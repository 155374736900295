import React from "react";

export default function Loader({ rows = 10, cols = 5, width = 150 }) {
  return (
    <div className="skeleton-table">
      {Array.from({ length: rows }).map((_, index) => (
        <div key={index} className="skeleton-row">
          {Array.from({ length: cols }).map((_, colIndex) => (
            <div
              key={colIndex}
              className="skeleton-cell"
              style={{
                width: `${width}px`,
              }}
            ></div>
          ))}
        </div>
      ))}
    </div>
  );
}
