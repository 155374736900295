import React, { useEffect, useState } from "react";

import { LIMITS, PROPERTIES } from "../../../config";
import { fetchData } from "../../../config/service";
import { Alert, Loader, Pagination } from "../../../components";
import toast from "react-hot-toast";
import VisaGuidelineModal from "./modals/visa-guideline-modal";

export default function VisaGuidelines() {
  const [visaModal, setVisaModal] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [genderOptions, setGenderOptions] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 700); // 700ms debounce

    // Cleanup the timeout if searchTerm changes (before 500ms)
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    getData();
  }, [limit, activePage, debouncedSearchTerm]);

  const getData = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    // Add search query if it exists
    if (debouncedSearchTerm) {
      param += `&search=${encodeURIComponent(debouncedSearchTerm || "")}`;
    }

    fetchData(
      `customer/api/visa/visa-game-plan-details/create-list/${param}`
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        setData([]);
        toast.error(res.message);
      }
    });
  };

  const getGenderOptions = () => {
    fetchData(`api/accounts/user-choices/?type=gender_choices`).then((res) => {
      if (res.success) {
        const data = res["gender_choices"]?.map((e) => {
          return { label: e?.display_name, value: e?.value };
        });
        setGenderOptions(data);
      } else {
        setGenderOptions([]);
        toast.error(res.message);
      }
    });
  };

  const getCountries = () => {
    fetchData(`api/country/?is_active=1&limit=${300}&offset=${0}`).then(
      (res) => {
        if (res.success) {
          setCountries(res.data);
        } else {
          setCountries([]);
          toast.error(res.message);
        }
      }
    );
  };

  const deleteItem = () => {
    fetchData(
      `customer/api/visa/visa-game-plan-details/${selected.id}/`,
      "delete"
    ).then((res) => {
      toast.dismiss();
      if (res.success) {
        setLoading(true);
        getData();
        toast.success("Record deleted successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <div className="row g-5 g-xxl-10">
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header">
              <div className="d-flex flex-stack flex-wrap gap-4">
                <div className="position-relative mr-4">
                  <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
                  <input
                    type="text"
                    data-kt-table-widget-4="search"
                    className="form-control text-dark w-250px fs-7 ps-12 all-search"
                    name="all_search"
                    placeholder="Search here..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="position-relative mr-4"></div>
              </div>
              <div className="card-toolbar">
                <button
                  className="btn btn-primary fs-7 fw-bold"
                  onClick={() => {
                    setVisaModal(true);
                  }}
                >
                  <i className="ki-outline ki-plus-square fs-2" /> Add New Visa
                  Guideline
                </button>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {loading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {data?.length > 0 ? (
                    <>
                      <div className="table-responsive tbl-sales">
                        {/*begin::Table*/}
                        <table
                          className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                          id
                        >
                          <thead>
                            <tr className="fw-bolder text-dark bg-white">
                              <th className="min-w-70px">Sr. No.</th>
                              <th className="min-w-150px">Country</th>
                              {/* <th className="min-w-150px">Visa Type</th>
                              <th className="min-w-200px">News</th> */}
                              <th className="min-w-150px">Checklists Link</th>
                              {/* <th className="min-w-200px">
                                Reasons for Rejections
                              </th>
                              <th className="min-w-150px">Visa Process</th> */}
                              <th className="min-w-150px">
                                Chances of Approval
                              </th>
                              {/* <th className="min-w-150px">Sources</th> */}
                              <th className="min-w-150px">
                                Visa Fee (Embassy+Appointment)
                              </th>
                              <th className="min-w-100px">Concierge Fee</th>
                              <th className="min-w-150px">
                                ExCo Fee - Full Board
                              </th>
                              <th className="min-w-150px">
                                ExCo Fee - Half Board
                              </th>
                              <th className="min-w-150px">
                                ExCo Fee - Doc Review
                              </th>
                              <th className="min-w-150px">ExCo Fee - Free</th>
                              <th className="min-w-150px">Action</th>
                            </tr>
                          </thead>
                          <tbody className=" fw-semibold fs-7">
                            {data?.map((visa, index) => (
                              <tr className="bg-light" key={index}>
                                <td> {(activePage - 1) * limit + 1 + index}</td>
                                <td>{visa?.country_name || "-"}</td>
                                {/* <td>{visa?.visa_type || "-"}</td>
                                <td>{visa?.news || "-"}</td> */}
                                <td>
                                  {visa?.checklist_link !== "" ||
                                  visa?.checklist_link !== null ? (
                                    <>
                                      <a
                                        href={visa?.checklist_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        View Checklist
                                      </a>
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                {/* <td
                                  dangerouslySetInnerHTML={{
                                    __html: visa?.rejections || "-",
                                  }}
                                ></td>

                                <td>{visa?.visa_process || "-"}</td> */}
                                <td>
                                  {`${visa?.chances_of_approval}%` || "-"}
                                </td>
                                {/* <td>{visa?.sources || "-"}</td> */}
                                <td>{`${visa?.visa_fee}` || "-"}</td>
                                <td>{`${visa?.concierge_fee}` || "-"}</td>
                                <td>{`${visa?.full_board_fee}` || "-"}</td>
                                <td>{visa?.half_board_fee || "-"}</td>
                                <td>{visa?.doc_board_fee || "-"}</td>
                                <td>{visa?.exco_fee || "-"}</td>
                                <td>
                                  <div className="d-flex flex-shrink-0 ">
                                    <span
                                      title="Edit"
                                      className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary cursor-pointer btn-sm me-3"
                                      onClick={() => {
                                        setVisaModal(true);
                                        setSelected(visa);
                                      }}
                                    >
                                      <i className="ki-outline ki-pencil fs-2 text-dark" />
                                    </span>
                                    <span
                                      title="Delete"
                                      className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary cursor-pointer btn-sm"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setSelected(visa);
                                      }}
                                    >
                                      <i className="ki-outline ki-trash fs-2 text-danger" />
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {/*end::Table*/}
                      </div>

                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={setLimit}
                        limit={limit}
                        data={data}
                        activePage={activePage}
                        totalRecords={totalRecords}
                        onChange={(pageNumber) => {
                          setLoading(true);
                          setActivePage(pageNumber);
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </div>

      <VisaGuidelineModal
        item={selected}
        show={visaModal}
        onHide={() => {
          setSelected({});
          setVisaModal(false);
          setLoading(true);
          getData();
        }}
        countries={countries}
      />

      <Alert
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        // onSuccess={() => {
        //   getData();
        // }}
        onAction={() => {
          toast.loading("Please wait...");
          deleteItem();
        }}
      />
    </div>
  );
}
