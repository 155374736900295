import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";

import moment from "moment";
import TribeModal from "./modals/tribeModal";
import { fetchData } from "../../config/service";
import { LIMITS } from "../../config";
import { Alert, Loader, Pagination } from "../../components";
import TribeMemberModal from "./modals/tribeMemberModal";
import { Popover } from "antd";
import Dropzone from "../../components/Dropzone";
import TribeBlogImagesModal from "./modals/tribeBlogImagesModal";

export default function Tribe() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [cities, setCities] = useState([]);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [tribeModal, setTribeModal] = useState(false);
  const [tribeMemberModal, setTribeMemberModal] = useState(false);
  const [tribeBlogImagesModal, setTribeBlogImagesModal] = useState(false);

  const [modalData, setModalData] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);

  useEffect(() => {
    getData();
    getCities();
  }, []);

  const getCities = () => {
    fetchData(
      `api/country/state/city/?phone_code=91&limit=${1000}&offset=${
        (activePage - 1) * limit
      }
      `,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e.name,
            value: e.id,
          };
        });
        setCities(data);
      } else {
        setCities([]);
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    setLoading(true);
    fetchData(
      `api/tribe/?limit=${limit}&offset=${(activePage - 1) * limit}
      `,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        toast.error(res.message);
      }
    });
  };

  const deleteItem = () => {
    fetchData(`api/tribe/${modalData.id}`, "delete").then((res) => {
      toast.dismiss();
      if (res.success) {
        setLoading(true);
        getData();
        toast.success("Record deleted successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  const tagLabels = {
    1: "Invite Only",
    2: "Buy Your Own Ticket",
  };

  const content = (
    <div>
      <label htmlFor className=" fs-6 fw-bold mb-2">
        Images
      </label>
      <Dropzone
        pictures={pictures}
        setPictures={setPictures}
        removedImages={removedImages}
        setRemovedImages={setRemovedImages}
      />
    </div>
  );

  return (
    <div className="row g-5 g-xxl-10">
      <div className="col-xxl-12">
        {/*begin::Tables Widget 9*/}
        <div className="card card-xxl-stretch mb-5 mb-xl-8">
          {/*begin::Header*/}
          <div className="card-header">
            <div className="d-flex flex-stack flex-wrap gap-4">
              {/* <div className="position-relative mr-4">
                <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
                <input
                  type="text"
                  data-kt-table-widget-4="search"
                  className="form-control text-dark w-250px fs-7 ps-12 all-search"
                  name="all_search"
                  placeholder="Search Event here..."
                  // value={searchTerm}
                  // onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div> */}
              <div className="position-relative mr-4"></div>
            </div>
            <div className="card-toolbar">
              <div
                className="btn btn-primary fs-7 fw-bold me-2"
                onClick={() => {
                  setTribeBlogImagesModal(!tribeBlogImagesModal);
                }}
              >
                Add Images
              </div>

              <button
                className="btn btn-primary fs-7 fw-bold"
                onClick={() => {
                  setTribeModal(!tribeModal);
                }}
              >
                <i className="ki-outline ki-plus-square fs-2" /> Add New Event
              </button>
              {/* <a href="#" class="btn  fs-7 btn-primary fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Add New User</a> */}
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body py-2 pb-7">
            {loading ? (
              <div className="table-responsive">
                <Loader cols={7} width={250} />
              </div>
            ) : (
              <div>
                {data?.length > 0 ? (
                  <>
                    <div className="table-responsive tbl-sales">
                      {/*begin::Table*/}
                      <table
                        className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                        id
                      >
                        <thead>
                          <tr className="fw-bolder text-gray-900 bg-light-primary">
                            {/* <th class="min-w-75px">Sr. No.</th> */}
                            <th className="min-w-125px">Event</th>
                            <th className="min-w-100px">Start Date</th>
                            <th className="min-w-125px">Address</th>
                            <th className="min-w-100px">City</th>
                            {/* <th className="min-w-175px">About</th>
                            <th className="min-w-175px">Terms & Conditions</th> */}
                            <th className="min-w-100px">Tags</th>
                            <th className="min-w-50px">Action</th>
                          </tr>
                        </thead>
                        <tbody className="fw-semibold fs-7">
                          {data.map((item, index) => (
                            <tr key={item.id}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                                    <img
                                      src={item.image || "/media/blank.png"}
                                      alt=""
                                    />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <div
                                      title="Click to View Tribe Members"
                                      className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-125px cursor-pointer"
                                      onClick={() => {
                                        setTribeMemberModal(!tribeMemberModal);
                                      }}
                                    >
                                      {`${item.name} `}
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td>
                                {item.start_date
                                  ? moment(item.start_date).format(
                                      "DD MMM YYYY"
                                    )
                                  : "N/A"}
                              </td>
                              <td>{item.address}</td>
                              <td>{item.city_name}</td>
                              {/* <td>{item.about}</td>
                              <td>{item.term_conditions}</td> */}
                              <td>
                                {item.tags
                                  .map((tagId) => tagLabels[tagId])
                                  .join(", ")}
                              </td>
                              <td>
                                <div className="d-flex flex-shrink-0">
                                  <div
                                    className={`btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3 `}
                                    onClick={() => {
                                      setTribeModal(!tribeModal);
                                      setModalData(item);
                                    }}
                                    style={{}}
                                  >
                                    <i className="ki-outline ki-pencil fs-2 text-dark" />
                                  </div>
                                  <span
                                    title="Delete"
                                    className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary cursor-pointer btn-sm"
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setModalData(item);
                                    }}
                                  >
                                    <i className="ki-outline ki-trash fs-2 text-danger" />
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/*end::Table*/}
                    </div>

                    <div className="separator separator-dashed border-gray-200" />
                    <Pagination
                      setLimit={setLimit}
                      limit={limit}
                      data={data}
                      activePage={activePage}
                      totalRecords={totalRecords}
                      onChange={(pageNumber) => {
                        setLoading(true);
                        setActivePage(pageNumber);
                      }}
                    />
                  </>
                ) : (
                  <div className="row g-5 mb-0 gx-md-10">
                    <div className="col-md-12 text-center">
                      <div className="mt-0">
                        <img
                          src="/media/no-data-found.png"
                          className="mw-25"
                          alt="No Data Found"
                        />
                      </div>
                      <h1 className="mt-4">No Data Found</h1>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>

      {tribeModal && (
        <TribeModal
          show={tribeModal}
          onHide={() => {
            setTribeModal(false);
            setModalData({});
            setLoading(true);
            getData();
          }}
          data={modalData}
          cities={cities}
        />
      )}

      {tribeMemberModal && (
        <TribeMemberModal
          show={tribeMemberModal}
          onHide={() => {
            setTribeMemberModal(false);
          }}
        />
      )}

      {tribeBlogImagesModal && (
        <TribeBlogImagesModal
          show={tribeBlogImagesModal}
          onHide={() => {
            setTribeBlogImagesModal(false);
          }}
        />
      )}

      <Alert
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        // onSuccess={() => {
        //   getData();
        // }}
        onAction={() => {
          toast.loading("Please wait...");
          deleteItem();
        }}
      />
    </div>
  );
}
