import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../../config/service";
import { Select, Tooltip } from "antd";
import { validateFields } from "../../../../components/validation";
import ReactQuill from "react-quill";

export default function VisaGuidelineModal({ show, item, onHide, countries }) {
  const [sending, setSending] = useState(false);

  const [currentInput, setCurrentInput] = useState("");

  const [formData, setFormData] = useState({
    country: null,
    visaType: "",
    news: "",
    checklistLink: "",
    reasonsForRejection: "",
    visaProcess: "",
    chancesOfApproval: "",
    sources: "",
    visaFee: "",
    conciergeFee: "",
    excoFeeFullBoard: "",
    excoFeeHalfBoard: "",
    excoFeeDocReview: "",
    excoFeeFree: "",
    travel_insurance: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (item?.id) {
      // console.log("item", item);
      setFormData({
        country: item.country || "",
        visaType: item.visa_type || "",
        news: item.news || "",
        checklistLink: item.checklist_link || "",
        reasonsForRejection: item.rejections || "",
        visaProcess: item.visa_process || "",
        chancesOfApproval: item.chances_of_approval || "",
        sources: item.sources || "",
        visaFee: item.visa_fee || "",
        conciergeFee: item.concierge_fee || "",
        excoFeeFullBoard: item.full_board_fee || "",
        excoFeeHalfBoard: item.half_board_fee || "",
        excoFeeDocReview: item.doc_board_fee || "",
        excoFeeFree: item.exco_fee || "",
        travel_insurance: item?.travel_insurance || "",
      });
    } else {
      setFormData({
        country: null,
        visaType: "",
        news: "",
        checklistLink: "",
        reasonsForRejection: "",
        visaProcess: "",
        chancesOfApproval: "",
        sources: "",
        visaFee: "",
        conciergeFee: "",
        excoFeeFullBoard: "",
        excoFeeHalfBoard: "",
        excoFeeDocReview: "",
        excoFeeFree: "",
        travel_insurance: "",
      });
    }
  }, [item]);

  const rules = {
    country: {
      required: true,
      message: "Please select a country", // Custom message for the required field
    },
    visaType: {
      required: true,
      message: "Please select a visa type", // Custom message
    },
    news: {
      required: true,
      maxLength: 500, // Allow up to 500 characters for news
      //   message: "News content must be at most 500 characters",
    },
    sources: {
      required: true,
      maxLength: 500, // Allow up to 500 characters for sources
      message: "Sources must be at most 500 characters",
    },

    checklistLink: {
      required: true,
      //   type: "url", // URL validation
      message: "Please provide a valid URL for the checklist link",
    },
    reasonsForRejection: {
      required: true,
      maxLength: 1000, // Allow up to 1000 characters for reasons for rejection
      message: "Reasons for rejection must be at most 1000 characters",
    },
    // visaProcess: {
    //   required: true,
    //   maxLength: 1000, // Limit the length of visa process explanation
    //   message: "Visa process must be at most 1000 characters",
    // },
    chancesOfApproval: {
      required: true,
      type: "number",
      min: 0,
      max: 100, // Expect a percentage between 0 and 100
      message: "Chances of approval must be a valid number between 0 and 100",
    },
    travel_insurance: {
      required: true,
      type: "number",
    },
    visaFee: {
      required: true,
      type: "number",
      min: 0, // Visa fee must be a non-negative number
      message: "Visa fee must be a valid non-negative number",
    },
    conciergeFee: {
      required: true,
      type: "number",
      min: 0, // Concierge fee must be a non-negative number
      message: "Concierge fee must be a valid non-negative number",
    },
    excoFeeFullBoard: {
      required: true,
      type: "number",
      min: 0, // Exco fee (Full board) must be a non-negative number
      message: "Exco fee (Full board) must be a valid non-negative number",
    },
    excoFeeHalfBoard: {
      required: true,
      type: "number",
      min: 0, // Exco fee (Half board) must be a non-negative number
      message: "Exco fee (Half board) must be a valid non-negative number",
    },
    excoFeeDocReview: {
      required: true,
      type: "number",
      min: 0, // Exco fee (Doc review) must be a non-negative number
      message: "Exco fee (Document review) must be a valid non-negative number",
    },
    excoFeeFree: {
      required: true,
      type: "number",
      min: 0, // Exco fee (Free) must be a non-negative number
      message: "Exco fee (Free) must be a valid non-negative number",
    },
  };

  const onSubmit = () => {
    const { isValid, errors } = validateFields(formData, rules);

    if (!isValid) {
      return;
    }

    const formattedValue = formData?.reasonsForRejection
      .replace(/<p>/g, "<div>") // Replace opening <p> with <div>
      .replace(/<\/p>/g, "</div>\n"); // Replace closing </p> with </div> and add a newline

    const body = {
      country: formData?.country,
      visa_type: formData?.visaType,
      news: formData?.news,
      rejections: formattedValue,
      chances_of_approval: formData?.chancesOfApproval,
      sources: formData?.sources,
      visa_fee: formData?.visaFee,
      concierge_fee: formData?.conciergeFee,
      full_board_fee: formData?.excoFeeFullBoard,
      half_board_fee: formData?.excoFeeHalfBoard,
      doc_board_fee: formData?.excoFeeDocReview,
      exco_fee: formData?.excoFeeFree,
      checklist_link: formData?.checklistLink,
      travel_insurance: formData?.travel_insurance,
      // visa_process: formData?.visaProcess, // Assuming you want to use one of the visa processes
    };

    // console.log(body, "body");

    // {{local_url}}/customer/api/visa/visa-game-plan-details/{{visaGamePlanId}}/

    let api = item?.id
      ? `customer/api/visa/visa-game-plan-details/${item?.id}/`
      : "customer/api/visa/visa-game-plan-details/create-list/";
    let method = item?.id ? "put" : "post";

    fetchData(api, method, body, "", false).then((res) => {
      setSending(false);
      if (res.success) {
        toast.success("Success");
        onHide();
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <Modal centered show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <h3 className="modal-title">
          {item?.id ? "Edit Visa Guideline" : "Add Visa Guideline"}
        </h3>
      </Modal.Header>
      <div className="modal-body pb-7 pt-5 px-lg-10">
        <div
          className="scroll-y pe-7 ps-3"
          id="kt_modal_add_customer_scroll"
          style={{
            height: "430px",
          }}
        >
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="country" className=" fs-6 fw-bold mb-2 required">
                Country
              </label>

              <Select
                options={countries?.map((role) => {
                  return {
                    label: role?.name,
                    value: role?.id,
                  };
                })}
                showSearch
                optionFilterProp="label"
                value={formData?.country}
                onChange={(e) =>
                  setFormData((prevData) => ({ ...prevData, country: e }))
                }
                placeholder="Select Country"
                dropdownStyle={{ zIndex: 1111 }}
                style={{ width: "100%", marginTop: "10px" }}
                size="large"
                allowClear
                onClear={() =>
                  setFormData((prevData) => ({
                    ...prevData,
                    country: null,
                  }))
                }
              />
              {/* <select
                className="form-select"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
              >
                <option value="">Select Country</option>
                <option value="USA">USA</option>
               
              </select> */}
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="visaType" className=" fs-6 fw-bold mb-2 required">
                Visa Type
              </label>
              <input
                type="text"
                className="form-control"
                id="visaType"
                name="visaType"
                value={formData.visaType}
                onChange={handleChange}
                placeholder="Enter Visa Type"
              />

              {/* <select
                className="form-select"
                id="visaType"
                name="visaType"
                value={formData.visaType}
                onChange={handleChange}
              >
                <option value="">Select Visa Type</option>
                <option value="Tourist">Tourist</option>
                
              </select> */}
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="news" className=" fs-6 fw-bold mb-2 required">
              News
            </label>
            <textarea
              className="form-control"
              id="news"
              name="news"
              rows="3"
              value={formData?.news}
              onChange={handleChange}
              placeholder="Enter news about visa requirements"
            ></textarea>
          </div>

          <div className="mb-3">
            <label htmlFor="sources" className=" fs-6 fw-bold mb-2 required">
              Sources
            </label>
            <input
              type="text"
              className="form-control"
              id="sources"
              name="sources"
              value={formData?.sources}
              onChange={handleChange}
              placeholder="Enter sources"
            />
          </div>

          <div className="mb-3">
            <label
              htmlFor="checklistLink"
              className=" fs-6 fw-bold mb-2 required"
            >
              Checklist Link
            </label>
            <input
              type="text"
              className="form-control"
              id="checklistLink"
              name="checklistLink"
              value={formData.checklistLink}
              onChange={handleChange}
              placeholder="Enter checklist link"
            />
          </div>

          <div className="mb-3">
            <label
              htmlFor="reasonsForRejection"
              className=" fs-6 fw-bold mb-2 required"
            >
              Reasons for Rejection
            </label>
            <ReactQuill
              theme="snow"
              value={formData.reasonsForRejection}
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  reasonsForRejection: e,
                }));
              }}
            />

            {/* <textarea
              className="form-control"
              id="reasonsForRejection"
              name="reasonsForRejection"
              rows="3"
              value={formData.reasonsForRejection}
              onChange={handleChange}
              placeholder="Enter reasons for rejection"
            ></textarea> */}
          </div>

          <div className="row">
            {/* <div className="col-md-6 mb-3">
              <label
                htmlFor="visaProcess"
                className=" fs-6 fw-bold mb-2 required"
              >
                Visa Process
              </label>
              <input
                type="text"
                className="form-control"
                id="visaProcess"
                name="visaProcess"
                value={formData.visaProcess}
                onChange={handleChange}
                placeholder="Enter visa process details"
              />
            </div> */}

            <div className="col-md-6 mb-3">
              <label
                htmlFor="chancesOfApproval"
                className=" fs-6 fw-bold mb-2 required"
              >
                Chances of Approval
              </label>
              <input
                type="text"
                className="form-control"
                id="chancesOfApproval"
                name="chancesOfApproval"
                value={formData.chancesOfApproval}
                onChange={handleChange}
                placeholder="Enter chances of approval"
              />
            </div>

            <div className="col-md-6 mb-3">
              <label
                htmlFor="travel_insurance"
                className=" fs-6 fw-bold mb-2 required"
              >
                Travel Insurance
              </label>
              <input
                type="text"
                className="form-control"
                id="travel_insurance"
                name="travel_insurance"
                value={formData.travel_insurance}
                onChange={handleChange}
                placeholder="Enter chances of approval"
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6 mb-3">
              <label htmlFor="visaFee" className=" fs-6 fw-bold mb-2 required">
                Visa Fee
              </label>
              <input
                type="text"
                className="form-control"
                id="visaFee"
                name="visaFee"
                value={formData.visaFee}
                onChange={handleChange}
                placeholder="Enter visa fee"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label
                htmlFor="conciergeFee"
                className=" fs-6 fw-bold mb-2 required"
              >
                Concierge Fee
              </label>
              <input
                type="text"
                className="form-control"
                id="conciergeFee"
                name="conciergeFee"
                value={formData.conciergeFee}
                onChange={handleChange}
                placeholder="Enter concierge fee"
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6 mb-3">
              <label
                htmlFor="excoFeeFullBoard"
                className=" fs-6 fw-bold mb-2 required"
              >
                Exco Fee (Full Board)
              </label>
              <input
                type="text"
                className="form-control"
                id="excoFeeFullBoard"
                name="excoFeeFullBoard"
                value={formData.excoFeeFullBoard}
                onChange={handleChange}
                placeholder="Enter Exco fee (Full Board)"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label
                htmlFor="excoFeeHalfBoard"
                className=" fs-6 fw-bold mb-2 required"
              >
                Exco Fee (Half Board)
              </label>
              <input
                type="text"
                className="form-control"
                id="excoFeeHalfBoard"
                name="excoFeeHalfBoard"
                value={formData.excoFeeHalfBoard}
                onChange={handleChange}
                placeholder="Enter Exco fee (Half Board)"
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6 mb-3">
              <label
                htmlFor="excoFeeDocReview"
                className=" fs-6 fw-bold mb-2 required"
              >
                Exco Fee (Doc Review)
              </label>
              <input
                type="text"
                className="form-control"
                id="excoFeeDocReview"
                name="excoFeeDocReview"
                value={formData.excoFeeDocReview}
                onChange={handleChange}
                placeholder="Enter Exco fee (Doc Review)"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label
                htmlFor="excoFeeFree"
                className=" fs-6 fw-bold mb-2 required"
              >
                Exco Fee (Free)
              </label>
              <input
                type="text"
                className="form-control"
                id="excoFeeFree"
                name="excoFeeFree"
                value={formData.excoFeeFree}
                onChange={handleChange}
                placeholder="Enter Exco fee (Free)"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-light fs-7" onClick={onHide}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={onSubmit}
          disabled={sending}
        >
          {sending ? (
            <span>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </Modal>
  );
}
