import { createSlice } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";
import { BHX_KEY } from "../config";

const secretKey = process.env.REACT_APP_SECRET_KEY;

// const secretKey = "your-secret-key"; // Replace with your own secret key

export const encodeValue = (value) => {
  const stringifiedValue = JSON.stringify(value);
  return CryptoJS.AES.encrypt(stringifiedValue, BHX_KEY).toString(); // Encrypt the value
};

export const decodeValue = (value) => {
  const bytes = CryptoJS.AES.decrypt(value, BHX_KEY);
  const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedValue); // Parse the decrypted value back to JSON
};

const initialState = {
  user: localStorage.getItem("user")
    ? decodeValue(localStorage.getItem("user"))
    : {},
  // user: JSON.parse(localStorage.getItem("user")) || null,
};

export const mainSlice = createSlice({
  name: "slice",
  initialState,
  reducers: {
    setUser: (state, action) => {
      // if (action.payload) {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      //   if (action?.payload?.token) {
      //     localStorage.setItem("token", action?.payload?.token);
      //   }
      // }

      if (action.payload) {
        localStorage.setItem("user", encodeValue(action.payload));
        if (action?.payload?.token) {
          localStorage.setItem("token", encodeValue(action?.payload?.token));
        }
      }
      state.user = action.payload;
    },

    handleLogout: (state, action) => {
      localStorage.clear();
      state.user = "";
    },
  },
});

export const { setUser, handleLogout } = mainSlice.actions;

export default mainSlice.reducer;
