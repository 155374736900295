import React from "react";

export default function RefundPolicy() {
  return (
    <>
      <div className="mb-20 pb-lg-20">
        {/*begin::Title*/}
        <h2 className="fw-bold text-gray-900 mb-8">Refund Terms:</h2>
        {/*end::Title*/}
        {/*begin::List*/}
        <ul className="fs-4 fw-semibold mb-6">
          <li>
            <span className="text-gray-700">
              Any cancellation happening within 3 days of booking an edition -
              full refund.
            </span>
          </li>
          <li className="my-2">
            <span className="text-gray-700">
              Otherwise, treat this as a cancellation, and cancellation policy
              applies.
            </span>
          </li>
        </ul>
        {/*end::List*/}

        {/*begin::Title*/}
        <h2 className="fw-bold text-gray-900 mb-8">Reschedule Terms:</h2>
        {/*end::Title*/}
        {/*begin::List*/}
        <ul className="fs-4 fw-semibold mb-6">
          <li>
            <span className="text-gray-700">
              Up to three transfers allowed, each incurring a fee. Fees from
              previous transfers accumulate.
            </span>
          </li>
          <li className="my-2">
            <span className="text-gray-700">
              For cancellations after transfer, standard cancellation policy
              applies; no transfer to credit.
            </span>
          </li>
          <li>
            <span className="text-gray-700">
              If unable to accommodate modification request and original booking
              not accepted, treated as cancellation by the client (charges as
              per policy).
            </span>
          </li>
          <li className="my-2">
            <span className="text-gray-700">
              In cases of unavoidable and extraordinary circumstances at the
              Edition destination (e.g., war, natural disasters), cancellation
              without a fee allowed.
            </span>
          </li>
          <li>
            <span className="text-gray-700">
              If Edition cut short, no refunds for unused portions, but travel
              insurance may cover some costs.
            </span>
          </li>
        </ul>
        {/*end::List*/}

        {/*begin::Title*/}
        <h2 className="fw-bold text-gray-900 mb-8">Other Relevant Terms:</h2>
        {/*end::Title*/}
        {/*begin::List*/}
        <ul className="fs-4 fw-semibold mb-6">
          <li>
            <span className="text-gray-700">
              Participants must have valid travel documents, including
              passports, visas, and required vaccinations.
            </span>
          </li>
          <li className="my-2">
            <span className="text-gray-700">
              Travel insurance is mandatory, covering medical expenses, injury,
              death, repatriation, cancellation, and curtailment.
            </span>
          </li>
        </ul>
        {/*end::List*/}
      </div>
    </>
  );
}
