import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { routesList, validateEmail } from "../../config";
import { fetchData } from "../../config/service";
import { setUser } from "../../redux/slice";

export default function Login() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [userObj, setUserObj] = useState({});
  const [phoneOtp, setPhoneOtp] = useState("");
  const [showPass1, setShowPass1] = useState(false);

  const onLogin = () => {
    if (!email || !validateEmail(email) || !password) {
      if (!email) {
        toast.error("Please enter your email!");
      } else if (!validateEmail(email)) {
        toast.error("Please enter valid email!");
      } else if (!password) {
        toast.error("Please enter your password!");
      }
    } else {
      setWaiting(true);
      const body = {
        email: email,
        password: password,
      };
      fetchData("api/accounts/auth/login/", "post", body).then((res) => {
        setWaiting(false);
        if (res.success) {
          toast.success(res.message);
          setOtpSent(true);
          setUserObj(res.data);
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  const verifyOtp = () => {
    if (!otp || otp.length !== 4) {
      if (!otp) {
        toast.error("Please enter OTP!");
      } else {
        toast.error("Please enter valid OTP!");
      }
    } else {
      setWaiting(true);
      const body = {
        email: email,
        otp: otp,
      };
      fetchData("api/accounts/auth/verify-otp/", "post", body).then((res) => {
        setWaiting(false);
        // alert("ok");
        // console.log(res);
        if (res.success) {
          toast.success("Successfully logged in!");
          dispatch(setUser(res));
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  const verifyPhoneOTP = () => {
    if (!phoneOtp || phoneOtp.length !== 4) {
      if (!phoneOtp) {
        toast.error("Please enter sms OTP!");
      } else if (phoneOtp.length !== 4) {
        toast.error("Please enter valid sms OTP!");
      }
    } else {
      setWaiting("phone");
      const body = {
        phone: userObj.phone,
        otp: phoneOtp,
      };
      fetchData("api/accounts/registrations/verify-phone/", "post", body).then(
        (res) => {
          setWaiting(false);
          if (res.success) {
            // toast.success(res.message);
            setUserObj({
              ...userObj,
              is_phone_verified: true,
            });
          } else {
            toast.error(res.message);
          }
        }
      );
    }
  };

  return (
    <div className="auth-bg">
      <div className="d-flex flex-column flex-root">
        <div
          className="d-flex flex-column flex-lg-row flex-column-fluid"
          style={{ height: "100vh" }}
        >
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="w-lg-500px p-10">
                <div className=" mb-10">
                  <div className=" text-dark d-flex align-items-center justify-content-center">
                    <img
                      alt="Logo"
                      src="/media/icon-bhx.png"
                      className="h-20px h-lg-90px theme-light-show "
                    />
                  </div>
                </div>
                <div className="form w-100">
                  <div className="text-center mb-11">
                    <h1 className="text-gray-900 fw-bolder mb-3">
                      Welcome to Experience Co.
                    </h1>
                    {userObj?.is_phone_verified !== false && (
                      <div className="text-gray-500 fw-semibold fs-6">
                        {otpSent
                          ? `We just sent an OTP to your registered email.`
                          : "Sign in to plan and book your next adventure."}
                      </div>
                    )}
                  </div>
                  {otpSent ? (
                    <div>
                      {userObj.is_phone_verified ? (
                        <div className="fv-row mb-8">
                          <input
                            type="text"
                            placeholder="OTP"
                            className="form-control bg-transparent h-60px border-gray-400 ps-7"
                            value={otp}
                            onChange={(e) => {
                              setOtp(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                verifyOtp();
                              }
                            }}
                          />
                        </div>
                      ) : (
                        <div className="fv-row mb-8">
                          <label className="text-gray-600 fw-semibold fs-7">
                            We just sent an OTP to {userObj.phone}
                          </label>
                          <div className="d-flex">
                            <input
                              type="text"
                              placeholder="SMS OTP"
                              className="form-control bg-transparent h-60px border-gray-400 ps-7"
                              value={phoneOtp}
                              maxLength={4}
                              onChange={(e) => {
                                setPhoneOtp(e.target.value);
                              }}
                            />
                            <button
                              className="btn btn-primary ml-10px"
                              onClick={verifyPhoneOTP}
                              disabled={
                                waiting === "phone" || phoneOtp.length !== 4
                              }
                            >
                              {waiting === "phone" ? (
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                              ) : (
                                <i className={"ki-outline ki-check fs-1"} />
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="fv-row mb-8">
                        <input
                          type="email"
                          placeholder="Email"
                          className="form-control bg-transparent h-60px border-gray-400 ps-7"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                      <div className="fv-row mb-3 password-container">
                        <input
                          type={showPass1 ? "text" : "password"}
                          placeholder="Password"
                          className="form-control bg-transparent h-60px border-gray-400 ps-7"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                        <span
                          className="eye-password"
                          onClick={() => {
                            setShowPass1(!showPass1);
                          }}
                        >
                          <i
                            className={`fs-1 ki-outline ${
                              showPass1 ? "ki-eye-slash" : "ki-eye"
                            }  me-2`}
                          />
                        </span>
                      </div>
                      <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                        <div />
                        <Link
                          to={routesList["forgot_password"].path}
                          className="link-primary"
                        >
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                  )}

                  {/*end::Wrapper*/}
                  {/*begin::Submit button*/}
                  {userObj?.is_phone_verified !== false && (
                    <div className="d-grid mb-10">
                      <button
                        className="btn btn-primary h-45px"
                        onClick={otpSent ? verifyOtp : onLogin}
                        disabled={waiting}
                      >
                        {waiting ? (
                          <span>
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>
                        ) : (
                          <span className="indicator-label">
                            {otpSent ? "Verify" : "Sign In"}
                          </span>
                        )}
                      </button>
                    </div>
                  )}
                  {/*end::Submit button*/}
                  {/*begin::Sign up*/}
                  <div className="text-gray-500 text-center fw-semibold fs-6">
                    Not a Member yet?
                    <Link
                      to={routesList["register"].path}
                      className="link-primary"
                    >
                      {" "}
                      Sign up
                    </Link>
                  </div>
                  {/*end::Sign up*/}
                </div>
                {/*end::Form*/}
              </div>
              {/*end::Wrapper*/}
            </div>
            {/*end::Form*/}
          </div>
          {/*end::Body*/}
          {/*begin::Aside*/}
          <div
            className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
            style={{
              backgroundImage: "url(/media/bg1.png)",
              borderRadius: "170px 0px 0px 0px",
            }}
          >
            {/*begin::Content*/}
            <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100"></div>
            {/*end::Content*/}
          </div>
          {/*end::Aside*/}
        </div>
        {/*end::Authentication - Sign-in*/}
      </div>
    </div>
  );
}
