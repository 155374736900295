import React from "react";
import Modal from "react-bootstrap/Modal";

export default function Settings({ show, onHide }) {
  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div>
          <h3 className="modal-title">BHX Goa </h3>
          <small className="fs-6">Start Date: March 15, 2024</small>
        </div>
      </Modal.Header>
      <div className="modal-body pb-7 pt-3 ps-lg-7 pe-0">
        <div
          className="scroll-y pe-7 ps-3"
          style={{
            height: "400px",
          }}
        >
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-12 ">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Change Edition Status
              </label>
              <div className="input-group ">
                <select className="form-select fs-7">
                  <option>Choose a Status</option>
                </select>
                <button className="btn btn-light-primary border border-gray-300 min-w-125px fw-bold fs-7">
                  <i className="ki-outline ki-notepad-edit fs-2" /> Update
                </button>
              </div>
            </div>
            <div className="col-md-12 ">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Restricted Genders
              </label>
              <div className="bg-light p-3 min-h-50px"></div>
            </div>
            <div className="col-md-12 ">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Add New Restricted gender
              </label>
              <div className="input-group ">
                <select className="form-select fs-7">
                  <option>Choose a gender</option>
                  <option>Male</option>
                  <option>Female</option>
                  <option>Other</option>
                </select>
                <button className="btn btn-light-primary border border-gray-300 min-w-125px fw-bold fs-7">
                  <i className="ki-outline ki-plus-square fs-2" /> Add
                </button>
              </div>
            </div>
            <div className="col-md-12 ">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Reset Ratio
              </label>
              <div className="mb-2">
                <small>
                  Effects upcoming curation, which means here on the curated
                  people will get adjusted price. This will reset score till
                  next sale only.
                </small>
              </div>
              <div className="mb-4">
                <small>
                  Ratio 2.01 and higher kicks in the dynamic pricing.
                </small>
              </div>
              <div className="input-group ">
                <input
                  type="number"
                  className="form-control fs-7"
                  defaultValue={2}
                />
                <button className="btn btn-light-primary border border-gray-300 min-w-125px fw-bold fs-7">
                  <i className="ki-outline ki-plus-square fs-2" /> Set Ratio
                </button>
              </div>
            </div>
            <div className="col-md-12 ">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Add Insight
              </label>
              <div className="input-group ">
                {/* <input type="number" class="form-control fs-7" value="2"> */}
                <textarea
                  name
                  id
                  cols={30}
                  rows={3}
                  className="form-control fs-7"
                  defaultValue={""}
                />
                {/* <button class="btn btn-light-primary border border-gray-300 min-w-125px fw-bold fs-7"><i class="ki-outline ki-plus-square fs-2"></i> Set Ratio</button> */}
              </div>
            </div>
            <div className="col-md-12">
              <button className="btn btn-light-primary border border-gray-300 fw-bold fs-7">
                <i className="ki-outline ki-plus-square fs-2" /> Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
