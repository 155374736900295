import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";

import { Select } from "antd";
import { fetchData } from "../../../../config/service";

export default function UserDetailModal({ show, statusList, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [customerData, setCustomerData] = useState({
    status: null,
    score: null,
  });

  useEffect(() => {
    if (data) {
      console.log(data, "user_data");
      setUserId(data?.uuid);
      setCustomerData({
        status: data?.status,
        score: data?.score,
      });
    }
  }, [data]);

  const onSubmitUser = async () => {
    setSending(true);

    const body = {
      ...(customerData.status !== data?.status
        ? { status: customerData.status }
        : {}),
      ...(customerData.score !== data?.score
        ? { score: customerData.score }
        : {}),
    };

    fetchData(`customer/api/customer/${userId}/`, "PATCH", body).then((res) => {
      setSending(false);
      if (res.success) {
        console.log(res);
        onHide();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setCustomerDataValue = (key, value) => {
    const common_data = {
      ...customerData,
      [key]: value,
    };
    console.log(common_data, "common_data");
    setCustomerData(common_data);
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 className="modal-title">
            {data?.user?.first_name + " " + data?.user?.last_name}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-5 mb-5 align-items-center">
          <div className="col-md-12 fv-row">
            <h6 className="text-muted">Score</h6>
            <p>{data?.user?.score || "-"}</p>

            <h6 className="text-muted">City</h6>
            <p>{data?.user?.user_profile?.city_name || "-"}</p>

            <h6 className="text-muted">Phone</h6>
            <p>{data?.user?.phone || "-"}</p>

            <h6 className="text-muted">Bio in their form</h6>
            <p>{data?.user?.user_profile?.bio || "-"}</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>

        <a
          href={`${data?.user?.user_profile?.instagram_url}`}
          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
          onClick={onHide}
        >
          <i className="lab la-instagram fs-2x text-primary" />
        </a>
        <a
          href={`${data?.user?.user_profile?.linkedin_url}`}
          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
          onClick={onHide}
        >
          <i className="lab la-linkedin fs-2x text-primary" />
        </a>
      </Modal.Footer>
    </Modal>
  );
}
