import React, { useState } from "react";
import DropzoneReact from "react-dropzone";
import { fetchData } from "../config/service";
import { Image as AntdImage, message } from "antd";
import { v4 as uuidv4 } from "uuid"; // Import UUID for unique filenames

// Sample fetch function for presigned URLs
const fetchPresignedUrls = async (filesData) => {
  const body = {
    // upload_for: 1,
    files: filesData,
    thumbnail_files: filesData,
  };

  try {
    const response = await fetchData(
      "api/editions/pre-sign-urls/",
      "POST",
      body
    );
    if (response.success) {
      return {
        files: response.pre_signed_urls,
        thumbnails: response.pre_thumbnail_urls,
      };
    } else {
      message.error(response.message);
      return null;
    }
  } catch (error) {
    console.error("Error fetching presigned URLs:", error);
    message.error("Error fetching presigned URLs");
    return null;
  }
};

export default function Dropzone({
  pictures,
  setPictures,
  removedImages,
  setRemovedImages,
  shouldUpload, // New flag to control upload functionality
  setUploadedUrls,
  setLoading,
}) {
  const uploadFiles = async (filesToUpload) => {
    if (filesToUpload.length === 0) return [];

    // Filter out files with invalid types
    const allowedTypes = ["image/jpeg", "image/png", "video/mp4"];
    const filteredFiles = filesToUpload.filter((file) =>
      allowedTypes.includes(file.type)
    );

    if (filteredFiles.length === 0) {
      console.error(
        "No valid file types selected. Only JPG, PNG, and MP4 are allowed."
      );
      return [];
    }

    // Prepare file data for presigned URL request
    const filesData = filteredFiles.map((file) => {
      const uniqueFileName = `${uuidv4()}.${file.name.split(".")[1]}`;
      console.log(uniqueFileName, "uniqueFileName");
      return {
        file_name: uniqueFileName,
        file_type: file.type,
      };
    });

    setLoading(true);
    // Fetch presigned URLs
    const presignedUrls = await fetchPresignedUrls(filesData);
    if (!presignedUrls || presignedUrls.length === 0) {
      console.error("No presigned URLs received.");
      return [];
    }

    const { files, thumbnails } = presignedUrls;

    console.log(files, thumbnails, "files and thumbnails");

    // Start timing
    const startTime = performance.now();

    // Array to store the URLs of successfully uploaded files
    const uploadedFileUrls = [];

    await Promise.all(
      filteredFiles.map(async (file, index) => {
        const presignedUrl = files[index]?.pre_signed_url;
        const fileUrl = files[index]?.file_url;
        const thumbUrl = thumbnails[index]?.file_url;
        const uniqueFileName = files[index].file_name;

        console.log();

        if (!presignedUrl) {
          console.error(`No presigned URL available for file: ${file.name}`);
          return;
        }

        try {
          // Upload original file
          const uploadResponse = await fetch(presignedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": file.type,
            },
            body: file,
          });

          if (uploadResponse.ok) {
            console.log(`File ${uniqueFileName} uploaded successfully!`);
            uploadedFileUrls.push(fileUrl); // Add the URL to the array
          } else {
            console.error(
              `File ${uniqueFileName} upload failed: ${uploadResponse.statusText}`
            );
          }

          // Compress image if it's an image type and upload to thumbUrl
          if (thumbUrl && file.type.startsWith("image/")) {
            const compressedImage = await compressImage(file, 200); // Compress to max 200px width/height

            const thumbResponse = await fetch(thumbUrl, {
              method: "PUT",
              headers: {
                "Content-Type": compressedImage.type,
              },
              body: compressedImage,
            });

            if (thumbResponse.ok) {
              console.log(
                `Thumbnail for ${uniqueFileName} uploaded successfully!`
              );
            } else {
              console.error(
                `Thumbnail for ${uniqueFileName} upload failed: ${thumbResponse.statusText}`
              );
            }
          }
        } catch (error) {
          console.error(
            `An error occurred while uploading file ${file.name}:`,
            error
          );
        }
      })
    );

    setLoading(false);

    // End timing
    const endTime = performance.now();
    const timeTakenMs = endTime - startTime;
    const timeTakenSec = (timeTakenMs / 1000).toFixed(2);
    const timeTakenMin = Math.floor(timeTakenMs / 60000);
    const remainingSec = ((timeTakenMs % 60000) / 1000).toFixed(2);

    message.destroy();
    message.success(`File Uploaded Successfully!`);

    console.log(
      `Total time taken: ${timeTakenMin} minute(s) and ${remainingSec} second(s)`
    );
    console.log(`Total time in seconds: ${timeTakenSec} seconds`);
    console.log(
      `Overall time taken for file uploads: ${timeTakenMs.toFixed(
        2
      )} milliseconds`
    );

    // Return the array of successfully uploaded file URLs
    return uploadedFileUrls;
  };

  const compressImage = (file, maxSize) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          // Create canvas element
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Calculate new dimensions while maintaining the aspect ratio
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxSize) {
              height = Math.floor((height * maxSize) / width);
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width = Math.floor((width * maxSize) / height);
              height = maxSize;
            }
          }

          // Set canvas dimensions and draw the image onto it
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          // Convert the canvas to a Blob (compressed image)
          canvas.toBlob(
            (blob) => {
              if (blob) {
                const compressedFile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                });
                resolve(compressedFile);
              } else {
                reject(new Error("Compression failed"));
              }
            },
            file.type,
            0.7 // Compression quality (0.7 = 70%)
          );
        };

        img.onerror = (error) => {
          reject(error);
        };
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  return (
    <div>
      <DropzoneReact
        maxFiles={10}
        accept={[".jpeg", ".png", ".jpg"]}
        onDrop={async (acceptedFiles) => {
          // Maximum file size limit in bytes (10 MB = 10 * 1024 * 1024)
          const MAX_FILE_SIZE = 10 * 1024 * 1024;

          let withPaths = [];

          // Filter out files larger than 10 MB
          const validFiles = acceptedFiles.filter((file) => {
            if (file.size > MAX_FILE_SIZE) {
              message.error(
                `${file.name} is larger than 10 MB and cannot be uploaded.`
              );
              return false; // Exclude the file
            }
            return true; // Include the file
          });

          if (validFiles.length === 0) {
            // If no valid files remain, stop further execution
            return;
          }

          validFiles.map((file) => {
            withPaths = [...withPaths, file];
          });

          setPictures([...pictures, ...withPaths]);

          if (shouldUpload) {
            message.loading("Uploading...", 0);

            const urls = await uploadFiles(withPaths);
            setUploadedUrls(urls);
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="fv-row">
                <div className="dropzone overflow-scroll">
                  <div className="dz-message needsclick">
                    <i className="ki-duotone ki-file-up fs-3x text-primary">
                      <span className="path1" />
                      <span className="path2" />
                    </i>
                    <div className="ms-4">
                      <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                        Drop files here or click to upload.
                      </h3>
                      <span className="fs-7 fw-semibold text-gray-500">
                        Upload up to 10 files
                      </span>
                      <div
                        className="mt-2 d-flex"
                        {...getRootProps({
                          onClick: (event) => event.stopPropagation(),
                        })}
                      >
                        {pictures &&
                          pictures.map((item, i) => (
                            <div className="position-relative" key={i}>
                              <AntdImage
                                src={item?.url ?? URL.createObjectURL(item)}
                                alt="Preview"
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "10px",
                                  marginRight: "5px",
                                }}
                              />
                              <span
                                onClick={() => {
                                  if (item.id) {
                                    setRemovedImages([
                                      ...removedImages,
                                      item.id,
                                    ]);
                                  }
                                  let allPictures = [...pictures];
                                  allPictures.splice(i, 1); // Removes the item at index `i`
                                  allPictures = allPictures.filter(
                                    (item) => item
                                  ); // Filters out falsy values (like undefined, null, etc.)

                                  setPictures(allPictures);
                                }}
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  backgroundColor: "#F8285A",
                                  borderRadius: "100px",
                                  height: "20px",
                                  width: "20px",
                                  top: "-5px",
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "white",
                                }}
                              >
                                <i
                                  className={`fs-3 ki-solid ki-abstract-11 text-light`}
                                  style={{
                                    display: "flex",
                                    height: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  ×
                                </i>
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </DropzoneReact>
    </div>
  );
}
