import React from "react";

const EditionsDetails = ({
  cardData,
  data,
  editionData,
  setIsOpenScoreModal,
  growthAreaList,
  topPriorityList,
}) => {
  return (
    <div className="card card-flush mb-6 mb-xl-9">
      <div className="card-header">
        <div className="card-title flex-column">
          <h2 className="mb-1">{cardData.title}</h2>
        </div>
        <div className="card-toolbar">
          <div className="fw-bold">
            Edition Status:
            <span className="badge badge-outline badge-primary ms-1 px-2">
              {editionData?.edition_details?.status || "N/A"}
            </span>
          </div>
        </div>
      </div>

      <div className="card-body p-9 pt-2">
        <h4>Story</h4>
        <div>
          <p className="fs-6">{editionData?.intentions}</p>
        </div>
        <div className="separator separator-dashed border-gray-300 my-5" />
        <h4>Top Reasons</h4>
        <div>
          <p className="fs-6">
            {editionData?.top_priority_labels?.length > 0 ? (
              <ul className="list-unstyled mt-5">
                {editionData.top_priority_labels
                  .slice(0, 2)
                  .map((priority, index) => (
                    <li key={index}>{priority}</li>
                  ))}
                {editionData.top_priority_labels.length > 2 && <li>...</li>}
              </ul>
            ) : (
              "N/A"
            )}
          </p>
        </div>
        <div className="separator separator-dashed border-gray-300 my-5" />
        <h4>Growth Areas</h4>
        <div>
          <p className="fs-6">
            {editionData?.growth_areas_labels?.length > 0 ? (
              <ul className="list-unstyled">
                {editionData.growth_areas_labels
                  .slice(0, 2)
                  .map((area, index) => (
                    <li key={index}>{area}</li>
                  ))}
                {editionData.growth_areas_labels.length > 2 && <li>...</li>}
              </ul>
            ) : (
              "N/A"
            )}
          </p>
        </div>
        <div className="separator separator-dashed border-gray-300 my-5" />
        <div className="row g-5">
          <div className="col-md-6">
            <div>
              <div className="mb-2 fs-6">
                <span className="fw-bold">Status:</span>{" "}
                {data?.status == 1
                  ? "Pending"
                  : data.status == 2
                  ? "Abandon"
                  : data?.status == 3
                  ? "Approved"
                  : "Attended"}
              </div>
              <div className="mb-2 fs-6">
                <span className="fw-bold">Score:</span> {data?.score}
              </div>
              <div className="mb-2 fs-6">
                <span className="fw-bold">Phone:</span> {data?.phone}
              </div>
              <div className="mb-2 fs-6">
                <span className="fw-bold">{data?.poc || "-"}</span>
              </div>
              <div className="mb-2">
                <div
                  className="fs-6 fw-semibold text-primary text-hover-primary cursor-pointer"
                  onClick={() => setIsOpenScoreModal(true)}
                >
                  Change Score
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10"></div>
      </div>
    </div>
  );
};

export default EditionsDetails;
