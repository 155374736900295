import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Select } from "antd";

export default function ChangeEditionsModal({
  show,
  editionsList,
  onHide,
  data,
}) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [oldEdition, setOldEdition] = useState(null);
  const [editionData, setEditionData] = useState({
    edition: null,
  });

  useEffect(() => {
    if (data) {
      // console.log(data, "user_data");
      setUserId(data?.user?.uuid);
      setOldEdition(data?.edition);
      setEditionData({
        edition: data?.edition,
      });
    }
  }, [data]);

  const onSubmitUser = async () => {
    setSending(true);

    const body = {
      user_uuid: userId,
      new_edition_id: editionData.edition,
      old_edition_id: oldEdition,
    };

    fetchData(
      `api/booking/send-request-to-reschedule-booking/`,
      "post",
      body
    ).then((res) => {
      setSending(false);
      if (res.success) {
        onHide();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setEditionDataValue = (key, value) => {
    const common_data = {
      ...editionData,
      [key]: value,
    };

    setEditionData(common_data);
  };

  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Change Edition <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-9 align-items-center">
          <div className="col-12">
            <label>
              <span className="required fw-semibold fs-5">Select Edition</span>
            </label>

            <Select
              options={editionsList}
              value={editionData?.edition || ""}
              onChange={(e) => setEditionDataValue("edition", e)}
              placeholder="Select Edition"
              dropdownStyle={{ zIndex: 1111 }}
              style={{ width: "100%", marginTop: "10px" }}
              size="large"
              // allowClear
              // onClear={() => setEditionDataValue("status", null)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitUser();
          }}
          disabled={sending || oldEdition === editionData?.edition}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
