import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Dropzone, Select } from "../../../components";

export default function Manage({
  show,
  item,
  onHide,
  destinationList,
  onSuccess,
}) {
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [pictures, setPictures] = useState([]);
  const [about, setAbout] = useState("");
  const [destination, setDestination] = useState("");
  const [cost, setCost] = useState("");
  const [sending, setSending] = useState(false);
  const [removedImages, setRemovedImages] = useState([]);

  useEffect(() => {
    if (item) {
      setImage(item.default_image);
      setImagePreview(item.default_image);
      setAbout(item.about);
      setCost(item.cost);
      setDestination(item.destination);
      setPictures(item.images);
    } else {
      setImage(null);
      setImagePreview(null);
      setAbout("");
      setCost("");
      setDestination("");
      setPictures([]);
    }
  }, [item]);

  const onSubmit = () => {
    if (
      (!image && !item?.id) ||
      !destination ||
      !about ||
      !cost ||
      pictures.length > 10
    ) {
      if (!image && !item?.id) {
        toast.error("Please select main hotel image.");
      } else if (!destination) {
        toast.error("Please add the destination.");
      } else if (!about) {
        toast.error("Please add about experience.");
      } else if (!cost) {
        toast.error("Please add cost of experience.");
      } else if (pictures.length > 10) {
        toast.error("You can select up to 10 images only.");
      }
    } else {
      setSending(true);
      const body = {
        destination: destination,
        about: about,
        cost: cost,
      };

      if (image) {
        body.default_image = image;
      }
      let uploadedPictures = pictures.filter((item) => item.path);
      if (uploadedPictures.length) {
        body.images = uploadedPictures;
      }

      if (removedImages.length) {
        body.delete_images = removedImages;
      }

      let api = item?.id ? `api/experience/${item.id}/` : "api/experience/";
      let method = item?.id ? "put" : "post";

      fetchData(api, method, body, "", true).then((res) => {
        setSending(false);
        if (res.success) {
          toast.success("Success");
          onHide();
          onSuccess();
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  const handleImagesChange = (e, imageType) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = () => {
        setImage(file);
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <h3 className="modal-title">
          {item?.id ? "Edit Experience" : "Add an Experience"}
        </h3>
      </Modal.Header>
      <div>
        <div className="modal-body pb-7 pt-5 px-lg-10">
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-12 fv-row mt-10">
              <label htmlFor className="fs-6 fw-bold mb-2 required">
                Image
              </label>
              {imagePreview ? (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    marginLeft: "10px",
                  }}
                >
                  <img
                    src={imagePreview}
                    alt="Profile Preview"
                    style={{
                      width: "150px",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "8px",
                      border: "2px solid #ccc",
                    }}
                  />
                  <i
                    title="Remove Image"
                    className="ki-outline ki-cross fs-2"
                    onClick={() => setImagePreview(null)}
                    style={{
                      position: "absolute",
                      top: "-8px",
                      right: "-8px",
                      cursor: "pointer",
                      zIndex: 2,
                      backgroundColor: "white",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              ) : (
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  className="form-control fs-7"
                  onChange={(e) => {
                    handleImagesChange(e);
                  }}
                />
              )}
            </div>

            <div className="col-md-12 fv-row">
              <div>
                <label htmlFor className=" fs-6 fw-bold mb-2">
                  Images
                </label>
                <Dropzone
                  pictures={pictures}
                  setPictures={setPictures}
                  removedImages={removedImages}
                  setRemovedImages={setRemovedImages}
                />
              </div>
            </div>
            <div className="col-md-12 fv-row">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                About the Experience
              </label>
              <textarea
                className="form-control fs-7"
                cols={30}
                rows={3}
                value={about}
                onChange={(e) => {
                  setAbout(e.target.value);
                }}
              />
            </div>
            <div className="col-md-12 fv-row">
              <label htmlFor className=" fs-6 fw-bold mb-2 ">
                Select Destinations
              </label>
              <Select
                value="id"
                label="name"
                list={destinationList}
                selected={destination}
                onChange={(value) => {
                  setDestination(value);
                }}
              />
            </div>
            <div className="col-md-12 fv-row">
              <label htmlFor className=" fs-6 fw-bold mb-2 ">
                Cost
              </label>
              <input
                type="number"
                className="form-control fs-7"
                value={cost}
                onChange={(e) => {
                  setCost(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-light fs-7" onClick={onHide}>
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary fs-7"
            onClick={onSubmit}
            disabled={sending}
          >
            {sending ? (
              <span>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}
