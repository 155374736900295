import dayjs from "dayjs";
import React, { useState } from "react";
import ChangeEditionsModal from "../../modals/change-edition";
import { useEffect } from "react";
import { fetchData } from "../../../../config/service";
import toast from "react-hot-toast";
import { Alert } from "../../../../components";
import moment from "moment";
import { formatNumber } from "../../../../config";

const Booking = ({
  editionData,
  getEditionOptions,
  getEditionsLitFromBookingData,
}) => {
  const [changeEditionModal, setChangeEditionModal] = useState(false);
  const [changeEditionModalData, setChangeEditionModalData] = useState({});
  const [editionListLoading, setEditionListLoading] = useState(false);
  const [bookingId, setBookingId] = useState(null);
  const [editionsList, setEditionsList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [paymentDetailsLoading, setPaymentDetailsLoading] = useState(false);
  const [paymentsDetailData, setPaymentsDetailsData] = useState([]);

  useEffect(() => {
    setBookingId(editionData?.id);
    getEditions();
    if (editionData?.id) {
      getPaymentDetails(editionData?.id);
    }
  }, [editionData]);

  const getEditions = () => {
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const deleteItem = () => {
    // console.log(bookingId, "bookingId");
    fetchData(`api/booking/${bookingId}`, "delete").then((res) => {
      toast.dismiss();
      if (res.success) {
        toast.success("Record deleted successfully");
        getEditionsLitFromBookingData();
        getEditionOptions();
        getPaymentDetails(bookingId);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getPaymentDetails = (bookingId) => {
    const body = {
      booking_id: `${bookingId}`,
    };

    setPaymentDetailsLoading(true);
    fetchData(`api/booking/payments-details/`, "post", body).then((res) => {
      setPaymentDetailsLoading(false);
      if (res.success) {
        setPaymentsDetailsData(res);
      } else {
        setPaymentsDetailsData({});
        toast.error(res.message);
      }
    });
  };

  return (
    <>
      <div className="card card-flush mb-6 mb-xl-9">
        <div className="card-header">
          <div className="card-title flex-column">
            <h2 className="mb-1">Booking</h2>
          </div>

          <div className="card-toolbar"></div>
        </div>

        <div className="card-body p-9 pt-2">
          <div className=" row g-5 mb-5 gx-md-10">
            <div className="col-md-6">
              <div className="mt-0">
                <div className>
                  <div className="mb-4 fs-6">
                    <span className="fw-bold">Booking ID:</span>{" "}
                    {editionData?.id}
                  </div>
                  <div className="mb-4 fs-6">
                    <span className="fw-bold">Start Date:</span>{" "}
                    {editionData?.edition_details?.start_date
                      ? dayjs(editionData?.edition_details?.start_date).format(
                          "DD-MM-YYYY h:mm A"
                        )
                      : "-"}
                  </div>
                  <div className="mb-2 fs-6">
                    <span className="fw-bold">End Date:</span>{" "}
                    {editionData?.edition_details?.start_date
                      ? dayjs(editionData?.edition_details?.end_date).format(
                          "DD-MM-YYYY h:mm A"
                        )
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="mt-10">
                {/* <div className="mb-3">
                  <a href className="fs-6 fw-semibold">
                    Change Edition
                  </a>
                </div> */}
                <div className="mb-3">
                  <div
                    className="fs-6 fw-semibold text-primary cursor-pointer"
                    onClick={() => {
                      setChangeEditionModal(!changeEditionModal);
                      setChangeEditionModalData(editionData);
                    }}
                  >
                    Reschedule Edition
                  </div>
                </div>
                {/* <div className="mb-3">
              <a href className="fs-6 fw-semibold">
                Link Booking to Application #
              </a>
            </div> */}
                {/* <div className="mb-3">
              <a href className="fs-6 fw-semibold">
                Reset Booking Dates
              </a>
            </div> */}
                {/* <div className="mb-3">
              <a href className="fs-6 fw-semibold">
                Change Payment Status
              </a>
            </div> */}
                <div className="mb-3">
                  <div
                    className="fs-6 fw-semibold text-danger cursor-pointer"
                    onClick={() => {
                      setShowDeleteModal(true);
                    }}
                  >
                    Delete Booking
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h4 className="mb-8">Status Markers</h4>
              <div className="d-flex flex-stack">
                <div className="fs-6">Call request sent on:</div>
                <div className="fs-6 fw-bolder">-</div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Call at:</div>
                <div className="fs-6 fw-bolder">-</div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Followup on:</div>
                <div className="fs-6 fw-bolder">-</div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Followuped on:</div>
                <div className="fs-6 fw-bolder">-</div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Payment link sent on:</div>
                <div className="fs-6 fw-bolder">-</div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Paid on:</div>
                <div className="fs-6 fw-bolder">-</div>
              </div>
            </div>
          </div>

          <div className="separator separator-dashed my-10"></div>

          <div className=" row g-5 mb-0 gx-md-10">
            <div className="col-md-6">
              <div className="bg-light-primary p-4 px-5 rounded mb-5 bdr-left-user">
                <div className="d-flex align-items-center flex-stack">
                  <div className="fs-5 fw-bold">Total Fee:</div>
                  <div className="fs-4 fw-bolder text-primary">
                    {" "}
                    {formatNumber(
                      parseInt(paymentsDetailData?.edition_cost || 0) +
                        parseInt(paymentsDetailData?.dynamic_pricing || 0) -
                        parseInt(paymentsDetailData?.grant || 0) +
                        parseInt(paymentsDetailData?.reschedule_fee || 0) +
                        parseInt(paymentsDetailData?.visa_fee || 0)
                    )}
                  </div>
                </div>
                <div className="separator separator-dashed my-3 " />
                <div className=" mt-1">
                  <small>
                    <small className="fs-8">
                      Visible to User = Fee for Edition + Dynamic Pricing (if
                      any) - Grant + Reschedule Fees (if any) + Visa Fee
                    </small>
                  </small>
                </div>
              </div>
              <div className="bg-light-primary p-4 px-5 rounded bdr-left-user">
                <div className="d-flex align-items-center flex-stack">
                  <div className="fs-5 fw-bold">Pending Fee:</div>
                  <div className="fs-4 fw-bolder text-primary">-</div>
                </div>
                <div className="separator separator-dashed my-3 " />
                <div className=" mt-1">
                  <small>
                    Due Date:{" "}
                    {paymentsDetailData?.due_date
                      ? dayjs(
                          paymentsDetailData?.edition_details?.start_date
                        ).format("DD-MM-YYYY h:mm A")
                      : "-"}
                  </small>
                </div>
              </div>
              <div className="mt-10">
                {/* <h4 className="mb-8">BHX Controls</h4> */}
                {/* <div className="mb-3">
              <a href className="fs-6 fw-semibold">
                Add First Payment
              </a>
            </div>
            <div className="mb-3">
              <a href className="fs-6 fw-semibold">
                Add Second Payment
              </a>
            </div>
            <div className="mb-10">
              <a href className="fs-6 fw-semibold">
                Add Third Payment
              </a>
            </div> */}
                {/* <div className="mb-3">
                  <a href className="fs-6 fw-semibold">
                    Add / Edit Reschedule Fees
                  </a>
                </div>
                <div className="mb-10">
                  <a href className="fs-6 fw-semibold">
                    Add Room Addon
                  </a>
                </div>
                <div className="mb-3">
                  <a href className="fs-6 fw-semibold">
                    Create Invoice for User
                  </a>
                </div>
                <div className="mb-3">
                  <a href className="fs-6 fw-semibold text-danger">
                    Give a Refund (Razorpay Connected)
                  </a>
                </div>
                <div className="mb-3">
                  <a href className="fs-6 fw-semibold">
                    Find New Edition Fee
                  </a>
                </div> */}
              </div>
            </div>
            <div className="col-md-6">
              <h4 className="mb-8">Breakdown</h4>
              <div className="d-flex flex-stack">
                <div className="fs-6">Edition Fee:</div>
                <div className="fs-6 fw-bolder">
                  {formatNumber(paymentsDetailData?.edition_cost) || 0}
                </div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Grant:</div>
                {/* <div className="fs-6 fw-bolder">₹10,000</div> */}
                <div className="fs-6 fw-bolder">
                  {formatNumber(paymentsDetailData?.grant) || 0}
                </div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Dynamic Pricing:</div>
                <div className="fs-6 fw-bolder">
                  {formatNumber(paymentsDetailData?.dynamic_pricing) || 0}
                </div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Reschedule Fee:</div>
                <div className="fs-6 fw-bolder">
                  {formatNumber(paymentsDetailData?.reschedule_fee) || 0}
                </div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack">
                <div className="fs-6">Fee for User:</div>
                <div className="fs-6 fw-bolder">
                  {formatNumber(paymentsDetailData?.fee_for_user) || 0}
                </div>
              </div>
              <div className="separator separator-dashed my-4 " />
              <div className="d-flex flex-stack align-items-start">
                <div className="fs-6">
                  <div className="mt-2"> Visa Fee:</div>
                  <div className="mt-2">
                    <small className="text-danger fs-9">
                      Visa Fee has not been added to User Fee yet. <br />
                      This payment could be pending. Please recheck.
                    </small>
                  </div>
                </div>
                <div className="fs-6 fw-bolder">
                  <div className="position-relative">
                    <span className="position-absolute top-50 translate-middle-y ms-5">
                      {/* ₹ */}
                    </span>

                    {formatNumber(paymentsDetailData?.visa_fee) || 0}
                    {/* <input
                      type="text"
                      className="border-0 form-control ps-7 form-control-sm w-150px fs-6 text-end text-dark"
                      placeholder="Enter Vise Fee"
                    /> */}
                  </div>
                </div>
              </div>
              <div className="mt-10">
                {paymentsDetailData?.transactions &&
                  paymentsDetailData?.transactions.length > 0 && (
                    <h4 className="mb-8">Transaction Status</h4>
                  )}
                {paymentsDetailData?.transactions &&
                paymentsDetailData?.transactions.length > 0
                  ? paymentsDetailData?.transactions.map((item, i) => {
                      return (
                        <>
                          <div className>
                            <div className="mb-2 fs-6">
                              <span className="fw-bold">Status:</span>{" "}
                              {item?.status || "-"}
                            </div>
                            <div className="mb-2 fs-6">
                              <span className="fw-bold">Made on:</span>{" "}
                              {item?.created_at
                                ? moment(item?.created_at).format("DD-MM-YYYY")
                                : "-"}
                            </div>
                          </div>

                          {i > 1 && (
                            <>
                              {" "}
                              <div className="separator separator-dashed my-2"></div>
                            </>
                          )}
                        </>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ChangeEditionsModal
        data={editionData}
        onHide={() => {
          setChangeEditionModal(false);
          getEditionOptions();
        }}
        show={changeEditionModal}
        editionsList={editionsList}
      />

      <Alert
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        // onSuccess={() => {
        //   getData();
        // }}
        onAction={() => {
          toast.loading("Please wait...");
          deleteItem();
        }}
      />
    </>
  );
};

export default Booking;
