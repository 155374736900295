import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { DatePicker, Select } from "antd";
import { fetchData } from "../../../config/service";
import dayjs from "dayjs";
import { validateFields } from "../../../components/validation";
import { LIMITS } from "../../../config";
import { Loader, Pagination } from "../../../components";
import moment from "moment";

export default function TribeMemberModal({ show, onHide }) {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [limit, setLimit] = useState(LIMITS[0]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [tribeModal, setTribeModal] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    fetchData(
      `api/tribe/tribe-members/?limit=${limit}&offset=${
        (activePage - 1) * limit
      }
      `,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Tribe Members <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          {loading ? (
            <div className="table-responsive">
              <Loader cols={7} width={250} />
            </div>
          ) : (
            <div>
              {data?.length > 0 ? (
                <>
                  <div className="table-responsive tbl-sales">
                    {/*begin::Table*/}
                    <table
                      className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                      id
                    >
                      <thead>
                        <tr className="fw-bolder text-gray-900 bg-light-primary">
                          {/* <th class="min-w-75px">Sr. No.</th> */}
                          <th className="min-w-125px">Event</th>
                          <th className="min-w-100px">Start Date</th>
                          {/* <th className="min-w-125px">Project Name</th> */}
                        </tr>
                      </thead>
                      <tbody className="fw-semibold fs-7">
                        {data.map((item, index) => (
                          <tr key={item.id}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3 cursor-pointer">
                                  {item?.member?.photo ? (
                                    <img
                                      src={
                                        item?.member?.photo ||
                                        "/media/blank.png"
                                      }
                                      alt=""
                                      width={60}
                                    />
                                  ) : (
                                    <div class="symbol symbol-circle symbol-50px mr-3">
                                      <span
                                        class="symbol-label fs-2 text-primary bg-light-primary"
                                        style={{
                                          border: "1px dashed #7843e6",
                                          borderRadius: "50%",
                                        }}
                                      >
                                        {item?.member?.first_name
                                          .charAt(0)
                                          .toUpperCase()}
                                      </span>
                                    </div>
                                  )}
                                </div>

                                <div className="d-flex flex-column">
                                  <div className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6">
                                    {item?.member?.first_name +
                                      "" +
                                      item?.member?.last_name || "-"}
                                  </div>

                                  <div className="text-gray-500">
                                    {item?.member?.designation}
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td>
                              {item.updated_at
                                ? moment(item.updated_at).format("DD MMM YYYY")
                                : "N/A"}
                            </td>
                            {/* <td>{item.project_name}</td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/*end::Table*/}
                  </div>

                  <div className="separator separator-dashed border-gray-200" />
                  <Pagination
                    setLimit={setLimit}
                    limit={limit}
                    data={data}
                    activePage={activePage}
                    totalRecords={totalRecords}
                    onChange={(pageNumber) => {
                      setLoading(true);
                      setActivePage(pageNumber);
                    }}
                  />
                </>
              ) : (
                <div className="row g-5 mb-0 gx-md-10">
                  <div className="col-md-12 text-center">
                    <div className="mt-0">
                      <img
                        src="/media/no-data-found.png"
                        className="mw-25"
                        alt="No Data Found"
                      />
                    </div>
                    <h1 className="mt-4">No Data Found</h1>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
