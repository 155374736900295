import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Select } from "../../../components";

export default function DetractorModal({ show, notesOptions, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [detractorData, setDetractorData] = useState({
    note_type: null,
    note: null,
  });

  const onSubmitUser = async () => {
    setSending(true);

    const body = {
      user: data?.uuid,
      note: detractorData?.note,
      note_type: detractorData?.note_type,
    };

    fetchData(`api/note/`, "post", body).then((res) => {
      toast.remove();
      setSending(false);
      if (res.success) {
        onHide();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setDetractorDataValue = (key, value) => {
    const common_data = {
      ...detractorData,
      [key]: value,
    };

    setDetractorData(common_data);
  };

  return (
    <Modal show={show} onHide={onHide} size="sm" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">Detractor Notes</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-9 align-items-center">
          <div className="col-12">
            <label htmlFor className=" fs-6 fw-bold mb-2">
              Reason
            </label>
            <textarea
              className="form-control fs-7"
              placeholder="Add Reason"
              cols={30}
              rows={3}
              value={detractorData?.note}
              onChange={(e) => setDetractorDataValue("note", e.target.value)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            toast.loading("Adding Note");
            onSubmitUser();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
