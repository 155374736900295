import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { fetchData } from "../../../../../config/service";
import toast from "react-hot-toast";
import { Dropzone } from "../../../../../components";
import { DatePicker, message, Select, Spin, TimePicker, Tooltip } from "antd";
import { validateFields } from "../../../../../components/validation";
import moment from "moment";
import dayjs from "dayjs";

export default function HotelDetail({
  show,
  airportList,
  setFilterData,
  setActivePage,
  airportModalDetails,
  setAirportModalDetails,
  onHide,
  data,
  hotelList,
  hotelLoading,
  hotelModalDetails,
  setHotelModalDetails,
  formData,
  setFormData,
  parseISOToUTCDate,
  disabledDate,
}) {
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);

  //   const [isDuplicate, setIsDuplicate] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAirportModalDetails({
      ...airportModalDetails,
      [name]: value,
    });
  };

  const handleTimeChange = (name, time) => {
    setAirportModalDetails({
      ...airportModalDetails,
      [name]: time,
    });
  };

  //   const clearInput = () => {
  //     setFormState({
  //       day: "",
  //       title: "",
  //       hotel: "",
  //       agendas: [""],
  //       photo: "",
  //       destination: "",
  //       experience: "",
  //       aboutDay: "",
  //       trip_highlight: false,
  //     });
  //     setPictures([]);
  //     setRemovedImages([]);
  //   };

  const validateHotelModalDetails = (details) => {
    return details.some(
      (detail) =>
        detail.hotel.trim() !== "" && detail.confirmation_number.trim() !== ""
    );
  };

  const onSubmit = () => {
    // Usage
    if (!validateHotelModalDetails(hotelModalDetails)) {
      toast.error("Please add at least one hotel with confirmation.");
      return;
    } else {
      handleHide(true);
    }
  };

  const handleHide = (canClose = false) => {
    if (canClose) {
      onHide(); // Close modal if `canClose` is true
    } else {
      message.warning(
        "Please fill in all the required fields before closing the modal."
      );
    }
  };

  //   const [hotelModalDetails, setHotelModalDetails] = useState([
  //     { hotel: "", confirmation_number: "" },
  //   ]);

  const [isDuplicate, setIsDuplicate] = useState(false);

  const addHotelRow = () => {
    setHotelModalDetails([
      ...hotelModalDetails,
      { hotel: "", confirmation_number: "" },
    ]);
  };

  // Remove a row
  const removeHotelRow = (index) => {
    const newHotelModalDetails = [...hotelModalDetails];
    newHotelModalDetails.splice(index, 1); // Remove the item at the given index
    setHotelModalDetails(newHotelModalDetails);
  };

  // Handle the change in hotel selection
  const handleHotelChange = (value, index) => {
    const newHotelModalDetails = [...hotelModalDetails];
    newHotelModalDetails[index].hotel = value;
    setHotelModalDetails(newHotelModalDetails);
  };

  // Handle the change in confirmation_number number
  const handleConfirmationChange = (e, index) => {
    const newHotelModalDetails = [...hotelModalDetails];
    newHotelModalDetails[index].confirmation_number = e.target.value;
    setHotelModalDetails(newHotelModalDetails);
  };

  return (
    <Modal centered show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <h3 className="modal-title">Hotel Details</h3>
      </Modal.Header>
      <div>
        <div
          className="modal-body pb-7 pt-5 px-lg-10"
          style={
            loading
              ? {
                  filter: "blur(5px)",
                  pointerEvents: "none",
                }
              : {}
          }
        >
          <div
            className="scroll-y pe-7 ps-3 h-400px"
            id="kt_modal_add_customer_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_customer_header"
            data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
            data-kt-scroll-offset="300px"
          >
            <div className="row g-7">
              {hotelModalDetails.map((detail, index) => (
                <div className="row g-7" key={index}>
                  <div className="col-md-5 fv-row">
                    <label className="fs-6 fw-bold mb-2">Add Hotel</label>
                    <Select
                      showSearch
                      optionFilterProp="label"
                      value={detail.hotel}
                      onChange={(value) => handleHotelChange(value, index)}
                      placeholder="Select Hotel"
                      className="fs-7 w-100"
                      style={{ width: "100%" }}
                      dropdownStyle={{ zIndex: 1100 }}
                      size="large"
                      options={hotelList}
                      disabled={isDuplicate}
                      loading={hotelLoading}
                    />
                  </div>

                  <div className="col-md-5 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Confirmation Number
                    </label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      value={detail.confirmation_number}
                      onChange={(e) => handleConfirmationChange(e, index)}
                    />
                  </div>

                  {/* Show Add/Remove button */}
                  <div className="col-md-2 fv-row d-flex align-items-center">
                    {index === 0 ? (
                      <Tooltip title="Add">
                        <div
                          className="ms-2 mt-8 cursor-pointer"
                          onClick={addHotelRow}
                        >
                          <i className="ki-outline fs-1 ki-plus-square text-success"></i>
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Remove ">
                        <div
                          className="ms-2 mt-8 cursor-pointer"
                          onClick={() => removeHotelRow(index)}
                        >
                          <i className="ki-outline fs-1 ki-minus-square text-danger"></i>
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              ))}

              <div className="col-md-6 fv-row">
                <label className="fs-6 fw-bold mb-2">Hotel Check In Date</label>
                <DatePicker
                  // showTime={{
                  //   format: "HH:mm",
                  // }}
                  format="DD-MM-YYYY"
                  value={
                    formData.hotel_check_in
                      ? parseISOToUTCDate(formData.hotel_check_in)
                      : null
                  }
                  onChange={(date, dateString) => {
                    // Convert date to ISO format in UTC if dateString is valid
                    const utcDateString = date
                      ? date.format("YYYY-MM-DD")
                      : null;

                    setFormData({
                      ...formData,
                      hotel_check_in: utcDateString,
                      hotel_check_out: null,
                    });
                  }}
                  className="fs-7 w-100"
                  placeholder="Pick a Hotel Check In Time"
                  disabledDate={(current) =>
                    disabledDate(
                      current,
                      "start",
                      null,
                      formData?.startDate,
                      formData?.endDate
                    )
                  }
                  size="large"
                />
              </div>

              <div className="col-md-6 fv-row">
                <label className="fs-6 fw-bold mb-2">
                  Hotel Check Out Date
                </label>

                <DatePicker
                  // showTime={{
                  //   format: "HH:mm",
                  // }}
                  format="DD-MM-YYYY"
                  value={
                    formData.hotel_check_out
                      ? parseISOToUTCDate(formData.hotel_check_out)
                      : null
                  }
                  onChange={(date, dateString) => {
                    // Convert date to ISO format in UTC if dateString is valid
                    const utcDateString = date
                      ? date.format("YYYY-MM-DD")
                      : null;

                    setFormData({
                      ...formData,
                      hotel_check_out: utcDateString,
                    });
                  }}
                  className="fs-7 w-100"
                  placeholder="Pick a Hotel Checkout Time"
                  disabledDate={(current) =>
                    disabledDate(
                      current,
                      "end",
                      formData?.hotel_check_in,
                      formData?.startDate,
                      formData?.endDate
                    )
                  }
                  size="large"
                />
              </div>

              {/* for debugging */}

              {/* <div className="col-md-12 fv-row mt-3">
                <pre>{JSON.stringify(hotelModalDetails, null, 2)}</pre>{" "}
               
              </div> */}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light fs-7"
            onClick={() => onHide()}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary fs-7"
            onClick={() => onSubmit()}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
}
