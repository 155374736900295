import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";

import toast from "react-hot-toast";

import { DatePicker, message, Select, Tabs, TimePicker, Tooltip } from "antd";
import { fetchData } from "../../../../../config/service";
import { validateFields } from "../../../../../components/validation";
import moment from "moment";
import dayjs from "dayjs";
import { parseISOToUTCDate } from "../../../../../config";

const DynamicInputList = ({ label, items, onItemsChange }) => {
  // Handle changes in a specific input field
  const handleChange = (index, event) => {
    const updatedItems = [...items];
    updatedItems[index] = event.target.value;
    onItemsChange(updatedItems);
  };

  // Add a new empty input field
  const handleAdd = () => {
    onItemsChange([...items, ""]);
  };

  // Remove an input field by index
  const handleRemove = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    onItemsChange(updatedItems);
  };

  return (
    <div className="row mb-3">
      <div className="col-md-12">
        <label className="fs-6 fw-bold mb-2">{label}</label>
        {items?.map((item, index) => (
          <div key={index} className="d-flex align-items-center mb-2">
            <input
              type="text"
              value={item}
              onChange={(e) => handleChange(index, e)}
              className="form-control fs-7 me-2" // Margin-end to separate from icons
            />
            {index === 0 ? (
              <div
                onClick={handleAdd}
                className=" p-2 d-flex align-items-center justify-content-center cursor-pointer"
              >
                <i className="ki-outline fs-1 ki-plus-square text-success"></i>
              </div>
            ) : (
              <div
                onClick={() => handleRemove(index)}
                className=" p-2 d-flex align-items-center justify-content-center cursor-pointer"
              >
                <i className="ki-outline fs-1 ki-minus-square text-danger"></i>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default function GamePlanModal({
  show,
  onHide,
  gamePlanId,
  data,
  eventDate,
}) {
  const [sending, setSending] = useState(false);

  const [budgetsAndFinance, setBudgetsAndFinance] = useState([""]);
  const [recommendations, setRecommendations] = useState([""]);
  const [facilitatorTodos, setFacilitatorTodos] = useState([""]);

  // State object for form fields
  const [formState, setFormState] = useState({
    city: "",
    day: "",
    timeSlot: "",
    date: "",
    startTime: null,
    endTime: null,
    title: "",
    googleMapsLink: "",
    ticketsLink: "",
    mealInclusion: "",
    bucketlistId: "",
    recommendations: "",
    specialNotes: "",
    facilitatorTodos: "",
    budgetsAndFinance: "",
    pocName: "",
    pocPhoneNumber: "",
    roomDistributionLink: "",
    teamStayAndFlights: "",
    feedbackComments: "",
  });

  useEffect(() => {
    if (data?.id) {
      // console.log(data, "data");
      setFormState({
        city: data.city,
        day: "",
        timeSlot: data.time_slot,
        date: "",
        startTime: data.start_time,
        endTime: data.end_time,
        title: data.title,
        googleMapsLink: data.google_maps,
        ticketsLink: data.tickets,
        mealInclusion: data.meal_inclusion,
        recommendations: data.recommendations,
        specialNotes: data.special_notes,
        facilitatorTodos: data.facilitator_todos,
        budgetsAndFinance: data.budgets_and_finance,
        pocName: data.poc_name,
        pocPhoneNumber: data.poc_phone_number,
        roomDistributionLink: data.room_distrubution,
        teamStayAndFlights: data.team_stay_and_flights,
        feedbackComments: data.feedback,
      });
      setBudgetsAndFinance(
        data?.budgets_and_finance.length > 0 ? data?.budgets_and_finance : [""]
      );
      setRecommendations(
        data?.recommendations.length > 0 ? data?.recommendations : [""]
      );
      setFacilitatorTodos(
        data?.facilitator_todos.length > 0 ? data?.facilitator_todos : [""]
      );
    } else {
      setFormState({
        city: "",
        day: "",
        timeSlot: "",
        date: "",
        startTime: null,
        endTime: null,
        title: "",
        googleMapsLink: "",
        ticketsLink: "",
        mealInclusion: "",
        bucketlistId: "",
        recommendations: "",
        specialNotes: "",
        facilitatorTodos: "",
        budgetsAndFinance: "",
        pocName: "",
        pocPhoneNumber: "",
        roomDistributionLink: "",
        teamStayAndFlights: "",
        feedbackComments: "",
      });
      setBudgetsAndFinance([""]);
      setRecommendations([""]);
      setFacilitatorTodos([""]);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const clearInput = () => {
    setFormState({
      status: "",
      nameOfIP: "",
      city: "",
      day: "",
      timeSlot: "",
      date: "",
      startTime: "",
      endTime: "",
      title: "",
      googleMapsLink: "",
      ticketsLink: "",
      mealInclusion: "",
      bucketlistId: "",
      recommendations: "",
      specialNotes: "",
      facilitatorTodos: "",
      budgetsAndFinance: "",
      pocName: "",
      pocPhoneNumber: "",
      roomDistributionLink: "",
      teamStayAndFlights: "",
      feedbackComments: "",
    });
  };

  const rules = {
    city: {
      required: true,
      type: "string",
      maxLength: 50,
    },
    day: {
      required: false,
      type: "number",
    },
    timeSlot: {
      required: false,
      type: "string",
      maxLength: 20,
    },
    date: {
      required: false,
    },
    startTime: {
      required: true,
    },
    endTime: {
      required: true,
    },
    title: {
      required: true,
      type: "string",
      maxLength: 100,
    },
    googleMapsLink: {
      required: false,
      // type: "url",
    },
    ticketsLink: {
      required: false,
      // type: "url",
    },
    mealInclusion: {
      required: false,
    },

    recommendations: {
      required: false,

      maxLength: 500,
    },
    specialNotes: {
      required: false,

      maxLength: 500,
    },
    facilitatorTodos: {
      required: false,

      maxLength: 500,
    },
    budgetsAndFinance: {
      required: false,

      maxLength: 500,
    },
    pocName: {
      required: false,

      maxLength: 100,
    },
    pocPhoneNumber: {
      required: false,
    },
    roomDistributionLink: {
      required: false,
      // type: "url",
    },
    teamStayAndFlights: {
      required: false,
      // type: "url",
    },
    feedbackComments: {
      required: false,
    },
  };

  const onSubmit = () => {
    const { isValid, errors } = validateFields(formState, rules);

    if (!isValid) {
      return;
    } else {
      if (data?.id) {
        toast.loading("Editing Game Segment....");
      } else {
        toast.loading("Adding Game Segment....");
      }
    }

    setSending(true);

    const body = {
      game_plan: gamePlanId || "",
      ...(formState.pocName && { poc_name: formState.pocName }),
      ...(formState.pocPhoneNumber && {
        poc_phone_number: formState.pocPhoneNumber,
      }),
      ...(formState.city && { city: formState.city }),
      ...(formState.title && { title: formState.title }),
      ...(formState.mealInclusion && {
        meal_inclusion: formState.mealInclusion,
      }),
      ...(formState.specialNotes && { special_notes: formState.specialNotes }),
      ...(formState.feedbackComments && {
        feedback: formState.feedbackComments,
      }),
      ...(formState.startTime && { start_time: formState.startTime }), // Ensure correct format
      ...(formState.endTime && { end_time: formState.endTime }), // Ensure correct format
      ...(formState.timeSlot && { time_slot: formState.timeSlot }),
      ...(facilitatorTodos &&
        facilitatorTodos.length > 0 && { facilitator_todos: facilitatorTodos }),
      ...(budgetsAndFinance &&
        budgetsAndFinance.length > 0 && {
          budgets_and_finance: budgetsAndFinance,
        }),
      ...(recommendations &&
        recommendations.length > 0 && { recommendations: recommendations }),
      ...(formState.googleMapsLink && {
        google_maps: formState.googleMapsLink,
      }),
      ...(formState.ticketsLink && { tickets: formState.ticketsLink }),
      ...(formState.roomDistributionLink && {
        room_distribution: formState.roomDistributionLink,
      }),
      ...(formState.teamStayAndFlights && {
        team_stay_and_flights: formState.teamStayAndFlights,
      }),
    };

    let api = data?.id
      ? `api/gameplan/gamesegments/${data?.id}/`
      : "api/gameplan/gamesegments/";
    let method = data?.id ? "put" : "post";

    fetchData(api, method, body, "").then((res) => {
      setSending(false);
      toast.remove();
      if (res.success) {
        toast.success("Success");
        onHide();
        clearInput();
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <Modal centered show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <h3 className="modal-title">Add GamePlan</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              {" "}
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="fs-6 fw-bold mb-2">Title</label>
                  <input
                    type="text"
                    name="title"
                    value={formState.title}
                    onChange={handleChange}
                    className="form-control fs-7"
                  />
                </div>

                <div className="col-md-6">
                  <label className="fs-6 fw-bold mb-2">City</label>
                  <input
                    type="text"
                    name="city"
                    value={formState.city}
                    onChange={handleChange}
                    className="form-control fs-7"
                  />
                </div>
              </div>
              {/* <div className="row mb-3">
                <div className="col-md-6">
                  <label className="fs-6 fw-bold mb-2">Day</label>
                  <input
                    type="text"
                    name="day"
                    value={formState.day}
                    onChange={handleChange}
                    className="form-control fs-7"
                  />
                </div>
                <div className="col-md-6">
                  <label className="fs-6 fw-bold mb-2">Date</label>
                  <DatePicker
                    showTime={{
                      format: "HH:mm",
                      defaultValue: dayjs().hour(14).minute(0),
                    }}
                    format="YYYY-MM-DD HH:mm"
                    value={
                      formState.date
                        ? dayjs(formState.date, "YYYY-MM-DD HH:mm") // Parse the existing start date using Day.js
                        : null
                    }
                    onChange={(date, dateString) => {
                      setFormState({
                        ...formState,
                        date: dateString ? dateString : null,
                      });
                    }}
                    className="fs-2 w-100"
                    placeholder="Pick a  Date"
                    size="large"
                  />
                </div>
              </div> */}
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="fs-6 fw-bold mb-2">Start Time</label>
                  <TimePicker
                    className="fs-6 w-100"
                    size="large"
                    value={
                      formState.startTime
                        ? parseISOToUTCDate(formState.startTime)
                        : null
                    }
                    onChange={(time, timeString) => {
                      const selectedTime = time.format("HH:mm"); // Extract just the time part
                      const updatedDateTime =
                        dayjs(eventDate).format("YYYY-MM-DD") +
                        " " +
                        selectedTime; // Combine with fixed date

                      setFormState({
                        ...formState,
                        startTime: updatedDateTime, // Store full date-time in startTime
                      });
                    }}
                    format="HH:mm"
                  />
                </div>
                <div className="col-md-6">
                  <label className="fs-6 fw-bold mb-2">End Time</label>
                  <TimePicker
                    className="fs-6 w-100"
                    size="large"
                    value={
                      formState.endTime
                        ? parseISOToUTCDate(formState.endTime)
                        : null // Initialize with eventDate and time set to 00:00
                    }
                    onChange={(time, timeString) => {
                      const selectedTime = time.format("HH:mm"); // Extract just the time part
                      const updatedDateTime =
                        dayjs(eventDate).format("YYYY-MM-DD") +
                        " " +
                        selectedTime; // Combine with fixed date

                      setFormState({
                        ...formState,
                        endTime: updatedDateTime, // Store full date-time in startTime
                      });
                    }}
                    format="HH:mm"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="fs-6 fw-bold mb-2">POC Name</label>
                  <input
                    type="text"
                    name="pocName"
                    value={formState.pocName}
                    onChange={handleChange}
                    className="form-control fs-7"
                  />
                </div>
                <div className="col-md-6">
                  <label className="fs-6 fw-bold mb-2">POC Phone Number</label>
                  <input
                    type="tel"
                    name="pocPhoneNumber"
                    value={formState.pocPhoneNumber}
                    onChange={handleChange}
                    className="form-control fs-7"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="fs-6 fw-bold mb-2">
                    Room Distribution Link
                  </label>
                  <input
                    type="url"
                    name="roomDistributionLink"
                    value={formState.roomDistributionLink}
                    onChange={handleChange}
                    className="form-control fs-7"
                  />
                </div>
                <div className="col-md-6">
                  <label className="fs-6 fw-bold mb-2">Google Maps Link</label>
                  <input
                    type="url"
                    name="googleMapsLink"
                    value={formState.googleMapsLink}
                    onChange={handleChange}
                    className="form-control fs-7"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="fs-6 fw-bold mb-2">Special Notes</label>
                  <input
                    type="text"
                    name="specialNotes"
                    value={formState.specialNotes}
                    onChange={handleChange}
                    className="form-control fs-7"
                  />
                </div>
                <div className="col-md-6">
                  {/* Split Form Fields */}
                  <label className="fs-6 fw-bold mb-2">Time Slot</label>
                  <Select
                    // mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Select Time Slots"
                    value={
                      Number(formState.timeSlot) > 8 ? null : formState.timeSlot
                    }
                    onChange={(value) => {
                      setFormState({ ...formState, timeSlot: value });
                    }}
                    dropdownStyle={{ zIndex: 1111 }}
                    options={[
                      { label: "Early Morning", value: 1 },
                      { label: "Morning", value: 2 },
                      { label: "Noon", value: 3 },
                      { label: "Afternoon", value: 4 },
                      { label: "Late Afternoon", value: 5 },
                      { label: "Evening", value: 6 },
                      { label: "Late Night", value: 7 },
                      { label: "Night", value: 8 },
                    ]}
                    size="large"
                  ></Select>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="fs-6 fw-bold mb-2">
                    Team Stay & Team Flights
                  </label>
                  <input
                    type="text"
                    name="teamStayAndFlights"
                    value={formState.teamStayAndFlights}
                    onChange={handleChange}
                    className="form-control fs-7"
                  />
                </div>
                <div className="col-md-6">
                  <label className="fs-6 fw-bold mb-2">Feedback/Comments</label>
                  <input
                    type="text"
                    name="feedbackComments"
                    value={formState.feedbackComments}
                    onChange={handleChange}
                    className="form-control fs-7"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="fs-6 fw-bold mb-2">Tickets Link</label>
                  <input
                    type="url"
                    name="ticketsLink"
                    value={formState.ticketsLink}
                    onChange={handleChange}
                    className="form-control fs-7"
                  />
                </div>
                <div className="col-md-6">
                  <label className="fs-6 fw-bold mb-2">Meal Inclusion</label>
                  <input
                    type="text"
                    name="mealInclusion"
                    value={formState.mealInclusion}
                    onChange={handleChange}
                    className="form-control fs-7"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <DynamicInputList
                  label="Budgets & Finance"
                  items={budgetsAndFinance}
                  onItemsChange={setBudgetsAndFinance}
                />
                <DynamicInputList
                  label="Recommendations"
                  items={recommendations}
                  onItemsChange={setRecommendations}
                />
                <DynamicInputList
                  label="Facilitator Todos"
                  items={facilitatorTodos}
                  onItemsChange={setFacilitatorTodos}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light fs-7" onClick={onHide}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => onSubmit()} // Handle form submission here
        >
          {data?.id ? "Edit Game Segment" : "Add Game Segment"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
