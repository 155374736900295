import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { PROPERTIES } from "../../../../config";
import toast from "react-hot-toast";
import { fetchData } from "../../../../config/service";
import { Dropzone } from "../../../../components";
import { DatePicker, Input, Select, TimePicker } from "antd";
import dayjs from "dayjs";

function separatePhoneNumber(phone) {
  const phoneLength = phone.length;

  if (phoneLength >= 10) {
    const countryCodeLength = phoneLength - 10;
    const localCountryCode = phone.slice(0, countryCodeLength);
    const localNumber = phone.slice(countryCodeLength);

    return {
      localCountryCode,
      localNumber,
    };
  } else {
    throw new Error("Invalid phone number format");
  }
}

export default function Manage({
  show,
  item,
  onHide,
  destinationList,
  propertyTypeList,
  onSuccess,
}) {
  const [image, setImage] = useState(null);
  const [pictures, setPictures] = useState([]);
  const [name, setName] = useState("");
  const [destination, setDestination] = useState("");
  const [starRating, setStarRating] = useState(0);
  const [googleRating, setGoogleRating] = useState(0);
  const [about, setAbout] = useState("");
  const [property, setProperty] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [mapLink, setMapLink] = useState("");
  const [roomSize, setRoomSize] = useState("");
  const [arrivalDate, setArrivalDate] = useState(null);
  const [departureDate, setDepartureDate] = useState(null);
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [address, setAddress] = useState("");
  const [sending, setSending] = useState(false);
  const [removedImages, setRemovedImages] = useState([]);

  const [images, setImages] = useState({
    hotelImage: null,
  });
  const [imagePreviews, setImagePreviews] = useState({
    hotelImagePreview: null,
  });

  const [hotelCheckIn, setHotelCheckIn] = useState(null);
  const [hotelCheckOut, setHotelCheckOut] = useState(null);
  // useEffect(() => {
  //   prefillData();
  // }, []);

  useEffect(() => {
    if (item) {
      const { localCountryCode, localNumber } = separatePhoneNumber(
        item?.phone
      );
      setName(item.name);
      setDestination(item.destination);
      setStarRating(item.star_rating);
      setGoogleRating(item.google_rating);
      setAbout(item.about);
      setProperty(item.property_type);
      setConfirmation(item.confirmation_number);
      setMapLink(item.google_map_link);
      setRoomSize(item.room_size);
      setArrivalDate(item.arrival_date);
      setDepartureDate(item.departure_date);
      setCountryCode(localCountryCode);
      setPhone(localNumber);
      setHotelCheckIn(item?.hotel_check_in);
      setHotelCheckOut(item?.hotel_check_out);
      setAddress(item.address);
      setPictures(item.images);
      setImagePreviews({
        hotelImagePreview: item?.default_image,
      });
      setImages({
        hotelImage: item?.default_image,
      });
    } else {
      setName("");
      setDestination("");
      setStarRating("");
      setGoogleRating("");
      setAbout("");
      setProperty("");
      setConfirmation("");
      setMapLink("");
      setRoomSize("");
      setArrivalDate("");
      setDepartureDate("");
      setCountryCode("+91");
      setPhone("");
      setAddress("");
      setHotelCheckIn(null);
      setHotelCheckOut(null);
      setPictures([]);
      setRemovedImages([]);
      setImagePreviews({
        mainImagePreview: null,
      });
      setImages({
        mainImage: null,
      });
    }
  }, [item]);

  // const prefillData = () => {
  //   setName("Test hotel");
  //   setDestination("66b998ef25ed9e8180bceeaa");
  //   setAbout("About hotel");
  //   setProperty("1");
  //   setConfirmation("123455");
  //   setMapLink("https://map.com");
  //   setRoomSize("200sqft 150X200");
  //   setArrivalDate("2024-08-15");
  //   setDepartureDate("2024-08-20");
  //   setPhone("+919292929292");
  //   setAddress("Ring road");
  // };

  const onSubmit = () => {
    const isURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch {
        return false;
      }
    };

    if (
      !name ||
      !images?.hotelImage ||
      !destination ||
      !property ||
      !confirmation ||
      !mapLink ||
      !roomSize ||
      !countryCode ||
      !countryCode.includes("+") ||
      !phone ||
      phone.length < 10 ||
      !address ||
      pictures.length > 10
    ) {
      if (!name) {
        toast.error("Please add the hotel name.");
      } else if (!images?.hotelImage) {
        toast.error("Please select Default hotel image.");
      } else if (!destination) {
        toast.error("Please add the destination.");
      } else if (!property) {
        toast.error("Please add the property type.");
      } else if (!confirmation) {
        toast.error("Please add the confirmation number.");
      } else if (!mapLink) {
        toast.error("Please add the map link.");
      } else if (!isURL(mapLink)) {
        toast.error("Please enter a valid Google Map link.");
      } else if (!roomSize) {
        toast.error("Please enter room size dimensions.");
      }
      // else if (!arrivalDate) {
      //   toast.error("Please add the arrival date.");
      // } else if (!departureDate) {
      //   toast.error("Please add the departure date.");
      // }
      else if (!countryCode) {
        toast.error("Please Select country code.");
      } else if (countryCode.includes("+") === false) {
        toast.error("Please Select Valid country code.");
      } else if (!phone) {
        toast.error("Please add the phone number.");
      } else if (phone.length < 10) {
        toast.error("Please add the valid phone number.");
      } else if (!address) {
        toast.error("Please add the address.");
      } else if (pictures.length > 10) {
        toast.error("You can select up to 10 images only.");
      }
    } else {
      const isURL = (str) => {
        try {
          new URL(str);
          return true;
        } catch {
          return false;
        }
      };

      setSending(true);
      const body = {
        name: name,
        destination: destination,
        star_rating: starRating,
        google_rating: googleRating,
        about: about,
        property_type: property,
        // confirmation_number: confirmation,
        google_map_link: mapLink,
        // arrival_date: arrivalDate,
        // departure_date: departureDate,
        phone: countryCode + phone,
        address: address,
        room_size: roomSize,
        ...(images?.hotelImage && isURL(images?.hotelImage)
          ? {}
          : { default_image: images?.hotelImage || "" }),

        hotel_check_in: hotelCheckIn,
        hotel_check_out: hotelCheckOut,
      };

      console.log(body, "body");

      // if (image) {
      //   body.default_image = image;
      // }

      let uploadedPictures = pictures.filter((item) => item.path);
      if (uploadedPictures.length) {
        body.images = uploadedPictures;
      }

      if (removedImages.length) {
        body.delete_images = removedImages;
      }

      let api = item?.id ? `api/hotel/${item.id}/` : "api/hotel/";
      let method = item?.id ? "put" : "post";

      fetchData(api, method, body, "", true).then((res) => {
        setSending(false);
        if (res.success) {
          toast.success("Success");
          onHide();
          onSuccess();
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  const handleImagesChange = (e, imageType) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = () => {
        // Update the state with the file and its preview
        setImages((prevImages) => ({
          ...prevImages,
          [imageType]: file,
        }));

        setImagePreviews((prevImagePreviews) => ({
          ...prevImagePreviews,
          [`${imageType}Preview`]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImages = (imageType) => {
    setImages((prevImages) => ({
      ...prevImages,
      [imageType]: null,
    }));

    setImagePreviews((prevImagePreviews) => ({
      ...prevImagePreviews,
      [`${imageType}Preview`]: null,
    }));
  };

  const parseISOToUTCDate = (dateString) => {
    return dateString ? dayjs.utc(dateString) : null;
  };

  return (
    <Modal centered show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <h3 className="modal-title">{item ? "Edit Hotel" : "Add New Hotel"}</h3>
      </Modal.Header>
      <div className="modal-body pb-7 pt-5 px-lg-10">
        <div
          className="scroll-y pe-7 ps-3"
          id="kt_modal_add_customer_scroll"
          style={{
            height: "400px",
          }}
        >
          <div className="row g-5 mb-5 align-items-center">
            <div className="col-md-12 fv-row">
              <div>
                <label htmlFor className=" fs-6 fw-bold mb-2">
                  Images
                </label>
                <Dropzone
                  pictures={pictures}
                  setPictures={setPictures}
                  removedImages={removedImages}
                  setRemovedImages={setRemovedImages}
                />
              </div>
            </div>

            <div className="col-md-4 fv-row">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Hotel Image
              </label>

              {imagePreviews?.hotelImagePreview ? (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    marginLeft: "10px",
                  }}
                >
                  <img
                    src={imagePreviews?.hotelImagePreview}
                    alt="Image Preview"
                    style={{
                      width: "150px",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "8px",
                      border: "2px solid #ccc",
                    }}
                  />
                  <i
                    title="Remove Image"
                    className="ki-outline ki-cross fs-2"
                    onClick={() => handleRemoveImages("hotelImage")}
                    style={{
                      position: "absolute",
                      top: "-8px",
                      right: "-8px",
                      cursor: "pointer",
                      zIndex: 2,
                      backgroundColor: "white",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              ) : (
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  className="form-control fs-7"
                  onChange={(e) => handleImagesChange(e, "hotelImage")}
                />
              )}

              {/* <input
                type="file"
                accept=".png, .jpg, .jpeg"
                className="form-control fs-7"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    setImage(e.target.files[0]);
                  }
                }}
              /> */}
            </div>
            <div className="col-md-4 fv-row">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Name of Hotel
              </label>
              <input
                type="text"
                className="form-control fs-7"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>

            <div className="col-md-4 fv-row">
              <label htmlFor className=" fs-6 fw-bold mb-2 ">
                Select Destinations
              </label>

              <Select
                value={destination}
                className="w-100"
                placeholder="Select Destination"
                label="name"
                options={destinationList?.map((item) => {
                  return {
                    value: item?.id,
                    label: item?.name,
                  };
                })}
                onChange={(value) => {
                  setDestination(value);
                }}
                dropdownStyle={{ zIndex: 1111 }}
                size="large"
              />
            </div>

            <div className="col-md-6 fv-row d-flex align-items-center flex-wrap">
              <label htmlFor className=" fs-6 fw-bold mb-2 me-5">
                Star Rating
              </label>
              <div className="rating">
                <label
                  className="btn btn-light fw-bold btn-sm rating-label me-3"
                  onClick={() => {
                    setStarRating(0);
                  }}
                >
                  Reset
                </label>
                {[1, 2, 3, 4, 5].map((item) => (
                  <label key={item} onClick={() => setStarRating(item)}>
                    <i
                      className="ki-duotone ki-star fs-1"
                      style={{
                        color: starRating >= item ? "#FFAD0D" : "#ccc",
                        cursor: "pointer",
                      }}
                    />
                  </label>
                ))}
              </div>
            </div>

            <div className="col-md-6 fv-row d-flex align-items-center flex-wrap">
              <label htmlFor className=" fs-6 fw-bold mb-2 me-5">
                Google Rating
              </label>
              <div className="rating">
                <label
                  className="btn btn-light fw-bold btn-sm rating-label me-3"
                  onClick={() => {
                    setGoogleRating(0);
                  }}
                >
                  Reset
                </label>
                {[1, 2, 3, 4, 5].map((item) => (
                  <label key={item} onClick={() => setGoogleRating(item)}>
                    <i
                      className="ki-duotone ki-star fs-1"
                      style={{
                        color: googleRating >= item ? "#FFAD0D" : "#ccc",
                        cursor: "pointer",
                      }}
                    />
                  </label>
                ))}
              </div>
            </div>

            <div className="col-md-6 fv-row">
              <label className="fs-6 fw-bold mb-2">Hotel Check In Time</label>

              <TimePicker
                format="HH:mm"
                value={hotelCheckIn ? dayjs(hotelCheckIn, "HH:mm") : null}
                onChange={(time, timeString) => {
                  setHotelCheckIn(timeString);
                }}
                className="fs-7 w-100"
                placeholder="Pick a Hotel Checkout Time"
                size="large"
              />
            </div>

            <div className="col-md-6 fv-row">
              <label className="fs-6 fw-bold mb-2">Hotel Check Out Time</label>

              <TimePicker
                format="HH:mm"
                value={hotelCheckOut ? dayjs(hotelCheckOut, "HH:mm") : null}
                onChange={(time, timeString) => {
                  setHotelCheckOut(timeString);
                }}
                className="fs-7 w-100"
                placeholder="Pick a Hotel Checkout Time"
                size="large"
              />
            </div>

            <div className="col-md-12 fv-row">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                About the Hotel
              </label>
              <textarea
                name
                className="form-control fs-7"
                id
                cols={30}
                rows={3}
                value={about}
                onChange={(e) => {
                  setAbout(e.target.value);
                }}
              />
            </div>

            <div className="col-md-4 fv-row">
              <label htmlFor className=" fs-6 fw-bold mb-2 ">
                Property Type
              </label>

              <Select
                value={property}
                className="w-100"
                placeholder="Select Property Type"
                label="name"
                options={propertyTypeList?.map((item) => {
                  return {
                    value: item?.value,
                    label: item?.display_name,
                  };
                })}
                onChange={(value) => {
                  setProperty(value);
                }}
                dropdownStyle={{ zIndex: 1111 }}
                size="large"
              />

              {/* <select
                name
                className="form-select fs-7"
                value={property}
                onChange={(e) => {
                  setProperty(e.target.value);
                }}
              >
                <option value="" selected disabled>
                  Select an option
                </option>
                {propertyTypeList.map((item, i) => {
                  return (
                    <option value={item.value} key={i}>
                      {item?.display_name}
                    </option>
                  );
                })}
              </select> */}
            </div>

            {/* <div className="col-md-4 fv-row">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Confirmation Number
              </label>

              <input
                type="text"
                className="form-control fs-7"
                value={confirmation}
                onChange={(e) => {
                  setConfirmation(e.target.value);
                }}
              />
            </div> */}

            <div className="col-md-4 fv-row">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Google Map Link
              </label>
              <input
                type="text"
                className="form-control fs-7"
                value={mapLink}
                onChange={(e) => {
                  setMapLink(e.target.value);
                }}
              />
            </div>

            <div className="col-md-4 fv-row">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Property Phone
              </label>

              <div className="input-group">
                <input
                  style={{
                    width: "30%",
                  }}
                  className="form-control fs-7"
                  value={countryCode}
                  onChange={(e) => {
                    setCountryCode(e.target.value);
                  }}
                  size="large"
                />
                <input
                  style={{
                    width: "70%",
                  }}
                  value={phone}
                  className="form-control fs-7"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  size="large"
                />
              </div>

              {/* <input
                type="text"
                className="form-control fs-7"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                prefix="+91"
              /> */}
            </div>

            <div className="col-md-4 fv-row">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Room Size <small>(In. msq. or meter square)</small>
              </label>
              <input
                type="text"
                className="form-control fs-7"
                value={roomSize}
                onChange={(e) => {
                  setRoomSize(e.target.value);
                }}
              />
            </div>

            {/* <div className="col-md-4 fv-row">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Arrival Date
              </label>
              <input
                type="date"
                className="form-control fs-7"
                placeholder="Pick a Date"
                value={arrivalDate}
                onChange={(e) => {
                  setArrivalDate(e.target.value);
                }}
              />
            </div>
            <div className="col-md-4 fv-row">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Departure Date
              </label>
              <input
                type="date"
                className="form-control fs-7"
                placeholder="Pick a Date"
                value={departureDate}
                onChange={(e) => {
                  setDepartureDate(e.target.value);
                }}
              />
            </div> */}

            <div className="col-md-8 fv-row">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Property Address
              </label>
              <input
                type="text"
                className="form-control fs-7"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-light fs-7" onClick={onHide}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={onSubmit}
          disabled={sending}
        >
          {sending ? (
            <span>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </Modal>
  );
}
