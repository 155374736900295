import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";

import { Image, Select } from "antd";
import { fetchData } from "../../../../config/service";
import dayjs from "dayjs";
import { Loader } from "../../../../components";

export default function PassportCheckModal({ show, statusList, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [customerData, setCustomerData] = useState({
    status: null,
    score: null,
  });
  const [passportData, setPassportData] = useState([]);

  useEffect(() => {
    if (data) {
      getPassportData();
    }
  }, [data]);

  const getPassportData = () => {
    setLoading(true);
    fetchData(
      `api/travel_info/passport/details-update/?user=${data?.user?.uuid}`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        console.log(res, "res");
        setPassportData(res);
      } else {
        toast.error(res.message);
      }
    });
  };

  const formatDate = (dateString) => {
    return dateString ? dayjs.utc(dateString).format("DD-MM-YYYY h:mm a") : "-";
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Passport Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loader cols={2} />
        ) : (
          <>
            {passportData?.name_on_passport !== "" &&
            passportData?.passport_number !== "" ? (
              <>
                <div className="row p-3">
                  <div className="col-md-6">
                    <div className="d-flex align-items-center  mb-10">
                      <div className="symbol symbol-40 symbol-light-primary me-5">
                        <span className="symbol-label">
                          <i className="ki-outline ki-user text-primary fs-1"></i>
                        </span>
                      </div>
                      <div className="d-flex flex-column font-weight-bold">
                        <span className="text-dark fw-semibold mb-1">Name</span>
                        <span className="text-muted fw-semibold">
                          {passportData?.name_on_passport || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-10">
                      <div className="symbol symbol-40 symbol-light-info me-5">
                        <span className="symbol-label">
                          <i className="ki-outline ki-calendar text-info fs-1"></i>
                        </span>
                      </div>
                      <div className="d-flex flex-column font-weight-bold">
                        <span className="text-dark mb-1 fw-semibold">
                          Date of Birth
                        </span>
                        <span className="text-muted fw-semibold">
                          {formatDate(passportData?.dob_on_passport)}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-10">
                      <div className="symbol symbol-40 symbol-light-danger me-5">
                        <span className="symbol-label">
                          <i className="ki-outline ki-document text-danger fs-1"></i>
                        </span>
                      </div>
                      <div className="d-flex flex-column font-weight-bold">
                        <span className="text-dark mb-1 fw-semibold">
                          Passport Number
                        </span>
                        <span className="text-muted fw-semibold">
                          {passportData?.passport_number || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-40 symbol-light-warning me-5">
                        <span className="symbol-label">
                          <i className="ki-outline ki-timer  text-warning fs-1"></i>
                        </span>
                      </div>
                      <div className="d-flex flex-column font-weight-bold">
                        <span className="text-dark mb-1 fw-semibold">
                          Expiry Date
                        </span>
                        <span className="text-muted fw-semibold">
                          {formatDate(passportData?.expiry_date)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column align-items-center h-100">
                      <div className="flex-grow-1 mb-5">
                        <h5 className="fw-bold mb-3">Front Image</h5>
                        <div className="flex-grow-1">
                          <Image
                            src={passportData?.front_image}
                            alt="Passport Front"
                            height={110}
                            width={300}
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="fw-bold mb-3">Back Image</h5>
                        <div className=" flex-grow-1">
                          <Image
                            src={passportData?.back_image}
                            alt="Passport Back"
                            height={110}
                            width={300}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row g-5 mb-0 gx-md-10">
                  <div className="col-md-12 text-center">
                    <div className="mt-0">
                      <img
                        src="/media/no-data-found.png"
                        className="mw-25"
                        alt="No Data Found"
                      />
                    </div>
                    <h1 className="mt-4">No Data Found</h1>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
