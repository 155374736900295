import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Select } from "antd";

export default function NotesModal({ show, notesOptions, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [notesData, setNotesData] = useState({
    note_type: null,
    note: null,
  });

  const onSubmitUser = async () => {
    setSending(true);

    const body = {
      user: data?.uuid,
      note: notesData?.note,
      note_type: notesData?.note_type,
    };

    fetchData(`api/note/`, "post", body).then((res) => {
      toast.remove();
      setSending(false);
      if (res.success) {
        onHide();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setNotesDataValue = (key, value) => {
    const common_data = {
      ...notesData,
      [key]: value,
    };

    setNotesData(common_data);
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Add Notes <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-9 align-items-center">
          <div className="col-12">
            <label>
              <span className="required fw-semibold fs-5">
                Select Note Type
              </span>
            </label>
            {/* 
            <Select
              list={statusOptions}
              value="value"
              label="name"
              selected={notesData?.status}
              onChange={(value) => {
                console.log(value);
                setNotesDataValue("status", value);
              }}
              inModal={true}
            /> */}

            <Select
              options={notesOptions?.map((role) => {
                return {
                  label: role?.display_name,
                  value: role?.value,
                };
              })}
              value={notesData?.note_type}
              onChange={(e) => setNotesDataValue("note_type", e)}
              placeholder="Select Notes Subject"
              dropdownStyle={{ zIndex: 1111 }}
              style={{ width: "100%", marginTop: "10px" }}
              size="large"
            />

            {/* <select
              className="form-select"
              value={notesData?.note_type}
              onChange={(e) => setNotesDataValue("note_type", e.target.value)}
              // disabled="true"
            >
              {notesOptions?.map((role, i) => (
                <option key={i} value={role?.value}>
                  {role?.display_name}
                </option>
              ))}
            </select> */}
          </div>

          <div className="col-12">
            <label htmlFor className=" fs-6 fw-bold mb-2">
              Notes Description
            </label>
            <textarea
              className="form-control fs-7"
              placeholder="Add Notes"
              cols={30}
              rows={3}
              value={notesData?.note}
              onChange={(e) => setNotesDataValue("note", e.target.value)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            toast.loading("Adding Note");
            onSubmitUser();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
