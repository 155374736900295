import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Loader, Pagination } from "../../components";
import { fetchData } from "../../config/service";
import toast from "react-hot-toast";
import { formatNumber, LIMITS, routesList } from "../../config";
import dayjs from "dayjs";
import { format } from "crypto-js";
import { Link } from "react-router-dom";

const activityLogData = [
  {
    id: 1,
    user: "Alisha Mangla",
    action: "Visa Fee Added",
    details: "BHX Ball 6",
    amount: "INR 3000",
  },
  {
    id: 2,
    user: "Rahul Sharma",
    action: "Application Submitted",
    details: "Study Permit",
    amount: "INR 5000",
  },
  {
    id: 3,
    user: "Priya Patel",
    action: "Document Uploaded",
    details: "Passport Scan",
    amount: "N/A",
  },
  {
    id: 4,
    user: "Alex Johnson",
    action: "Payment Received",
    details: "Tuition Fee",
    amount: "INR 50000",
  },
  {
    id: 5,
    user: "Sarah Williams",
    action: "Interview Scheduled",
    details: "Embassy Meeting",
    amount: "N/A",
  },
];

export default function () {
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);

  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState([]);

  const [activityLoading, setActivityLoading] = useState(false);
  const [activityData, setActivityData] = useState([]);

  const [appChartData, setAppChartData] = useState([]);

  useEffect(() => {
    getCardData();

    getAppChartData();
  }, []);

  useEffect(() => {
    getActivityData();
  }, [activePage, limit]);

  const getCardData = () => {
    setLoading(true);

    fetchData(`api/dashboard/`, "get").then((res) => {
      setLoading(false);
      if (res.success) {
        setCardData(res);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getAppChartData = () => {
    setLoading(true);

    fetchData(`api/business-metrics/application-chart/`, "get").then((res) => {
      setLoading(false);
      if (res.success) {
        setAppChartData(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getActivityData = () => {
    setActivityLoading(true);

    fetchData(
      `api/dashboard/user-activities/?limit=${limit}&offset=${
        (activePage - 1) * limit
      }`,
      "get"
    ).then((res) => {
      setActivityLoading(false);
      if (res.success) {
        setTotalRecords(res.count);
        setActivityData(res.data);
      } else {
        setActivityData([]);
        toast.error(res.message);
      }
    });
  };

  const icons = [
    "ki-solid ki-people",
    "ki-solid ki-gear",
    "ki-solid ki-chart-simple-2",
    "ki-solid ki-flask ",
  ];

  const values = [
    cardData?.users,
    cardData?.pending_requests,
    cardData?.Conversion_rate,
    cardData?.calls_done,
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Step 2: Prepare the data for the chart
  const salesData =
    cardData &&
    cardData?.sales &&
    months.map((month) => cardData?.sales[month] || 0);

  const salesChartOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        const number = formatNumber(val);
        return number;
      },
    },
    series: [
      {
        name: "Sales",
        data: salesData,
      },
    ],
    colors: ["#7843e6"],
    xaxis: {
      categories: months,
    },
  };

  const applicationsChartOptions = {
    chart: {
      type: "area",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      area: {
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: appChartData.map((item) => item.date), // Extract dates as labels
      // labels: {
      //   format: "yyyy-MM-dd", // Format if necessary
      // },
      // tickAmount: 7,
    },

    // xaxis: {
    //   type: "datetime",
    //   title: {
    //     text: "Date",
    //   },
    // },
    yaxis: {
      title: {
        text: "Bookings",
      },
    },
    grid: {
      show: false, // Hide the grid lines on both x and y axes
    },
    fill: {
      opacity: 1,
    },
    colors: ["#7843e6"],
    series: [
      {
        name: "Sales",
        data: appChartData.map((item) => item.total_bookings),
      },
    ],
  };

  return (
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            {["Users", "Pending requests", "Conversion rate", "Calls Done"].map(
              (title, index) => (
                <div className="col-sm-6 col-xl-6 mb-xl-10" key={index}>
                  {/*begin::Card widget 2*/}
                  <div className="card h-lg-100 position-relative p-3">
                    {/*begin::Body*/}
                    <div className="card-body d-flex justify-content-between align-items-start flex-column">
                      {/*begin::Icon*/}
                      <div className="m-0">
                        <i
                          className={`${icons[index]} fs-2hx text-gray-600 ms-1`} // Dynamically set the icon class
                        ></i>
                      </div>
                      {/*end::Icon*/}
                      {/*begin::Section*/}
                      <div className="d-flex flex-column my-7">
                        {/*begin::Number*/}
                        <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                          {values[index] || 0}
                        </span>
                        {/*end::Number*/}
                        {/*begin::Follower*/}
                        <div className="m-0">
                          <span className="fw-semibold fs-6 text-gray-500">
                            {title}
                          </span>
                        </div>
                        {/*end::Follower*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                    {/*end::Body*/}

                    {/*begin::Background Icon*/}
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "40px",
                        opacity: "0.1",
                        transform:
                          "translateY(-80%) translateX(25%) rotate(0deg)",
                        transition: "all 0.3s",
                      }}
                      className="group"
                    >
                      <div
                        style={{
                          transition: "transform 0.3s",
                        }}
                        className="group-hover-transform"
                      >
                        <i
                          className={icons[index]} // Dynamically set the icon class
                          style={{ fontSize: "10rem" }}
                        ></i>
                      </div>
                    </div>
                    {/*end::Background Icon*/}
                  </div>
                  {/*end::Card widget 2*/}
                </div>
              )
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Applications</h5>
              <ReactApexChart
                options={applicationsChartOptions}
                series={applicationsChartOptions.series}
                type="area"
                height={376}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Sales</h5>
              <ReactApexChart
                options={salesChartOptions}
                series={salesChartOptions.series}
                type="bar"
                height={410}
              />
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-body py-2 pb-7 main-box">
              {activityLoading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <>
                  {/*begin::Table container*/}
                  <div className="table-responsive tbl-sales">
                    {/*begin::Table*/}
                    <table
                      className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                      id
                    >
                      <thead>
                        <tr className="fw-bolder text-gray-900 bg-white">
                          <th className="min-w-50px">Sr.no</th>
                          <th className="min-w-150px">User</th>
                          <th className="min-w-85px">Action</th>
                          <th className="min-w-80px">Created At</th>
                          {/* <th className="min-w-70px">Amount</th> */}
                        </tr>
                      </thead>
                      <tbody className=" fw-semibold fs-7">
                        {activityData?.length > 0
                          ? activityData?.map((item, index) => (
                              <tr
                                key={index}
                                className="bg-light cursor-pointer"
                              >
                                <td className="bdr-left-user text-center">
                                  {(activePage - 1) * limit + 1 + index}
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                      <img src="/media/blank.png" alt="" />
                                    </div>
                                    <div className="d-flex flex-column">
                                      <Link
                                        to={`${routesList[
                                          "customer_details"
                                        ].path.replace(":id", item.user_id)}`}
                                        className="text-primary mb-1 fw-bolder"
                                      >
                                        {item.user?.first_name +
                                          " " +
                                          item.user?.last_name}
                                      </Link>
                                      {/* <p className="text-primary mb-1 fw-bolder">
                                        {item.user?.first_name +
                                          " " +
                                          item.user?.last_name}
                                      </p> */}

                                      <span className="text-gray-600">
                                        {item.user?.first_name ||
                                          "hello@gmail.com"}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <span className="badge bg-secondary">
                                    {item.message || "-"}
                                  </span>
                                </td>
                                <td>
                                  {dayjs(item.created_at).format(
                                    "DD-MM-YYYY"
                                  ) || "-"}
                                </td>
                                {/* <td>{item.amount}</td> */}
                              </tr>
                            ))
                          : "No Data Found"}
                      </tbody>
                    </table>
                    {/*end::Table*/}
                  </div>
                  {/*end::Table container*/}
                </>
              )}

              {/*begin::Separator*/}
              <div className="separator separator-dashed border-gray-200 mb-n4" />
              {/*end::Separator*/}
              <Pagination
                setLimit={setLimit}
                limit={limit}
                data={activityData}
                activePage={activePage}
                totalRecords={totalRecords}
                onChange={(pageNumber) => {
                  setActivityLoading(true);
                  setActivePage(pageNumber);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
