import React from "react";

const InvoiceData = () => {
  return (
    <div className="card card-flush mb-6 mb-xl-9">
      <div className="card-header">
        <div className="card-title flex-column">
          <h2 className="mb-1">Data for Invoices</h2>
        </div>

        <div className="card-toolbar"></div>
      </div>

      <div className="card-body p-9 pt-2">
        <div className=" row g-5 mb-0 gx-md-10">
          <div className="col-md-12 text-center">
            <div className="mt-0">
              <img src="/media/no-data-found.png" className="mw-25" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceData;
