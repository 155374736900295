import React, { useState } from "react";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function () {
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [showPass3, setShowPass3] = useState(false);

  const changePassword = () => {
    if (
      !currentPassword ||
      !newPassword ||
      !confirmPassword ||
      newPassword !== confirmPassword
    ) {
      if (!currentPassword) {
        toast.error("Please enter your current password!");
      } else if (!newPassword) {
        toast.error("Please enter new password!");
      } else if (!confirmPassword) {
        toast.error("Please enter confirm password!");
      } else if (newPassword !== confirmPassword) {
        toast.error("Passwords do not match!");
      }
    } else {
      setWaiting(true);
      const body = {
        old_password: currentPassword,
        new_password: newPassword,
      };
      fetchData("api/accounts/change-password/", "post", body).then((res) => {
        setWaiting(false);
        if (res.success) {
          toast.success(res.message);
          navigate("/");
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  return (
    <div className="col-md-12">
      <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <div className="card-body p-9 pb-3">
          <div className="fv-row mb-3 password-container">
            <input
              type={showPass1 ? "text" : "password"}
              placeholder="Current Password"
              className="form-control bg-transparent h-60px border-gray-400 ps-7"
              value={currentPassword}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
            />
            <span
              className="eye-password"
              onClick={() => {
                setShowPass1(!showPass1);
              }}
            >
              <i
                className={`fs-1 ki-outline ${
                  showPass1 ? "ki-eye-slash" : "ki-eye"
                }  me-2`}
              />
            </span>
          </div>
          <div className="fv-row mb-3 password-container">
            <input
              type={showPass2 ? "text" : "password"}
              placeholder="New Password"
              className="form-control bg-transparent h-60px border-gray-400 ps-7"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
            <span
              className="eye-password"
              onClick={() => {
                setShowPass2(!showPass2);
              }}
            >
              <i
                className={`fs-1 ki-outline ${
                  showPass2 ? "ki-eye-slash" : "ki-eye"
                }  me-2`}
              />
            </span>
          </div>
          <div className="fv-row password-container">
            <input
              type={showPass3 ? "text" : "password"}
              placeholder="Confirm Password"
              className="form-control bg-transparent h-60px border-gray-400 ps-7"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            <span
              className="eye-password"
              onClick={() => {
                setShowPass3(!showPass3);
              }}
            >
              <i
                className={`fs-1 ki-outline ${
                  showPass3 ? "ki-eye-slash" : "ki-eye"
                }  me-2`}
              />
            </span>
          </div>
        </div>
        <div className="card-footer">
          <div className="d-grid">
            <button
              className="btn btn-primary h-45px"
              onClick={changePassword}
              disabled={waiting}
            >
              {waiting ? (
                <span>
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              ) : (
                <span className="indicator-label">Save</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
