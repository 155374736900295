import React, { useEffect, useState } from "react";

import { LIMITS, PROPERTIES } from "../../../config";
import { fetchData } from "../../../config/service";
import { Alert, Loader, Pagination } from "../../../components";
import toast from "react-hot-toast";
import { Image, Select } from "antd";

const priorityOptions = [
  {
    label: "Critical",
    value: 1,
  },
  {
    label: "High",
    value: 2,
  },
  {
    label: "Medium",
    value: 3,
  },
  {
    label: "Low",
    value: 4,
  },
];

export default function SupportTickets() {
  const [visaModal, setVisaModal] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    getData();
  }, [limit, activePage]);

  const getData = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    // Add search query if it exists
    if (debouncedSearchTerm) {
      param += `&search=${encodeURIComponent(debouncedSearchTerm || "")}`;
    }

    fetchData(`api/support/tickets/${param}`).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        setData([]);
        toast.error(res.message);
      }
    });
  };

  const deleteItem = () => {
    fetchData(
      `customer/api/item/item-game-plan-details/${selected.id}/`,
      "delete"
    ).then((res) => {
      toast.dismiss();
      if (res.success) {
        setLoading(true);
        getData();
        toast.success("Record deleted successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  const handlePriorityChange = (value, data) => {
    toast.loading("Updating priority...");
    fetchData(`api/support/tickets/${data.id}/`, "put", {
      priority: value,
    }).then((res) => {
      toast.dismiss();
      if (res.success) {
        setLoading(true);
        getData();
        toast.success("Priority updated successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <div className="row g-5 g-xxl-10">
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header">
              <h1 className="card-title">Support Tickets</h1>
              {/* <div className="d-flex flex-stack flex-wrap gap-4">
                <div className="position-relative mr-4">
                  <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
                  <input
                    type="text"
                    data-kt-table-widget-4="search"
                    className="form-control text-dark w-250px fs-7 ps-12 all-search"
                    name="all_search"
                    placeholder="Search here..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="position-relative mr-4"></div>
              </div> */}
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {loading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {data?.length > 0 ? (
                    <>
                      <div className="table-responsive tbl-sales">
                        {/*begin::Table*/}
                        <table
                          className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                          id
                        >
                          <thead>
                            <tr className="fw-bolder text-dark bg-white">
                              <th className="min-w-70px">Sr. No.</th>
                              <th className="min-w-150px">Created By</th>
                              <th className="min-w-150px">Priority</th>
                              <th className="min-w-200px">Issue</th>
                              <th className="min-w-150px">Attachments</th>
                            </tr>
                          </thead>
                          <tbody className=" fw-semibold fs-7">
                            {data?.map((item, index) => (
                              <tr className="bg-light" key={index}>
                                <td> {(activePage - 1) * limit + 1 + index}</td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                      {item?.user?.photo ? (
                                        <img src={item?.user?.photo} alt="" />
                                      ) : (
                                        <div class="symbol symbol-circle symbol-50px mr-3">
                                          <span
                                            class="symbol-label fs-2 text-primary bg-light-primary"
                                            style={{
                                              border: "1px dashed #7843e6",
                                              borderRadius: "50%",
                                            }}
                                          >
                                            {item?.user?.first_name
                                              ?.charAt(0)
                                              .toUpperCase()}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    <div className="d-flex flex-column">
                                      <a
                                        href
                                        className="text-primary mb-1 fw-bolder gotham_black"
                                      >
                                        {item.user?.first_name +
                                          " " +
                                          item.user?.last_name || "-"}
                                      </a>
                                      <span>{item?.user?.email || "-"}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <Select
                                    size="large"
                                    value={item?.priority || null}
                                    options={priorityOptions}
                                    placeholder="Select Priority"
                                    onChange={(e) => {
                                      handlePriorityChange(e, item);
                                    }}
                                    style={{ width: "100%" }}
                                    className="fs-7"
                                  ></Select>
                                </td>
                                <td>
                                  {" "}
                                  <span className="text-danger fs-6 fw-bold">
                                    {item?.issue || "-"}
                                  </span>
                                </td>
                                <td>
                                  <div>
                                    {item?.attachments?.length > 0
                                      ? item?.attachments?.map(
                                          (item, index) => (
                                            <div
                                              className="symbol symbol-square symbol-50px overflow-hidden me-3"
                                              key={index}
                                            >
                                              <Image
                                                src={item}
                                                width={50}
                                                height={50}
                                                alt="Attachment Image"
                                              />
                                            </div>
                                          )
                                        )
                                      : "No Attachments"}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {/*end::Table*/}
                      </div>

                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={setLimit}
                        limit={limit}
                        data={data}
                        activePage={activePage}
                        totalRecords={totalRecords}
                        onChange={(pageNumber) => {
                          setLoading(true);
                          setActivePage(pageNumber);
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </div>

      <Alert
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        // onSuccess={() => {
        //   getData();
        // }}
        onAction={() => {
          toast.loading("Please wait...");
          deleteItem();
        }}
      />
    </div>
  );
}
