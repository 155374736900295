import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";

import { DatePicker, Image, Select, Tooltip } from "antd";
import { fetchData } from "../../../../config/service";
import dayjs from "dayjs";
import { Loader } from "../../../../components";
import { validateFields } from "../../../../components/validation";

export default function VisaStageModal({ show, statusList, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [formData, setFormData] = useState({
    visaServiceType: "",
    status: "",
    process: "",
    visaGrantedDate: "",
  });

  const [options, setOptions] = useState({});

  useEffect(() => {
    if (data) {
      console.log(data, "data");
      setFormData({
        visaServiceType: data?.visa_service,
        status: data?.visa_status,
        process: data?.visa_process,
        visaGrantedDate: "",
      });
      getOptions();
    }
  }, [data]);

  const getOptions = () => {
    setLoading(true);
    fetchData(`customer/api/visa/visa-choices/`, "get").then((res) => {
      setLoading(false);
      if (res.success) {
        console.log(res, "res");
        setOptions(res);
      } else {
        setOptions({});
        toast.error(res.message);
      }
    });
  };

  const rules = {
    visaServiceType: {
      required: true,
    },
    status: {
      required: true,
    },
    process: {
      required: true,
    },
    // visaGrantedDate: {
    //   required: true,
    // },
  };

  const clearInput = () => {
    setFormData({
      visaServiceType: "",
      status: "",
      process: "",
      visaGrantedDate: "",
    });
  };

  const onSubmit = () => {
    const { isValid, errors } = validateFields(formData, rules);

    if (!isValid) {
      return;
    } else {
      toast.loading("Updating ....");
    }

    setSending(true);

    const body = {
      visa_service: formData?.visaServiceType,
      visa_status: formData?.status,
      visa_process: formData?.process,
      // visa_date: formData?.visaGrantedDate,
    };

    let api = `customer/api/visa/visa-information-admin-details/${data?.id}/`;

    fetchData(api, "PATCH", body, "", true).then((res) => {
      setSending(false);
      toast.remove();
      if (res.success) {
        toast.success("Success");
        onHide();
        clearInput();
      } else {
        toast.error(res.message);
      }
    });
  };

  console.log(options, "options");

  return (
    // <Modal show={show} onHide={onHide} size="lg" centered backdrop="static"> prevent hide the modal
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>Visa Stage Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <div className="row mb-5">
            <div className=" col-md-12">
              <label className="form-label font-weight-bold">
                Visa Service Type
              </label>
              <Select
                value={formData?.visaServiceType}
                onChange={(value) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    visaServiceType: value,
                  }));
                }}
                placeholder="Select Visa Status"
                style={{ width: "100%" }}
                options={options["visa_service_choices"]?.map((e) => {
                  return {
                    label: e?.display_name,
                    value: e?.value,
                  };
                })}
                allowClear
                size="large"
                dropdownStyle={{ zIndex: 1100 }}
              />
            </div>
          </div>

          <div className="row mb-5">
            <div className=" col-md-12">
              <label className="form-label font-weight-bold">Status</label>
              <Select
                value={formData?.status}
                onChange={(value) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    status: value,
                  }));
                }}
                placeholder="Select Visa Status"
                style={{ width: "100%" }}
                options={options["visa_status_Choices"]?.map((e) => {
                  return {
                    label: e?.display_name,
                    value: e?.value,
                  };
                })}
                allowClear
                size="large"
                dropdownStyle={{ zIndex: 1100 }}
              />
            </div>
          </div>

          <div className="row mb-5">
            <div className=" col-md-12">
              <label className="form-label font-weight-bold">Process</label>
              <Select
                value={formData?.process}
                onChange={(value) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    process: value,
                  }));
                }}
                placeholder="Select Visa Process"
                style={{ width: "100%" }}
                options={options["visa_process_choices"]?.map((e) => {
                  return {
                    label: e?.display_name,
                    value: e?.value,
                  };
                })}
                allowClear
                size="large"
                dropdownStyle={{ zIndex: 1100 }}
              />
            </div>
          </div>

          <div className="row">
            <div className=" col-md-12">
              <label className="form-label ">Visa Granted On</label>

              <DatePicker
                showTime={{
                  format: "HH:mm",
                }}
                format="DD-MM-YYYY HH:mm"
                value={
                  formData.visaGrantedDate
                    ? dayjs(formData.visaGrantedDate)
                    : null
                }
                onChange={(date, dateString) => {
                  const utcDateString = date
                    ? date.format("YYYY-MM-DD HH:mm")
                    : null;

                  setFormData({
                    ...formData,
                    visaGrantedDate: utcDateString ? utcDateString : null,
                  });
                }}
                className="fs-7 w-100"
                placeholder="Pick a Visa Grant Time"
                size="large"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light fs-7" onClick={onHide}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => onSubmit()} // Handle form submission here
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
}
