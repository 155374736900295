import toast from "react-hot-toast";

export function objectValidation(arrayOfObjects) {
  const errors = [];

  arrayOfObjects.forEach((obj, index) => {
    const fields = Object.keys(obj);
    const values = Object.values(obj).map((value) =>
      typeof value === "string" ? value.trim() : String(value).trim()
    );

    const isAnyFilled = values.some((value) => value !== ""); // Check if any field is filled
    const isAnyEmpty = values.some((value) => value === ""); // Check if any field is empty

    if (isAnyFilled && isAnyEmpty) {
      // If one is filled and another is empty, log an error for this object
      const missingFields = fields
        .filter((_, i) => values[i] === "")
        .join(", ");
      const errorMessage = `Please fill in all required fields. Missing: ${missingFields} in entry #${
        index + 1
      }.`;
      errors.push(errorMessage);

      // Display error using toast
      toast.error(errorMessage);
    }
  });

  return { isValid: errors.length === 0, errors };
}
