import dayjs from "dayjs";
import { support } from "jquery";

export const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const BHX_KEY =
  "9a72b8f0a563e007f4f61135101fae46ca77a8b5e728f42c019e2c33e8bab2ae";

export function formatNumber(No) {
  // Convert input to a number
  const number = Number(No);

  // If input is not a valid number, return it as-is
  if (isNaN(number)) {
    return No;
  }

  // Split into integer and decimal parts
  const [integerPart, decimalPart] = number.toString().split(".");

  // Format the integer part in the Indian numbering system
  const formattedInteger = integerPart.replace(
    /\B(?=(\d{3})(\d{2})*(?!\d))/g, // Correct 3-2-2 grouping pattern
    ","
  );

  // Combine the formatted integer and decimal parts
  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
}

export const parseISOToUTCDate = (dateString) => {
  return dateString ? dayjs.utc(dateString) : null;
};

export function suppressConsoleLogs() {
  if (process.env.NODE_ENV === "production") {
    for (let method of ["log", "warn", "info", "debug"]) {
      console[method] = () => {}; // Replace console methods with no-op
    }
  }
}

export const LIMITS = [10, 50, 100];

export const PROPERTIES = {
  1: "Hotel",
  2: "Hostel",
  3: "Guest House",
  4: "Apartment",
};

export const VISA_TYPE_OPTIONS = [
  {
    value: 1,
    label: "Offline Application",
  },
  {
    value: 2,
    label: "Not Required",
  },
  {
    value: 3,
    label: "On Arrival",
  },
  {
    value: 4,
    label: "E-Visa",
  },
];

export const routesList = {
  "/": {
    path: "/",
    name: "Dashboard",
  },
  manage_editions: {
    path: "/manage/editions",
    name: "Manage Editions",
  },
  projects: {
    path: "/manage/projects",
    name: "Projects",
  },
  handle_editions: {
    path: "/manage/editions/handle/",
    name: "Manage Editions",
  },
  handle_editions_detail: {
    path: "/manage/editions/handle/:id",
    name: "Manage Editions Detail",
  },
  manage_addons: {
    path: "/manage/add-ons",
    name: "Manage Add-ons",
  },
  manage_hotels: {
    path: "/manage/hotels",
    name: "Manage Hotels",
  },
  manage_destinations: {
    path: "/manage/destinations",
    name: "Manage Destinations",
  },
  manage_experiences: {
    path: "/manage/experiences",
    name: "Manage Experiences",
  },
  customers: {
    path: "/customers",
    name: "Users",
  },
  customer_details: {
    path: "/customers/details/:id",
    name: "User Details",
  },
  curation_central: {
    path: "/customers/curation-central/:id",
    name: "Curation Central",
  },
  payments: {
    path: "/bookings/payments",
    name: "Payments",
  },
  bookings: {
    path: "/bookings/bookings-page/:id",
    name: "Bookings",
  },
  bookings_detail: {
    path: "/bookings/bookings-detail-page/:id",
    name: "Bookings Detail",
  },
  call_requests: {
    path: "/bookings/call-requests",
    name: "Call Requests",
  },
  onboarding_calls: {
    path: "/bookings/onboarding-calls",
    name: "Onboarding Calls",
  },
  change_edition: {
    path: "/bookings/change-edition",
    name: "Edition Changes",
  },
  visa_center: {
    path: "/bookings/visa-center",
    name: "Visa Center",
  },
  room_manager: {
    path: "/bookings/room-manager",
    name: "Room Manager",
  },
  ad_metrics: {
    path: "/settings/ad-metrics",
    name: "Ad Metrics",
  },
  business_metrics: {
    path: "/settings/business-metrics",
    name: "Business Metrics",
  },
  cancellation_policy: {
    path: "/settings/cancellation-policy",
    name: "Cancellation Policy",
  },
  faqs: {
    path: "/settings/faqs",
    name: "FAQs",
  },
  refund_policy: {
    path: "/settings/refund-policy",
    name: "Refund Policy",
  },
  change_password: {
    path: "/settings/change-password",
    name: "Change Password",
  },
  manage_employee: {
    path: "/settings/employee",
    name: "Manage Employee",
  },
  project_settings: {
    path: "/settings/project-settings",
    name: "Project Settings",
  },
  feedback: {
    path: "/settings/feedback",
    name: "Feedback",
  },
  visa_guideline: {
    path: "/settings/visa_guideline",
    name: "Visa Guideline",
  },
  support_tickets: {
    path: "/settings/support_tickets",
    name: "Support Tickets",
  },
  settings: {
    path: "/settings",
    name: "Settings",
  },
  tribe: {
    path: "/tribe",
    name: "Tribe",
  },
  my_profile: {
    path: "/my-profile",
    name: "My Profile",
  },
  login: {
    path: "/login",
    name: "Login",
  },
  register: {
    path: "/register",
    name: "Register",
  },
  forgot_password: {
    path: "/forgot-password",
    name: "Forgot Password",
  },
};
