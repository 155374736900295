import React, { useEffect, useState } from "react";
import { fetchData } from "../../../../config/service";
import toast from "react-hot-toast";
import moment from "moment";

const Curation = ({ id }) => {
  const [curationNote, setCurationNote] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCurationNote();
  }, []);

  const getCurationNote = () => {
    fetchData(`api/curation_note/${id}`, "get").then((res) => {
      setLoading(false);
      if (res.success) {
        setCurationNote(res);
      } else {
        setCurationNote([]);
        toast.error(res.message);
      }
    });
  };

  const formatDate = (date) => {
    return date ? moment(date).format("DD-MM-YYYY h:mm a") : "N/A";
  };

  return (
    <div
      className="card card-flush mb-6 mb-xl-9"
      style={
        loading
          ? {
              filter: "blur(5px)",
              pointerEvents: "none",
            }
          : {}
      }
    >
      <div className="card-header">
        <div className="card-title flex-column">
          <h2 className="mb-1">Curation</h2>
        </div>

        <div className="card-toolbar"></div>
      </div>

      <div className="card-body p-9 pt-2">
        <div className="mb-10">
          <div className="mb-2 fs-6">
            <span className="fw-bold">Applied On:</span>{" "}
            {curationNote?.curation_details?.applied_on
              ? formatDate(curationNote.curation_details.applied_on)
              : "N/A"}
          </div>
          <div className="mb-2 fs-6">
            <span className="fw-bold">Curated On:</span>{" "}
            {curationNote?.curation_details?.curated_on
              ? formatDate(curationNote.curation_details.curated_on)
              : "N/A"}
          </div>
        </div>
        <h4>Curation Note</h4>
        <div>
          <>
            {curationNote?.curation_data?.map((option) => (
              <div
                className="col-12 my-5"
                key={option.curation_question_answer_id}
              >
                <label>
                  <span className="fw-semibold fs-5">{option.question}</span>
                </label>

                <ul>
                  {option?.selected_answers?.map((answer, index) => (
                    <li className="fw-semibold text-gray-600 mt-1" key={index}>
                      {answer}
                    </li>
                  )) || <li>No answers selected</li>}
                </ul>
              </div>
            ))}
          </>
        </div>
      </div>
    </div>
  );
};

export default Curation;
