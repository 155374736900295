import { DatePicker, TimePicker } from "antd";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const StatusMarker = ({ show, feedbackQlist, feedbacklist, onHide, data }) => {
  const [formState, setFormState] = useState({
    callRequestSentOn: "",
    callAt: "",
    followupOn: "",
    followupedOn: "",
    paymentLinkSentOn: "",
    paidOn: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };
  const handleDateChange = (name, date) => {
    setFormState({
      ...formState,
      [name]: date ? date.format("YYYY-MM-DD") : null, // Format the date to string
    });
  };

  const handleTimeChange = (name, time) => {
    setFormState({
      ...formState,
      [name]: time ? time.format("HH:mm:ss") : null, // Format the time to string
    });
  };

  const handleSave = () => {
    // Handle what to do with the form data when Save is clicked

    onHide();
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="scroll-y pe-7 ps-3">
            <div className="row g-5 align-items-center">
              <div className="col-md-6">
                <label className="fs-6 fw-bold mb-2" l>
                  Call Request Sent On:
                </label>
                <DatePicker
                  className="form-control"
                  onChange={(date) =>
                    handleDateChange("callRequestSentOn", date)
                  }
                  format="DD-MM-YYYY"
                />
              </div>
              <div className="col-md-6">
                <label className="fs-6 fw-bold mb-2">Call At (Time):</label>
                <TimePicker
                  className="form-control"
                  onChange={(time) => handleTimeChange("callAt", time)}
                  format="HH:mm:ss"
                />
              </div>

              <div className="col-md-6">
                <label className="fs-6 fw-bold mb-2">Followup On (Date):</label>
                <DatePicker
                  className="form-control"
                  onChange={(date) => handleDateChange("followupOn", date)}
                  format="DD-MM-YYYY"
                />
              </div>
              <div className="col-md-6">
                <label className="fs-6 fw-bold mb-2">
                  Followuped On (Time):
                </label>
                <TimePicker
                  className="form-control"
                  onChange={(time) => handleTimeChange("followupedOn", time)}
                  format="HH:mm:ss"
                />
              </div>

              <div className="col-md-6">
                <label className="fs-6 fw-bold mb-2">
                  Payment Link Sent On:
                </label>
                <DatePicker
                  className="form-control"
                  onChange={(date) =>
                    handleDateChange("paymentLinkSentOn", date)
                  }
                  format="DD-MM-YYYY"
                />
              </div>
              <div className="col-md-6">
                <label className="fs-6 fw-bold mb-2">Paid On (Time):</label>
                <TimePicker
                  className="form-control"
                  onChange={(time) => handleTimeChange("paidOn", time)}
                  format="HH:mm:ss"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StatusMarker;
