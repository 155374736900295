import moment from "moment";
import React, { useEffect, useState } from "react";

import DateRangePicker from "react-bootstrap-daterangepicker";
import Table from "./Table";
import { LIMITS, routesList } from "../../../config";
import { Link } from "react-router-dom";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import { Loader, Pagination } from "../../../components";
import { Select } from "antd";
import BarChart from "./chart/edition-chart";

const dummyData = [
  {
    id: 1,
    name: "John Doe",
    start_date: "2023-01-10T08:00:00",
    hero_image: null,
    vibe_score: 80,
    sold_count: 45,
    conv: 20,
    payment_count: 12,
    preference_count: 50,
    cost: 5000,
    advance_percentage: 20,
    max_spots: 100,
    progress_value: 70, // Progress value
    full_payments_pending: 5, // Full Payments Pending Count
    preferences_pending: 3, // Preferences Pending Count
    onboarding_pending: 2, // Onboarding Pending Count
    visa_pending: 1, // Visa Pending Count
    flight_pending: 4, // Flight Pending Count
    open_loops: 10, // Open Loops
    t_60: 6, // T-60
  },
  {
    id: 2,
    name: "Jane Smith",
    start_date: "2023-01-15T10:00:00",
    hero_image: null,
    vibe_score: 90,
    sold_count: 60,
    conv: 25,
    payment_count: 15,
    preference_count: 70,
    cost: 8000,
    advance_percentage: 30,
    max_spots: 120,
    progress_value: 50, // Progress value
    full_payments_pending: 2,
    preferences_pending: 7,
    onboarding_pending: 4,
    visa_pending: 3,
    flight_pending: 1,
    open_loops: 8, // Open Loops
    t_60: 3, // T-60
  },
  {
    id: 3,
    name: "Mike Johnson",
    start_date: "2023-02-01T12:00:00",
    hero_image: null,
    vibe_score: 85,
    sold_count: 30,
    conv: 10,
    payment_count: 8,
    preference_count: 40,
    cost: 4000,
    advance_percentage: 10,
    max_spots: 80,
    progress_value: 85, // Progress value
    full_payments_pending: 3,
    preferences_pending: 5,
    onboarding_pending: 1,
    visa_pending: 0,
    flight_pending: 2,
    open_loops: 5, // Open Loops
    t_60: 4, // T-60
  },
];

export default function ManageEditions() {
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);

  const [editionsStatusOptions, setEditionsStatusOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterData, setFilterData] = useState({
    project: null,
    status: null,
  });
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    getChartData();
    getEditionsStatus();
    getProjectOptions();
  }, []);

  useEffect(() => {
    getData();
  }, [limit, activePage, filterData, debouncedSearchTerm]);

  const getChartData = () => {
    setChartLoading(true);
    fetchData(`api/editions/edition-dashboard-data/`).then((res) => {
      setChartLoading(false);
      if (res.success) {
        setChartData(res);
      } else {
        setChartData([]);
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    setLoading(true);
    fetchData(
      `api/editions/?limit=${limit}&offset=${
        (activePage - 1) * limit
      }&search=${encodeURIComponent(debouncedSearchTerm || "")}&status=${
        filterData?.status || ""
      }&project=${filterData?.project || ""}`
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        setData([]);
        toast.error(res.message);
      }
    });
  };

  const getEditionsStatus = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    fetchData(`api/editions/editions-choices/`).then((res) => {
      if (res.success) {
        const data = res["edition_status_choices"]?.map((e) => {
          return {
            label: e?.display_name,
            value: e?.value,
          };
        });
        setEditionsStatusOptions(data);
      } else {
        setEditionsStatusOptions([]);
        toast.error(res.message);
      }
    });
  };

  const getProjectOptions = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${100}&offset=${offset}`;

    fetchData(`api/project/${param}`).then((res) => {
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });
        setProjectOptions(data);
      } else {
        setProjectOptions([]);
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <div className="row g-5 gx-xl-10 mb-5 mb-xl-10">
        {/*begin::Col*/}
        <div className="col-xl-2 ">
          {/*begin::Card widget 3*/}
          <div
            className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100"
            style={{
              backgroundColor: "#F1416C",
              backgroundImage: 'url("/media/wave-bg-light.svg")',
            }}
          >
            {/*begin::Header*/}
            <div className="card-header pt-5 mb-3  justify-content-center">
              {/*begin::Icon*/}
              <div
                className="d-flex flex-center rounded-circle h-80px w-80px"
                style={{
                  border: "1px dashed rgba(255, 255, 255, 0.4)",
                  backgroundColor: "#F1416C",
                }}
              >
                <i className="ki-outline ki-call text-white fs-2qx lh-0" />
              </div>
              {/*end::Icon*/}
            </div>
            {/*end::Header*/}
            {/*begin::Card body*/}
            <div className="card-body d-flex align-items-end mb-3 px-2 justify-content-center">
              {/*begin::Info*/}
              <div className=" text-center">
                <span className="fs-2hx text-white fw-bold me-0 text-end">
                  {chartData?.total_edition_count || 0}
                </span>
                <div className="fw-bold fs-6 text-white">
                  <span className="d-block">Total No. Of Edition</span>
                </div>
              </div>
              {/*end::Info*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card widget 3*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-xl-2 ">
          {/*begin::Card widget 3*/}
          <div
            className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100"
            style={{
              backgroundColor: "#7843e6",
              backgroundImage: 'url("/media/wave-bg-light.svg")',
            }}
          >
            {/*begin::Header*/}
            <div className="card-header pt-5 mb-3  justify-content-center">
              {/*begin::Icon*/}
              <div
                className="d-flex flex-center rounded-circle h-80px w-80px"
                style={{
                  border: "1px dashed rgba(255, 255, 255, 0.4)",
                  backgroundColor: "#7843e6",
                }}
              >
                <i className="ki-outline ki-tech-wifi text-white fs-2qx lh-0" />
              </div>
              {/*end::Icon*/}
            </div>
            {/*end::Header*/}
            {/*begin::Card body*/}
            <div className="card-body d-flex align-items-end mb-3 px-2 justify-content-center">
              {/*begin::Info*/}
              <div className=" text-center">
                <span className="fs-2hx text-white fw-bold me-0 text-end">
                  {chartData?.total_live_edition_count || 0}
                </span>
                <div className="fw-bold fs-6 text-white">
                  <span className="d-block">Live Edition</span>
                </div>
              </div>
              {/*end::Info*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card widget 3*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-xl-2 ">
          {/*begin::Card widget 3*/}
          <div
            className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100"
            style={{
              backgroundColor: "#72ad32",
              backgroundImage: 'url("/media/wave-bg-light.svg")',
            }}
          >
            {/*begin::Header*/}
            <div className="card-header pt-5 mb-3  justify-content-center">
              {/*begin::Icon*/}
              <div
                className="d-flex flex-center rounded-circle h-80px w-80px"
                style={{
                  border: "1px dashed rgba(255, 255, 255, 0.4)",
                  backgroundColor: "#72ad32",
                }}
              >
                <i className="ki-outline ki-watch text-white fs-2qx lh-0" />
              </div>
              {/*end::Icon*/}
            </div>
            {/*end::Header*/}
            {/*begin::Card body*/}
            <div className="card-body d-flex align-items-end mb-3 px-2 justify-content-center">
              {/*begin::Info*/}
              <div className=" text-center">
                <span className="fs-2hx text-white fw-bold me-0 text-end">
                  {chartData?.total_upcoming_edition_count || 0}
                </span>
                <div className="fw-bold fs-6 text-white">
                  <span className="d-block">Upcoming Edition</span>
                </div>
              </div>
              {/*end::Info*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card widget 3*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-xl-6">
          {/*begin::Chart widget 36*/}
          <div className="card card-flush overflow-hidden h-lg-100">
            {/*begin::Header*/}
            <div className="card-header pt-4  min-h-30px">
              <h3 className="card-title mb-0 align-items-start flex-column">
                <span className="card-label fw-bold text-gray-900">
                  Top 5 Edition
                </span>
              </h3>
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Card body*/}
            <div className="card-body  py-0 align-items-end p-0 ps-5 pe-10">
              <div>
                {chartLoading ? (
                  <div className="h-100px mt-5">
                    <Loader cols={1} />
                  </div>
                ) : Object.keys(chartData).length > 0 &&
                  chartData["top_5_editions_by_bookings"].length > 0 ? (
                  <BarChart data={chartData} />
                ) : (
                  <div className="row g-5 mb-0 gx-md-10">
                    <div className="col-md-12 text-center">
                      <div className="mt-0">
                        <img
                          src="/media/no-data-found.png"
                          className="mw-25"
                          alt="No Data Found"
                        />
                      </div>
                      <h1 className="mt-4">No Data Found</h1>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Chart widget 36*/}
        </div>
        {/*end::Col*/}
      </div>
      {/*begin::Row*/}
      <div className="row g-5 g-xxl-10">
        <div className="col-xxl-12 ">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header">
              <div className="d-flex flex-stack flex-wrap gap-4">
                <div className="position-relative mr-4">
                  <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
                  <input
                    type="text"
                    data-kt-table-widget-4="search"
                    className="form-control text-dark w-250px fs-7 ps-12 all-search"
                    name="all_search"
                    placeholder="Search edition here..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="position-relative mr-4"></div>
              </div>
              <div className="card-toolbar">
                <div className="position-relative me-3 ">
                  <Select
                    showSearch
                    name="project"
                    value={filterData?.project}
                    onChange={(value) => {
                      setFilterData((prevState) => ({
                        ...prevState,
                        project: value,
                      }));
                    }}
                    placeholder="Select Project"
                    className="fs-7 w-150px"
                    style={{ width: "100%" }}
                    dropdownStyle={{ zIndex: 1100 }}
                    size="large"
                    options={projectOptions}
                    allowClear
                    onClear={() =>
                      setFilterData((prevState) => ({
                        ...prevState,
                        project: null,
                      }))
                    }
                  ></Select>
                </div>
                <Select
                  showSearch
                  name="status"
                  value={filterData?.status}
                  onChange={(value) => {
                    setFilterData((prevState) => ({
                      ...prevState,
                      status: value,
                    }));
                  }}
                  placeholder="Select Status"
                  className="fs-8 me-3 w-150px"
                  dropdownStyle={{ zIndex: 1100 }}
                  size="large"
                  options={editionsStatusOptions}
                  allowClear
                  onClear={() =>
                    setFilterData((prevState) => ({
                      ...prevState,
                      status: null,
                    }))
                  }
                ></Select>
                {/* <a class="btn btn-primary fs-7 fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_add_edition"><i class="ki-outline ki-plus-square fs-2"></i>Add Edition</a> */}
                <Link
                  to={routesList["handle_editions"].path}
                  className="btn btn-primary fs-7 fw-bold"
                >
                  <i className="ki-outline ki-plus-square fs-2" /> Add Edition
                </Link>
                {/* <a href="#" class="btn  fs-7 btn-primary fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Add New User</a> */}
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {loading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {data?.length > 0 ? (
                    <>
                      {/*begin::Table container*/}
                      <Table data={data} />

                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={setLimit}
                        limit={limit}
                        data={data}
                        activePage={activePage}
                        totalRecords={totalRecords}
                        onChange={(pageNumber) => {
                          setLoading(true);
                          setActivePage(pageNumber);
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </div>
      {/*end::Row*/}
    </div>
  );
}
