import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Select } from "antd";

export default function UpdateCallStatus({ show, statusList, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  // State object for form fields
  const [formFields, setFormFields] = useState({
    inviteExtendedTill: "",
    callRequestSent: "",
    callRequestReminderSent: "",
    callDoneOn: "",
    callFeeler: "",
    callFollowupOn: "",
    followupNotes: "",
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  useEffect(() => {
    if (data) {
      console.log(data, "user_data");
      setUserId(data?.uuid);
    }
  }, [data]);

  const onSubmitUser = async () => {
    setSending(true);

    const body = {
      ...formFields,
    };

    // fetchData(`api/booking/${userId}/`, "PATCH", body).then((res) => {
    //   setSending(false);
    //   if (res.success) {
    //     onHide();
    //     toast.success("Success");
    //   } else {
    //     toast.error(res.message);
    //   }
    // });
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Change Status <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-3 align-items-center">
          {/* Invite Extended Till */}
          <div className="col-md-6">
            <label className="form-label fw-bold fs-7">
              Invite Extended Till
            </label>
            <input
              type="date"
              className="form-control"
              name="inviteExtendedTill"
              value={formFields.inviteExtendedTill}
              onChange={handleChange}
            />
          </div>

          {/* Call Request Sent */}
          <div className="col-md-6">
            <label className="form-label fw-bold fs-7">Call Request Sent</label>
            <input
              type="date"
              className="form-control"
              name="callRequestSent"
              value={formFields.callRequestSent}
              onChange={handleChange}
            />
          </div>

          {/* Call Request Reminder Sent */}
          <div className="col-md-6">
            <label className="form-label fw-bold fs-7">
              Call Request Reminder Sent
            </label>
            <input
              type="date"
              className="form-control"
              name="callRequestReminderSent"
              value={formFields.callRequestReminderSent}
              onChange={handleChange}
            />
          </div>

          {/* Call Done On */}
          <div className="col-md-6">
            <label className="form-label fw-bold fs-7">Call Done On</label>
            <input
              type="date"
              className="form-control"
              name="callDoneOn"
              value={formFields.callDoneOn}
              onChange={handleChange}
            />
          </div>

          {/* Call Feeler */}
          <div className="col-md-6">
            <label className="form-label fw-bold fs-7">Call Feeler</label>
            <input
              type="text"
              className="form-control"
              name="callFeeler"
              value={formFields.callFeeler}
              onChange={handleChange}
            />
          </div>

          {/* Call Followup On */}
          <div className="col-md-6">
            <label className="form-label fw-bold fs-7">Call Followup On</label>
            <input
              type="date"
              className="form-control"
              name="callFollowupOn"
              value={formFields.callFollowupOn}
              onChange={handleChange}
            />
          </div>

          {/* Followup Notes */}
          <div className="col-12">
            <label className="form-label fw-bold fs-7">Followup Notes</label>
            <textarea
              className="form-control"
              rows="3"
              name="followupNotes"
              value={formFields.followupNotes}
              onChange={handleChange}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitUser();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
