import React, { useEffect, useState } from "react";
import { ManageEmployeeModal } from "./modals";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Loader, Pagination } from "../../../components";
import { LIMITS } from "../../../config";
import moment from "moment";

export default function ManageEmployee() {
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [user_data, set_user_data] = useState({});

  const [userRoles, setUserRoles] = useState([]);
  const [data, setData] = useState([]);

  const handleStatusChange = (id, currentStatus) => {
    const body = { is_active: !currentStatus };

    fetchData(`api/accounts/${id}/`, "put", body).then((res) => {
      toast.remove();
      if (res.success) {
        toast.success("Status updated successfully");
        getData();
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleStaffStatusChange = (id, currentStatus) => {
    const body = { is_staff: !currentStatus };

    fetchData(`api/accounts/${id}/`, "put", body).then((res) => {
      toast.remove();
      if (res.success) {
        toast.success("Staff updated successfully");
        getData();
      } else {
        toast.error(res.message);
      }
    });
  };

  useEffect(() => {
    if (user?.uuid) {
      getData();
    }
  }, [user?.uuid, limit, activePage]);

  useEffect(() => {
    getRoles();
  }, []);

  const getData = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    fetchData(`api/accounts/${param}`, "get").then((res) => {
      setLoading(false);
      if (res.success) {
        // console.log(res, "res");

        // Get the user ID from the state
        const userId = user?.uuid;

        // Filter out the user object with the matching ID
        const filteredData = res?.data?.filter((item) => item?.uuid !== userId);

        setData(filteredData);
        setTotalRecords(res.count - 1);
      } else {
        setData([]);
        toast.error(res.message);
      }
    });
  };

  const getRoles = () => {
    fetchData(`api/accounts/user-choices/`, "get").then((res) => {
      if (res.success) {
        setUserRoles(res["user_type_choices"]);
      } else {
        setUserRoles([]);
        toast.error(res.message);
      }
    });
  };

  return (
    <>
      <div className="row gy-5 g-xl-5">
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header ">
              <div className="card-toolbar gap-2">
                {/* <div>
                  <button
                    className="btn fs-8 btn-primary fw-bold"
                    onClick={() => {
                      setIsOpenUserModal(true);
                    }}
                  >
                    Add New
                  </button>
                </div> */}
              </div>
            </div>

            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {loading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {data?.length > 0 ? (
                    <>
                      <div className="main-box clearfix">
                        <div className="table-responsive">
                          <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                            <thead>
                              <tr className="fw-bolder text-dark bg-white">
                                <th className="min-w-75px text-center">#</th>
                                <th className="min-w-180px">Name</th>
                                <th className="min-w-125px">Mobile No</th>
                                <th className="min-w-100px">Role</th>
                                <th className="min-w-175px">Updated at</th>
                                <th className="min-w-90px">Is Staff?</th>

                                {/* <th className="min-w-90px">Status</th> */}
                                <th className="min-w-105px text-center">
                                  Action
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {data?.length > 0
                                ? data?.map((d, i) => {
                                    return (
                                      <tr className="bg-light" key={i}>
                                        <td className="bdr-left-user text-center">
                                          {(activePage - 1) * limit + 1 + i}
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                              {d?.photo ? (
                                                <img src={d?.photo} alt="" />
                                              ) : (
                                                <div class="symbol symbol-circle symbol-50px mr-3">
                                                  <span
                                                    class="symbol-label fs-2 text-primary bg-light-primary"
                                                    style={{
                                                      border:
                                                        "1px dashed #7843e6",
                                                      borderRadius: "50%",
                                                    }}
                                                  >
                                                    {d?.first_name
                                                      ?.charAt(0)
                                                      .toUpperCase()}
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                            <div className="d-flex flex-column">
                                              <a
                                                href
                                                className="text-primary mb-1 fw-bolder gotham_black"
                                              >
                                                {d.first_name +
                                                  " " +
                                                  d.last_name || "-"}
                                              </a>
                                              <span>{d?.email || "-"}</span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>{d?.phone || "-"}</td>

                                        <td>
                                          <span
                                            className={`badge ${
                                              d?.user_type == 1
                                                ? "badge-light-success"
                                                : d?.user_type == 2
                                                ? "badge-light-primary"
                                                : d?.user_type == 3
                                                ? "badge-light-warning"
                                                : d?.user_type == 4
                                                ? "badge-light-info"
                                                : d?.user_type == 5
                                                ? "badge-light-primary"
                                                : d?.user_type == 6
                                                ? "badge-light-dark"
                                                : d?.user_type == 7
                                                ? "badge-light-info"
                                                : "-"
                                            }`}
                                          >
                                            {d?.user_type == 1
                                              ? "Admin"
                                              : d?.user_type == 2
                                              ? "Ads"
                                              : d?.user_type == 3
                                              ? "Manager"
                                              : d?.user_type == 4
                                              ? "Customer"
                                              : d?.user_type == 5
                                              ? "Super Admin"
                                              : d?.user_type == 6
                                              ? "Business"
                                              : d?.user_type == 7
                                              ? "Lead Facilitator"
                                              : "-"}
                                          </span>{" "}
                                        </td>

                                        <td>
                                          {moment(
                                            new Date(d?.last_modified)
                                          ).format("DD-MM-YYYY h:mm a")}
                                        </td>

                                        <td>
                                          <div className="form-check form-switch ">
                                            <input
                                              className="form-check-input cursor-pointer"
                                              type="checkbox"
                                              role="switch"
                                              id={`status-switch-${d.uuid}`}
                                              checked={d?.is_staff}
                                              onChange={() => {
                                                toast.loading(
                                                  "Staff Updating..."
                                                );
                                                handleStaffStatusChange(
                                                  d?.uuid,
                                                  d?.is_staff
                                                );
                                              }}
                                            />
                                          </div>
                                        </td>

                                        {/* <td>
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input cursor-pointer"
                                        type="checkbox"
                                        role="switch"
                                        id={`status-switch-${d.uuid}`}
                                        checked={d?.is_active}
                                        onChange={() => {
                                          toast.loading("Status Updating...");
                                          handleStatusChange(
                                            d.uuid,
                                            d?.is_active
                                          );
                                        }}
                                      />
                                    </div>
                                  </td> */}

                                        <td>
                                          <div className="d-flex flex-shrink-0 justify-content-center">
                                            <button
                                              className="btn btn-icon bg-light btn-active-color-primary btn-sm me-2"
                                              onClick={() => {
                                                set_user_data(d);
                                                setIsOpenUserModal(true);
                                              }}
                                            >
                                              <i className="ki-outline ki-pencil fs-2 text-info" />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })
                                : "No Data Found"}
                            </tbody>
                          </table>
                        </div>

                        <div className="separator separator-dashed border-gray-200" />
                        <Pagination
                          setLimit={setLimit}
                          limit={limit}
                          data={data}
                          activePage={activePage}
                          totalRecords={totalRecords}
                          onChange={(pageNumber) => {
                            setLoading(true);
                            setActivePage(pageNumber);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
          </div>
        </div>
      </div>

      {isOpenUserModal && (
        <ManageEmployeeModal
          show={isOpenUserModal}
          onHide={() => {
            set_user_data({});
            setIsOpenUserModal(false);
            setLoading(true);
            getData();
          }}
          data={user_data}
          userRoles={userRoles}
        />
      )}
    </>
  );
}
