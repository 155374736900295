import React from "react";

const EditionSuccess = () => {
  const tab1Data = [
    {
      description: "Coming for Sure",
      count: 0,
      countClass: "text-success",
      action: "Move them Out",
    },
    {
      description: "Coming / Will Pay",
      count: 0,
      countClass: "text-success",
      action: "Move them Out",
    },
    {
      description: "Coming / Needs Time",
      count: 0,
      countClass: "text-success",
      action: "Move them Out",
    },
    {
      description: "Payment Extension Requested",
      count: 0,
      countClass: "text-success",
      action: "Move them Out",
    },
    {
      description: "Newly Moved from Previous Editions",
      count: 0,
      countClass: "text-success",
      action: "Move them Out",
    },
    {
      description: "Paid / No Response",
      count: 0,
      countClass: "text-warning",
      action: "Move them Out",
    },
    {
      description: "Not Sure",
      count: 0,
      countClass: "text-warning",
      action: "Move them Out",
    },
    {
      description: "No Response",
      count: 0,
      countClass: "text-warning",
      action: "Move them Out",
    },
    {
      description: "Move to other Edition",
      count: 1,
      countClass: "text-gray-800",
      action: "Move them Out",
    },
    {
      description: "Not Coming",
      count: 0,
      countClass: "text-gray-800",
      action: "Move them Out",
    },
  ];
  return (
    <div>
      <table className="table align-middle table-row-dashed table-row-gray-300 gy-4 gs-4">
        <tbody>
          {tab1Data.map((row, index) => (
            <tr key={index}>
              <td>{row.description}</td>
              <td className="min-w-100px">
                <span className={`fw-bolder ${row.countClass}`}>
                  {row.count}
                </span>
              </td>
              <td>{`Possible Action: ${row.action}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EditionSuccess;
