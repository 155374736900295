import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LIMITS, routesList } from "../../../config";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import { Alert, Loader, Pagination } from "../../../components";
import { Select } from "antd";

export default function CallRequests() {
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [editionsList, setEditionsList] = useState([]);

  const [filterData, setFilterData] = useState({
    score: null,
    edition: null,
  });

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    getEditions();
    getStatus();
  }, []);

  useEffect(() => {
    getData();

    // window.addEventListener("scroll", handleScroll);

    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, [limit, activePage, debouncedSearchTerm, filterData]);

  const getStatus = () => {
    fetchData(
      `api/accounts/user-choices/?type=user_status_choices`,
      "get"
    ).then((res) => {
      if (res.success) {
        setStatusList(res["user_status_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getEditions = () => {
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    setLoading(true);

    fetchData(
      `api/call/?limit=${limit}&offset=${
        (activePage - 1) * limit
      }&search=${encodeURIComponent(debouncedSearchTerm || "")}&user__score=${
        filterData?.score || ""
      }&edition=${filterData?.edition || ""}`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        toast.error(res.message);
      }
    });
  };

  const setFilterDataValue = (key, value) => {
    const common_data = {
      ...filterData,
      [key]: value,
    };
    setFilterData(common_data);
  };

  const deleteItem = () => {
    fetchData(`api/call/${selected.id}`, "delete").then((res) => {
      toast.dismiss();
      if (res.success) {
        setLoading(true);
        getData();
        toast.success("Record deleted successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  const scoreOptions = [
    // { value: 0, name: "0" },
    { value: 1, name: "1" },
    { value: 2, name: "2" },
    { value: 3, name: "3" },
    { value: 3.5, name: "3.5" },
    { value: 4, name: "4" },
    { value: 4.5, name: "4.5" },
    { value: 5, name: "5" },
  ];
  return (
    <div>
      <div className="row g-5 g-xxl-5">
        <div className="col-xxl-12">
          <p className="mb-1">
            Step 1: <b>Click on Whatsapp Icon</b>
          </p>
          <p className="mb-1">
            Step 2: <b>Paste the template message on Whatsapp</b>
          </p>
          <p className="mb-0">
            Step 3: <b>Come back here and press tick icon</b>
          </p>
          {/* <p><b>Click on Whatsapp Icon</b></p> */}
        </div>
        <div className="col-xxl-12">
          <div className="d-flex flex-wrap gap-4">
            {/* <div class="position-relative ">
                                          <i
                                              class="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5"></i>
                                          <input type="text" data-kt-table-widget-4="search"
                                              class="form-control text-dark w-250px fs-7 ps-12 all-search"
                                              name="all_search" placeholder="Search Hotel here...">
                                      </div> */}
            <div className="position-relative ">
              <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
              <input
                type="text"
                data-kt-table-widget-4="search"
                className="form-control text-dark w-250px fs-7 ps-12 all-search"
                name="all_search"
                placeholder="Search customer here..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="position-relative ">
              <Select
                value={filterData?.score}
                showSearch
                optionFilterProp="label"
                onChange={(value) => setFilterDataValue("score", value)}
                placeholder="Select Score"
                style={{ width: "200px" }}
                options={scoreOptions.map((h) => ({
                  label: h.name,
                  value: h.value,
                }))}
                allowClear
                onClear={() => {
                  setFilterDataValue("score", null);
                }}
                size="large"
              />
            </div>
            <div className="position-relative ">
              <Select
                value={filterData?.edition}
                showSearch
                optionFilterProp="label"
                onChange={(value) => setFilterDataValue("edition", value)}
                placeholder="Select Edition"
                style={{ width: "200px" }}
                options={editionsList}
                allowClear
                onClear={() => {
                  setFilterDataValue("edition", null);
                }}
                size="large"
              />
            </div>
            {/* <div className="position-relative ">
              <select
                className="form-select fs-7 min-w-150px"
                data-control="select2"
                data-placeholder="Select Category"
              >
                <option />
                <option value={1}>BHX</option>
                <option value={1}>20k - 30k</option>
                <option value={1}>30k - 40k</option>
                <option value={2}>50k - 60k</option>
              </select>
            </div> */}
          </div>
        </div>

        {loading ? (
          <div className="table-responsive">
            <Loader cols={1} width={550} />
          </div>
        ) : (
          <>
            {data?.length > 0 ? (
              data.map((user) => (
                <div className="col-md-6" key={user.id}>
                  <div className="card">
                    <div className="card-body p-5 d-flex flex-stack">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-circle symbol-70px overflow-hidden me-4">
                          {user.user.photo ? (
                            <img src={user.user.photo} alt="" />
                          ) : (
                            <span className="symbol-label fs-2x text-primary">
                              {user.user.first_name.charAt(0)}
                            </span>
                          )}
                        </div>
                        {/* User details */}
                        <div className="d-flex flex-column">
                          <a
                            href="#"
                            className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-5 min-w-200px"
                          >
                            {`${user.user.first_name} ${user.user.last_name}`}
                          </a>
                          <div className="text-gray-600">
                            Number:{" "}
                            <a href="#" className="fw-bold">
                              {user.user.phone}
                            </a>
                          </div>
                          <div className="text-gray-600 fs-8">
                            Attended By:{" "}
                            {user.attended_by
                              ? `${user.attended_by.first_name} ${user.attended_by.last_name}`
                              : "Not assigned"}
                          </div>
                          <div className="text-gray-600 fs-8">
                            Edition: {user.edition_name}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="text-end mb-4">
                          <a
                            href="#"
                            className="btn btn-icon box-shadow-lg border bg-light btn-active-color-primary btn-sm me-3"
                          >
                            <i className="ki-outline ki-eye fs-2 text-gray-800" />
                          </a>
                          <a
                            href="#"
                            className="btn btn-icon box-shadow-lg border bg-light btn-active-color-primary btn-sm"
                          >
                            <i className="ki-outline ki-information-2 fs-2 text-gray-800" />
                          </a>
                        </div>
                        <div className="d-flex flex-shrink-0 justify-content-end">
                          <div>
                            {user?.user.phone ? (
                              <a
                                href={`https://wa.me/${user.user.phone}`}
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="bi bi-whatsapp fs-2 text-success" />
                              </a>
                            ) : (
                              <div
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                disabled
                                style={{
                                  cursor: "not-allowed",
                                  opacity: "0.5",
                                }}
                              >
                                <i className="bi bi-whatsapp fs-2 text-success" />
                              </div>
                            )}
                          </div>

                          <a
                            href="#"
                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                          >
                            <i className="bi bi-envelope fs-2 text-danger" />
                          </a>
                          <a
                            href="#"
                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                          >
                            <i className="ki-outline ki-check-circle fs-2 text-success" />
                          </a>
                          <div
                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                            onClick={() => {
                              setShowDeleteModal(true);
                              setSelected(user);
                            }}
                          >
                            <i className="ki-outline ki-trash fs-2 text-danger" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="row g-5 mb-0 gx-md-10">
                <div className="col-md-12 text-center">
                  <div className="mt-0">
                    <img
                      src="/media/no-data-found.png"
                      className="mw-25"
                      alt="No Data Found"
                    />
                  </div>
                  <h1 className="mt-4">No Data Found</h1>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <Alert
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        // onSuccess={() => {
        //   getData();
        // }}
        onAction={() => {
          toast.loading("Please wait...");
          deleteItem();
        }}
      />
    </div>
  );
}
