import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Select } from "antd";

export default function UserRoleModal({ show, notesOptions, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [loadings, setLoadings] = useState({
    ladderLoading: true,
    npsLoading: true,
    tagLoading: true,
  });
  const [ladderOptions, setLadderOptions] = useState([]);
  const [npsOptions, setNpsOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);

  const [userRoleData, setUserRoleData] = useState({
    role: null,
    tags: null,
    ladder: null,
    nps: null,
  });

  useEffect(() => {
    if (show) {
      getLadderOptions();
      getNpsOptions();
      getTags();
    }
  }, []);

  const getLadderOptions = () => {
    fetchData(`api/accounts/user-choices/?type=ladder_choices`, "get").then(
      (res) => {
        setLoadings((prevState) => ({
          ...prevState,
          ladderLoading: false,
        }));
        if (res.success) {
          const data = res["ladder_choices"]?.map((e) => {
            return {
              label: e.display_name,
              value: e.value,
            };
          });
          setLadderOptions(data);
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  const getNpsOptions = () => {
    fetchData(`api/accounts/user-choices/?type=nps_choices`, "get").then(
      (res) => {
        setLoadings((prevState) => ({
          ...prevState,
          npsLoading: false,
        }));

        if (res.success) {
          const data = res["nps_choices"]?.map((e) => {
            return {
              label: e.display_name,
              value: e.value,
            };
          });
          setNpsOptions(data);
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  const getTags = () => {
    fetchData(`api/tags`, "get").then((res) => {
      setLoadings((prevState) => ({
        ...prevState,
        tagLoading: false,
      }));
      if (res.success) {
        const data = res?.data?.map((e) => {
          return {
            label: e.name,
            value: e.id,
          };
        });
        setTagOptions(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const onSubmitUser = async () => {
    setSending(true);

    const body = {
      user_profile: {
        tags: userRoleData?.tags,
        ladder: userRoleData?.ladder,
        nps: userRoleData?.nps,
      },
    };

    // console.log(data?.uuid, "uuid");

    // console.log(body, "body");

    fetchData(`api/accounts/${data?.uuid}/`, "put", body).then((res) => {
      toast.remove();
      setSending(false);
      if (res.success) {
        onHide();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setUserRoleDataValue = (key, value) => {
    const common_data = {
      ...userRoleData,
      [key]: value,
    };

    setUserRoleData(common_data);
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Change Role<span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>
            <span className="required fw-semibold fs-5">Select Role</span>
          </label>

          <select
            name="role"
            value={userRoleData.role}
            onChange={(e) => setUserRoleDataValue("role", e.target.value)}
            className="form-control"
            disabled={true}
          >
            <option value="User">User</option>
            <option value="Admin">Admin</option>
            <option value="Moderator">Moderator</option>
            {/* Add more options as needed */}
          </select>
        </div>

        <div className="mt-5">
          <label>
            <span className="required fw-semibold fs-5">Add Tags</span>
          </label>
          <Select
            mode="multiple"
            showSearch
            name="hotel"
            value={userRoleData.tags}
            onChange={(value) => setUserRoleDataValue("tags", value)}
            placeholder="Choose Tags"
            className="fs-7 w-100"
            style={{ width: "100%" }}
            dropdownStyle={{ zIndex: 1100 }}
            size="large"
            options={tagOptions}
            disabled={loadings?.tagLoading}
          ></Select>
        </div>

        <div className="mt-5">
          <label>
            <span className="required fw-semibold fs-5">Ladder</span>
          </label>

          <Select
            showSearch
            name="hotel"
            value={userRoleData.ladder}
            onChange={(value) => setUserRoleDataValue("ladder", value)}
            placeholder="Choose Ladder Options"
            className="fs-7 w-100"
            style={{ width: "100%" }}
            dropdownStyle={{ zIndex: 1100 }}
            size="large"
            options={ladderOptions}
            disabled={loadings?.ladderLoading}
          ></Select>
        </div>

        <div className="mt-5">
          <label>
            <span className="required fw-semibold fs-5">NPS Persona</span>
          </label>

          <Select
            showSearch
            name="hotel"
            value={userRoleData.nps}
            onChange={(value) => setUserRoleDataValue("nps", value)}
            placeholder="Choose Nps Option"
            className="fs-7 w-100"
            style={{ width: "100%" }}
            dropdownStyle={{ zIndex: 1100 }}
            size="large"
            options={npsOptions}
            disabled={loadings?.npsLoading}
          ></Select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            toast.loading("Updating Note");
            onSubmitUser();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
