export default function circularProgressBar(progress, size, strokeWidth) {
  const maxProgress = 5;
  const center = size / 2;
  const radius = center - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / maxProgress) * circumference;

  return (
    <svg className="circular-progress-bar" width={size} height={size}>
      <circle
        stroke="#804dea33"
        fill="transparent"
        strokeWidth={strokeWidth}
        r={radius}
        cx={center}
        cy={center}
      />
      <circle
        stroke="#443dba"
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        r={radius}
        cx={center}
        cy={center}
        style={{ transition: "stroke-dashoffset 0.35s" }}
      />
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="12px"
        fill="#443dba"
      >
        {`${progress.toFixed(1)}`}
      </text>
    </svg>
  );
}
