import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Dropzone } from "../../../../components";
import toast from "react-hot-toast";
import { fetchData } from "../../../../config/service";
import { Select } from "antd";

export default function MaxSpots({
  show,
  item,
  onHide,
  destinationList,
  hotelList,
  experienceList,
  onSuccess,
}) {
  const [image, setImage] = useState(null);
  const [pictures, setPictures] = useState([]);
  const [dayNumber, setDayNumber] = useState("");
  const [cost, setCost] = useState("");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [hotel, setHotel] = useState("");
  const [destination, setDestination] = useState("");
  const [experience, setExperience] = useState("");
  const [about, setAbout] = useState("");

  const [sending, setSending] = useState(false);
  const [removedImages, setRemovedImages] = useState([]);

  const [images, setImages] = useState({
    mainImage: null,
  });
  const [imagePreviews, setImagePreviews] = useState({
    mainImagePreview: null,
  });

  useEffect(() => {
    if (item) {
      console.log(item, "item");
      setTitle(item.title_of_day);
      setDayNumber(item.day_number);
      setCost(item.cost);
      setAbout(item.one_line_about);
      setDescription(item.description);
      setDestination(item.destination);
      setHotel(item.hotel);
      setExperience(item.experience);
      // setPictures(item.images);
      setImagePreviews({
        mainImagePreview: item?.default_image,
      });
      setImages({
        mainImage: item?.default_image,
      });
    } else {
      setTitle("");
      setDayNumber("");
      setCost("");
      setAbout("");
      setDescription("");
      setDestination("");
      setHotel("");
      setExperience("");
      setPictures([]);
      setRemovedImages([]);
      setImagePreviews({
        mainImagePreview: null,
      });
      setImages({
        mainImage: null,
      });
    }
  }, [item]);

  const onSubmit = () => {
    if (!cost || !images?.mainImage || !title || !description) {
      if (!images?.mainImage) {
        toast.error("Please select main add-on image.");
      } else if (!cost) {
        toast.error("Please add Cost.");
      } else if (!title) {
        toast.error("Please enter title.");
      } else if (!description) {
        toast.error("Please add the description.");
      }
      // else if (!hotel) {
      //   toast.error("Please select the hotel.");
      // } else if (!destination) {
      //   toast.error("Please select the destination.");
      // } else if (!experience) {
      //   toast.error("Please select the experience.");
      // } else if (!about) {
      //   toast.error("Please enter about the day.");
      // } else if (pictures.length > 10) {
      //   toast.error("You can select up to 10 images only.");
      // }
    } else {
      const isURL = (str) => {
        try {
          new URL(str);
          return true;
        } catch {
          return false;
        }
      };

      setSending(true);
      const body = {
        title_of_day: title,
        cost: cost,
        description: description,
        ...(images?.mainImage && isURL(images?.mainImage)
          ? {}
          : { default_image: images?.mainImage || "" }),
        destination: destination,
        // day_number: dayNumber,
        // hotel: hotel,
        // experience: experience,
        // one_line_about: about,
      };

      // if (images?.mainImage) {
      //   body.default_image = images?.mainImage;
      // }

      // let uploadedPictures = pictures.filter((item) => item.path);
      // if (uploadedPictures.length) {
      //   body.images = uploadedPictures;
      // }

      // if (removedImages.length) {
      //   body.delete_images = removedImages;
      // }

      let api = item?.id ? `api/add-ons/${item.id}/` : "api/add-ons/";
      let method = item?.id ? "put" : "post";

      fetchData(api, method, body, "", true).then((res) => {
        setSending(false);
        if (res.success) {
          toast.success("Success");
          onHide();
          onSuccess();
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  const handleImagesChange = (e, imageType) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = () => {
        // Update the state with the file and its preview
        setImages((prevImages) => ({
          ...prevImages,
          [imageType]: file,
        }));

        setImagePreviews((prevImagePreviews) => ({
          ...prevImagePreviews,
          [`${imageType}Preview`]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImages = (imageType) => {
    setImages((prevImages) => ({
      ...prevImages,
      [imageType]: null,
    }));

    setImagePreviews((prevImagePreviews) => ({
      ...prevImagePreviews,
      [`${imageType}Preview`]: null,
    }));
  };

  return (
    <Modal centered show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <h3 className="modal-title">Add Add-ons</h3>
      </Modal.Header>
      <div>
        <div className="modal-body pb-7 pt-5 px-lg-10">
          <div className="scroll-y pe-7 ps-3">
            <div className="row g-5 mb-5 align-items-center">
              <div className="col-md-6 fv-row">
                <label htmlFor className=" fs-6 fw-bold mb-2">
                  Main Image
                </label>
                {imagePreviews?.mainImagePreview ? (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      marginLeft: "10px",
                    }}
                  >
                    <img
                      src={imagePreviews?.mainImagePreview}
                      alt="Image Preview"
                      style={{
                        width: "150px",
                        height: "120px",
                        objectFit: "cover",
                        borderRadius: "8px",
                        border: "2px solid #ccc",
                      }}
                    />
                    <i
                      title="Remove Image"
                      className="ki-outline ki-cross fs-2"
                      onClick={() => handleRemoveImages("mainImage")}
                      style={{
                        position: "absolute",
                        top: "-8px",
                        right: "-8px",
                        cursor: "pointer",
                        zIndex: 2,
                        backgroundColor: "white",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                ) : (
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    className="form-control fs-7"
                    onChange={(e) => handleImagesChange(e, "mainImage")}
                  />
                )}

                {/* <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  className="form-control fs-7"
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      setImage(e.target.files[0]);
                    }
                  }}
                /> */}
              </div>
              <div className="col-md-6 fv-row">
                <label htmlFor className=" fs-6 fw-bold mb-2">
                  Cost
                </label>
                <input
                  type="text"
                  className="form-control fs-7"
                  value={cost}
                  onChange={(e) => {
                    setCost(e.target.value);
                  }}
                />
              </div>
              {/* <div className="col-md-4 fv-row">
                <label htmlFor className=" fs-6 fw-bold mb-2">
                  Day Number
                </label>
                <input
                  type="text"
                  className="form-control fs-7"
                  value={dayNumber}
                  onChange={(e) => {
                    setDayNumber(e.target.value);
                  }}
                />
              </div> */}
              <div className="col-md-6 fv-row">
                <label htmlFor className=" fs-6 fw-bold mb-2">
                  Title of the Day
                </label>
                <input
                  type="text"
                  className="form-control fs-7"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>

              <div className="col-md-6 fv-row">
                <label htmlFor className=" fs-6 fw-bold mb-2 ">
                  Add Destinations
                </label>

                <Select
                  value={destination}
                  className="w-100"
                  placeholder="Select Destination"
                  label="name"
                  options={destinationList?.map((item) => {
                    return {
                      value: item?.id,
                      label: item?.name,
                    };
                  })}
                  onChange={(value) => {
                    setDestination(value);
                  }}
                  dropdownStyle={{ zIndex: 1111 }}
                  size="large"
                />
              </div>
              <div className="col-md-12 fv-row">
                <label htmlFor className=" fs-6 fw-bold mb-2">
                  Add-ons Description
                </label>
                <textarea
                  className="form-control fs-7"
                  cols={30}
                  rows={3}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>

              {/* <div className="col-md-12 fv-row">
                <label htmlFor className=" fs-6 fw-bold mb-2">
                  Image
                </label>

                {imagePreviews?.mainImagePreview ? (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      marginLeft: "10px",
                    }}
                  >
                    <img
                      src={imagePreviews?.mainImagePreview}
                      alt="Image Preview"
                      style={{
                        width: "150px",
                        height: "120px",
                        objectFit: "cover",
                        borderRadius: "8px",
                        border: "2px solid #ccc",
                      }}
                    />
                    <i
                      title="Remove Image"
                      className="ki-outline ki-cross fs-2"
                      onClick={() => handleRemoveImages("mainImage")}
                      style={{
                        position: "absolute",
                        top: "-8px",
                        right: "-8px",
                        cursor: "pointer",
                        zIndex: 2,
                        backgroundColor: "white",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                ) : (
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    className="form-control fs-7"
                    onChange={(e) => handleImagesChange(e, "mainImage")}
                  />
                )}
              </div> */}

              {/* <div className="col-md-12 fv-row">
                <form className="form" action="#" method="post">
                  <label htmlFor className=" fs-6 fw-bold mb-2">
                    Add-ons Image
                  </label>
                  <Dropzone
                    pictures={pictures}
                    setPictures={setPictures}
                    removedImages={removedImages}
                    setRemovedImages={setRemovedImages}
                  />
                </form>
              </div>
               */}

              {/* <div className="col-md-4 fv-row">
                <label htmlFor className=" fs-6 fw-bold mb-2 ">
                  Add Hotel
                </label>
                <Select
                  inModal
                  value="id"
                  label="name"
                  list={hotelList}
                  selected={hotel}
                  onChange={(value) => {
                    setHotel(value);
                  }}
                />
              </div> */}

              {/* <div className="col-md-4 fv-row">
                <label htmlFor className=" fs-6 fw-bold mb-2 ">
                  Select Experience
                </label>
                <Select
                  inModal
                  value="id"
                  label="about"
                  list={experienceList}
                  selected={experience}
                  onChange={(value) => {
                    setExperience(value);
                  }}
                />
              </div> */}
              {/* <div className="col-md-12 fv-row">
                <label htmlFor className=" fs-6 fw-bold mb-2 ">
                  About this day <small>(One Line)</small>
                </label>
                <input
                  type="text"
                  className="form-control fs-7"
                  value={about}
                  onChange={(e) => {
                    setAbout(e.target.value);
                  }}
                />
              </div> */}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-light fs-7" onClick={onHide}>
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary fs-7"
            onClick={onSubmit}
            disabled={sending}
          >
            {sending ? (
              <span>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}
