import React, { useEffect, useState } from "react";
import { ManageHotels } from "./modals";
import { LIMITS, PROPERTIES } from "../../../config";
import { fetchData } from "../../../config/service";
import { Alert, Loader, Pagination } from "../../../components";
import toast from "react-hot-toast";
import ImagePreview from "../../../components/validation";
import { Image, Select } from "antd";

export default function AddOns() {
  const [manageHotels, setManageHotels] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [loading, setLoading] = useState(false);
  const [destinationList, setDestinationList] = useState([]);

  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [ratingList, setRatingList] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const [filterData, setFilterData] = useState({
    property_type: null,
    google_rating: null,
  });

  // Debounce the search term
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 700); // 500ms debounce

    // Cleanup the timeout if searchTerm changes (before 500ms)
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    getDestination();
    getHotelChoices();
  }, []);

  useEffect(() => {
    getData();
  }, [limit, activePage, debouncedSearchTerm, filterData]);

  const getDestination = () => {
    fetchData("api/destination/").then((res) => {
      if (res.success) {
        setDestinationList(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getHotelChoices = () => {
    fetchData("api/hotel/hotel-choices").then((res) => {
      if (res.success) {
        setPropertyTypeList(res["property_type_choices"]);
        setRatingList(res["rating_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    // Conditionally add property_type if it exists
    if (filterData?.property_type) {
      param += `&property_type=${encodeURIComponent(filterData.property_type)}`;
    }

    // Conditionally add google_rating if it exists
    if (filterData?.google_rating) {
      param += `&google_rating=${encodeURIComponent(filterData.google_rating)}`;
    }

    // Add search query if it exists
    if (debouncedSearchTerm) {
      param += `&search=${encodeURIComponent(debouncedSearchTerm)}`;
    }

    setLoading(true); // Assuming you have this to indicate loading state

    fetchData(`api/hotel/${param}`).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        setData([]);
        toast.error(res.message);
      }
    });
  };

  const deleteItem = () => {
    fetchData(`api/hotel/${selected.id}`, "delete").then((res) => {
      toast.dismiss();
      if (res.success) {
        getData();
        toast.success("Record deleted successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setFilterDataValue = (key, value) => {
    const common_data = {
      ...filterData,
      [key]: value,
    };

    setFilterData(common_data);
  };

  return (
    <div>
      <div className="row g-5 g-xxl-10">
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header">
              <div className="d-flex flex-stack flex-wrap gap-4">
                <div className="position-relative mr-4">
                  <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
                  <input
                    type="text"
                    data-kt-table-widget-4="search"
                    className="form-control text-dark w-250px fs-7 ps-12 all-search"
                    name="all_search"
                    placeholder="Search Hotel here..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div>
                  <div className="d-flex align-items-center position-relative">
                    <div className="ms-3">
                      <Select
                        value={filterData?.property_type}
                        showSearch
                        optionFilterProp="label"
                        onChange={(value) => {
                          setFilterDataValue("property_type", value);
                        }}
                        style={{ width: "200px" }}
                        placeholder="Select a Propety Type"
                        options={propertyTypeList.map((e) => ({
                          label: e.display_name,
                          value: e.value,
                        }))}
                        allowClear
                        onClear={() => {
                          setFilterDataValue("property_type", null);
                        }}
                        size="large"
                      />

                      {/* <Select
                        list={propertyTypeList}
                        value="value"
                        label="display_name"
                        selected={filterData?.property_type}
                        onChange={(value) => {
                          console.log(value);
                          setFilterDataValue("property_type", value);
                        }}
                        isClearable={true}
                      /> */}
                    </div>

                    <div className="ms-3">
                      <Select
                        value={filterData?.google_rating}
                        showSearch
                        optionFilterProp="label"
                        onChange={(value) => {
                          setFilterDataValue("google_rating", value);
                        }}
                        style={{ width: "200px" }}
                        placeholder="Select a Google Rating"
                        options={ratingList.map((e) => ({
                          label: e.display_name,
                          value: e.value,
                        }))}
                        allowClear
                        onClear={() => {
                          setFilterDataValue("google_rating", null);
                        }}
                        size="large"
                      />

                      {/* <select
                        className="form-select"
                        value={filterData?.google_rating || ""}
                        onChange={(e) =>
                          setFilterDataValue("google_rating", e.target.value)
                        }
                      >
                        
                        <option value="" disabled>
                          Select a Goggle Rating
                        </option>
                        {ratingList?.map((p, i) => (
                          <option key={i} value={p?.value}>
                            {p?.display_name}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  </div>

                  {/* <button class="btn btn-primary fs-7 fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_add_itinerary"><i class="ki-outline ki-plus-square fs-2"></i> Add Itinerary </button> */}
                </div>
                <div className="position-relative mr-4"></div>
              </div>
              <div className="card-toolbar">
                {/*begin::Daterangepicker(defined in src/js/layout/app.js)*/}
                {/* <div data-kt-daterangepicker="true"
                                                  data-kt-daterangepicker-opens="left"
                                                  class="btn btn-sm btn-white border border-gray-300 me-3 d-flex align-items-center px-4">
                                                  <div class="text-gray-600 fw-bold">
                                                      Loading date range...
                                                  </div>

                                                  <i class="ki-outline ki-calendar-8 fs-1 ms-2 me-0"></i>
                                              </div> */}
                {/*end::Daterangepicker*/}
                {/* <a class="btn btn-primary fs-7 fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_add_edition"><i class="ki-outline ki-plus-square fs-2"></i>Add Edition</a> */}
                <button
                  className="btn btn-primary fs-7 fw-bold"
                  onClick={() => {
                    setManageHotels(true);
                  }}
                >
                  <i className="ki-outline ki-plus-square fs-2" /> Add New Hotel
                </button>
                {/* <a href="#" class="btn  fs-7 btn-primary fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Add New User</a> */}
              </div>
            </div>
            {/*end::Header*/}

            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {loading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {data?.length > 0 ? (
                    <>
                      <div className="table-responsive tbl-sales">
                        {/*begin::Table*/}
                        <table
                          className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                          id
                        >
                          <thead>
                            <tr className="fw-bolder text-gray-900 bg-light-primary">
                              {/* <th class="min-w-75px">Sr. No.</th> */}
                              <th className="min-w-125px">Hotel Details</th>
                              <th className="min-w-125px">Property Type</th>
                              {/* <th className="min-w-350px">About the Hotel</th> */}
                              <th className="min-w-175px">
                                Confirmation Number
                              </th>
                              <th className="min-w-150px">Google Map Link</th>
                              <th className="min-w-100px">City</th>
                              {/* <th className="min-w-125px">Arrival Date</th>
                              <th className="min-w-125px">Departure Date</th> */}
                              <th className="min-w-125px">Property Phone</th>
                              {/* <th className="min-w-250px">Property Address</th> */}
                              <th className="min-w-125px">Google Rating</th>
                              <th className="min-w-150px">Room Size (Msq.)</th>
                              <th className="min-w-100px">Action</th>
                            </tr>
                          </thead>
                          <tbody className=" fw-semibold fs-7">
                            {data.length > 0 ? (
                              data.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="symbol symbol-60px overflow-hidden me-3">
                                          <Image
                                            src={
                                              item?.default_image ??
                                              "/media/img.jpg"
                                            }
                                            alt={`${item.name} `}
                                            width={90}
                                            height={90}
                                          />
                                        </div>
                                        {/*end::Avatar*/}
                                        {/*begin::User details*/}
                                        <div className="d-flex flex-column">
                                          <a
                                            href
                                            className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-200px"
                                          >
                                            {item.name || "-"}
                                          </a>
                                          <div className="rating justify-content-start gap-1">
                                            {[1, 2, 3, 4, 5].map((_, i) => {
                                              return (
                                                <div
                                                  className={`rating-label  ${
                                                    item.star_rating >= i &&
                                                    "checked"
                                                  }`}
                                                >
                                                  <i className="ki-solid ki-star fs-4" />
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      {/* {PROPERTIES?.[item.property_type] || "-"} */}
                                      {item.property_type_label || "-"}
                                    </td>
                                    {/* <td>{item.about || "-"}</td> */}
                                    <td>{item.confirmation_number || "-"}</td>
                                    <td>
                                      <span>
                                        {item.google_map_link ? (
                                          <a
                                            target="_blank"
                                            href={item.google_map_link}
                                            className="text-two fs-7 min-w-200px text-primary"
                                          >
                                            {item.google_map_link || "-"}
                                          </a>
                                        ) : (
                                          "-"
                                        )}
                                      </span>
                                    </td>
                                    <td> {item.city || "-"}</td>
                                    {/* <td> {item.arrival_date || "-"}</td>
                                    <td> {item.departure_date || "-"}</td> */}
                                    <td> {item.phone || "-"}</td>
                                    {/* <td>
                                      <span className>
                                        {item.address || "-"}
                                      </span>
                                    </td> */}
                                    <td>{item.google_rating || "-"}</td>
                                    <td>{item.room_size || "-"}</td>
                                    <td>
                                      <div className="d-flex flex-shrink-0 ">
                                        <span
                                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary cursor-pointer btn-sm me-3"
                                          onClick={() => {
                                            setManageHotels(true);
                                            setSelected(item);
                                          }}
                                        >
                                          <i className="ki-outline ki-pencil fs-2 text-dark" />
                                        </span>
                                        <span
                                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary cursor-pointer btn-sm"
                                          onClick={() => {
                                            setShowDeleteModal(true);
                                            setSelected(item);
                                          }}
                                        >
                                          <i className="ki-outline ki-trash fs-2 text-danger" />
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="8">
                                  <div className="d-flex justify-content-center align-items-center h-200px">
                                    <span className="fs-2x fw-bold">
                                      No records
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {/*end::Table*/}
                      </div>
                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={setLimit}
                        limit={limit}
                        data={data}
                        activePage={activePage}
                        totalRecords={totalRecords}
                        onChange={(pageNumber) => {
                          setLoading(true);
                          setActivePage(pageNumber);
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </div>

      <ManageHotels
        item={selected}
        destinationList={destinationList}
        propertyTypeList={propertyTypeList}
        show={manageHotels}
        onHide={() => {
          setSelected(null);
          setManageHotels(false);
        }}
        onSuccess={() => {
          getData();
        }}
      />
      <Alert
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        onSuccess={() => {
          getData();
        }}
        onAction={() => {
          toast.loading("Please wait...");
          deleteItem();
        }}
      />
    </div>
  );
}
