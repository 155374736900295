import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { LIMITS, routesList } from "../../../config";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import { Loader, Pagination } from "../../../components";
import { Popover, Select, Tooltip } from "antd";
import BookingsFilterDrawer from "./drawer/bookings-filters";
import FlightModal from "./modals/flight";
import PassportCheckModal from "./modals/passport-check";
import NotesDrawer from "../onboarding-calls/drawer/notes";
import PaymentModal from "./modals/payment";
import PaymentDrawer from "./drawer/payment";

const dummyData = [
  {
    id: 1,
    edition_name: "Edition A",
    top_priority_labels: ["Priority 1", "Priority 2", "Priority 3"],
    growth_areas_labels: ["Growth Area 1", "Growth Area 2"],
    status: 3, // Approved
    status_labels: "Approved",
    intentions: "Expand business reach",
    payment_date: "2021-04-20",
    resource_manager: "John Doe",
    user: {
      first_name: "John",
      last_name: "Doe",
      date_of_birth: "1988-04-20",
      photo: "/media/users/john-doe.png",
      user_work: [
        {
          designation: "Software Engineer",
        },
      ],
      user_profile: {
        instagram_url: "https://instagram.com/johndoe",
        linkedin_url: "https://linkedin.com/in/johndoe",
      },
    },
  },
  {
    id: 2,
    edition_name: "Edition B",
    top_priority_labels: ["Priority A", "Priority B"],
    growth_areas_labels: ["Growth Area A"],
    status: 2, // Abandon
    status_labels: "Abandon",
    intentions: "Increase market share",
    payment_date: "2021-04-20",
    resource_manager: "John Doe",

    user: {
      first_name: "Jane",
      last_name: "Smith",
      date_of_birth: "1992-08-15",
      photo: "/media/users/jane-smith.png",
      user_work: [
        {
          designation: "Marketing Manager",
        },
      ],
      user_profile: {
        instagram_url: "https://instagram.com/janesmith",
        linkedin_url: "https://linkedin.com/in/janesmith",
      },
    },
  },
  {
    id: 3,
    edition_name: "Edition C",
    top_priority_labels: ["Priority X", "Priority Y", "Priority Z"],
    growth_areas_labels: ["Growth Area X", "Growth Area Y", "Growth Area Z"],
    status: 1, // Pending
    status_labels: "Pending",
    intentions: "Develop new products",
    payment_date: "2021-04-20",
    resource_manager: "John Doe",
    user: {
      first_name: "Alice",
      last_name: "Johnson",
      date_of_birth: "1985-11-05",
      photo: "/media/users/alice-johnson.png",
      user_work: [
        {
          designation: "Product Manager",
        },
      ],
      user_profile: {
        instagram_url: "https://instagram.com/alicejohnson",
        linkedin_url: "https://linkedin.com/in/alicejohnson",
      },
    },
  },
];

export default function BookingsPage() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [editionsList, setEditionsList] = useState([]);

  const [filterData, setFilterData] = useState({
    status: null,
    edition: null,
    second_payment_status: null,
    visa_status: null,
    payment_status: null,
    curation_status: null,
    flight_ticket_status: null,
    destination: null,
    project: null,
    start_date: null,
    end_date: null,
  });

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const [projectOptions, setProjectOptions] = useState([]);
  const [destinationsOptions, setDestinationsOptions] = useState([]);
  const [flightModal, setFlightModal] = useState(false);

  const [passportInfoModal, setPassportInfoModal] = useState(false);
  const [paymentDrawer, setPaymentDrawer] = useState(false);

  const [selectedModalData, setSelectedModalData] = useState({});
  const [showNotesDrawer, setShowNotesDrawer] = useState(false);
  const [notesOptions, setNotesOptions] = useState([]);
  const [onboardingFlag, setOnboardingFlag] = useState(false);

  useEffect(() => {
    getData();
  }, [limit, activePage, debouncedSearchTerm, filterData, id]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    getEditions();
    getStatus();
    getProjectOptions();
    getDestinationsOptions();
    getNotesOptions();
  }, []);

  const getProjectOptions = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${100}&offset=${offset}`;

    fetchData(`api/project/${param}`).then((res) => {
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });
        setProjectOptions(data);
      } else {
        setProjectOptions([]);
        toast.error(res.message);
      }
    });
  };

  const getDestinationsOptions = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${100}&offset=${offset}`;

    fetchData(`api/destination/${param}`).then((res) => {
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });
        setDestinationsOptions(data);
      } else {
        setDestinationsOptions([]);
        toast.error(res.message);
      }
    });
  };

  const getStatus = () => {
    fetchData(
      `api/accounts/user-choices/?type=user_status_choices`,
      "get"
    ).then((res) => {
      if (res.success) {
        setStatusList(res["user_status_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getEditions = () => {
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    setLoading(true);

    const editionId = filterData?.edition
      ? filterData?.edition
      : id == ":id"
      ? null
      : id;

    // id == ":id" ? null : filterData?.edition ? filterData?.edition : id;

    fetchData(
      `api/booking/?limit=${limit}&offset=${
        (activePage - 1) * limit
      }&search=${encodeURIComponent(debouncedSearchTerm || "")}&status=${
        filterData?.status || ""
      }&edition=${editionId || ""}&project=${
        filterData?.project || ""
      }&destinations=${filterData?.destination || ""}&curation_status=${
        filterData?.curation_status || ""
      }&flight_ticket_status=${
        filterData?.flight_ticket_status || ""
      }&start_date=${filterData?.start_date || ""}&end_date=${
        filterData?.end_date || ""
      }
      `,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        toast.error(res.message);
      }
    });
  };

  const setFilterDataValue = (key, value) => {
    const common_data = {
      ...filterData,
      [key]: value,
    };
    setFilterData(common_data);
  };

  const getNotesOptions = () => {
    fetchData(`api/note/notes-enum/`, "get").then((res) => {
      if (res.success) {
        setNotesOptions(res["note_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <div className="row g-5 g-xxl-10">
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header">
              <div className="d-flex flex-stack flex-wrap gap-4">
                <div class="position-relative ">
                  <i class="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5"></i>

                  <input
                    type="text"
                    data-kt-table-widget-4="search"
                    className="form-control text-dark w-250px fs-7 ps-12 all-search"
                    name="all_search"
                    placeholder="Search customer here..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>

                <div className="position-relative mr-4">
                  <div
                    className="btn btn-icon btn-primary btn-sm fw-bold me-2"
                    onClick={() => {
                      setOpenFilterDrawer(!openFilterDrawer);
                    }}
                  >
                    <i class="ki-outline ki-setting-4 fs-2x"></i>
                  </div>
                </div>

                {/* <div className="position-relative ">
                  <Select
                    value={filterData?.status}
                    showSearch
                    optionFilterProp="label"
                    onChange={(value) => setFilterDataValue("status", value)}
                    placeholder="Select Status"
                    style={{ width: "200px" }}
                    options={statusList.map((h) => ({
                      label: h.display_name,
                      value: h.value,
                    }))}
                    allowClear
                    onClear={() => {
                      setFilterDataValue("status", null);
                    }}
                    size="large"
                  />
                </div>
                <div className="position-relative ">
                  <Select
                    value={filterData?.edition}
                    showSearch
                    optionFilterProp="label"
                    onChange={(value) => setFilterDataValue("edition", value)}
                    placeholder="Select Edition"
                    style={{ width: "200px" }}
                    options={editionsList}
                    allowClear
                    onClear={() => {
                      setFilterDataValue("edition", null);
                    }}
                    size="large"
                  />
                </div> */}
              </div>
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}

            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {loading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {data?.length > 0 ? (
                    <>
                      <div className="table-responsive tbl-sales">
                        {/*begin::Table*/}
                        <table className="table align-middle table-row-dashed table-row-gray-300 gy-4 gs-4 border-top-d">
                          <thead>
                            <tr className="fw-bolder text-gray-900 bg-light-primary">
                              <th className="min-w-205px">Details</th>
                              <th className="min-w-125px">Edition</th>
                              <th className="min-w-150px">Status</th>
                              <th className="min-w-75px">Age</th>
                              <th className="min-w-125px">Payment Date</th>
                              <th className="min-w-150px ">Resource Manger</th>
                              <th className="min-w-125px">Explore more</th>
                              <th className="min-w-125px text-center">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold fs-7">
                            {data?.map((item) => {
                              const { user } = item;
                              const age =
                                new Date().getFullYear() -
                                new Date(user.date_of_birth).getFullYear();
                              return (
                                <tr key={item.id}>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                        <img
                                          src={user.photo || "/media/blank.png"}
                                          alt="user"
                                        />
                                      </div>
                                      <div className="d-flex flex-column">
                                        <Link
                                          to={`${routesList[
                                            "bookings_detail"
                                          ].path.replace(":id", item.id)}`}
                                          className="text-primary text-hover-primary mb-1 fw-bolder fs-5 min-w-200px"
                                        >
                                          {user.first_name || "-"}{" "}
                                          {user.last_name || "-"}
                                        </Link>
                                        <div className="text-gray-600">
                                          {user.user_work[0]?.designation ||
                                            "N/A"}
                                        </div>
                                      </div>
                                    </div>
                                  </td>

                                  <td>{item.edition_name || "-"}</td>

                                  <td>
                                    <span
                                      className={`badge badge-light-${
                                        item.status == 1
                                          ? "warning"
                                          : item.status == 2
                                          ? "danger"
                                          : item?.status == 3
                                          ? "success"
                                          : "dark"
                                      }`}
                                    >
                                      {/* {item.status == 1
                                        ? "Pending"
                                        : item.status == 2
                                        ? "Abandon"
                                        : item?.status == 3
                                        ? "Approved"
                                        : "Attended"} */}
                                      {item.status_labels || "-"}
                                    </span>
                                  </td>
                                  <td>{age || "-"}</td>

                                  <td>{item.payment_date || "-"}</td>
                                  <td>{item.resource_manager || "-"}</td>

                                  <td>
                                    <div className="d-flex flex-shrink-0">
                                      {user?.user_profile?.instagram_url ? (
                                        <>
                                          <a
                                            href={
                                              user?.user_profile?.instagram_url
                                            }
                                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <i className="lab la-instagram fs-2 text-primary" />
                                          </a>
                                        </>
                                      ) : (
                                        <p>{"-"}</p>
                                      )}

                                      {user?.user_profile?.linkedin_url ? (
                                        <>
                                          <a
                                            href={
                                              user?.user_profile?.linkedin_url
                                            }
                                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <i className="lab la-linkedin fs-2 text-primary" />
                                          </a>
                                        </>
                                      ) : (
                                        <p>{"-"}</p>
                                      )}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="d-flex">
                                      {/* On Boarding Icon */}
                                      <Tooltip title="On Boarding">
                                        <div
                                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                          onClick={() => {
                                            setOnboardingFlag(true);
                                            setSelectedModalData(item);
                                            setShowNotesDrawer(
                                              !showNotesDrawer
                                            );
                                          }}
                                        >
                                          <i
                                            className={`${
                                              item?.is_onboarding_note
                                                ? "ki-solid"
                                                : "ki-outline"
                                            } ki-calendar-add fs-2 text-success`}
                                          ></i>{" "}
                                          {/* <i className="ki-outline ki-calendar-add fs-2 text-info"></i>{" "} */}
                                        </div>
                                      </Tooltip>

                                      {/* Flight Icon */}
                                      <Tooltip title="Flight">
                                        <div
                                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm  me-3"
                                          onClick={() => {
                                            // const data ={edition_id: item?.edition, user_id: item?.user?.uuid};
                                            setSelectedModalData({ ...item });
                                            setFlightModal(!flightModal);
                                          }}
                                        >
                                          <i
                                            className={`${
                                              item?.is_flight_ticket
                                                ? "ki-solid"
                                                : "ki-outline"
                                            } ki-airplane fs-2 text-warning`}
                                          ></i>{" "}
                                          {/* <i className="ki-solid ki-airplane fs-2 text-warning"></i>{" "} */}
                                        </div>
                                      </Tooltip>

                                      {/* Subtitle Icon */}
                                      <Tooltip title="Visa">
                                        <a className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm  me-3">
                                          <i
                                            className={`${
                                              item?.is_visa
                                                ? "ki-solid"
                                                : "ki-outline"
                                            } ki-subtitle fs-2 text-info`}
                                          ></i>{" "}
                                        </a>
                                      </Tooltip>

                                      {/* Passport Check Icon */}
                                      <Tooltip title="Passport Check">
                                        <div
                                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm  me-3"
                                          onClick={() => {
                                            setSelectedModalData({ ...item });
                                            setPassportInfoModal(
                                              !passportInfoModal
                                            );
                                          }}
                                        >
                                          <i
                                            className={`${
                                              item?.is_passport
                                                ? "ki-solid"
                                                : "ki-outline"
                                            } ki-password-check fs-2 text-danger`}
                                          ></i>
                                          {/* <i className="ki-outline ki-password-check fs-2 text-danger"></i>{" "} */}
                                          {/* Changed to text-danger */}
                                        </div>
                                      </Tooltip>

                                      {/* Payment Check Icon */}
                                      <Tooltip title="Payment Check">
                                        <div
                                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm  me-3"
                                          onClick={() => {
                                            setSelectedModalData({ ...item });
                                            setPaymentDrawer(!paymentDrawer);
                                          }}
                                        >
                                          <i
                                            className={`${
                                              item?.is_full_payment
                                                ? "ki-solid"
                                                : "ki-outline"
                                            } ki-check-circle fs-2 text-success`}
                                          ></i>
                                          {/* <i className="ki-solid ki-check-circle fs-2 text-success"></i>{" "} */}
                                          {/* Changed to text-success */}
                                        </div>
                                      </Tooltip>

                                      {/* Notes Icon with Popover */}
                                      <Tooltip title="Notes">
                                        <div
                                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm  me-3"
                                          onClick={() => {
                                            setSelectedModalData(item);
                                            setShowNotesDrawer(
                                              !showNotesDrawer
                                            );
                                          }}
                                        >
                                          <i className="ki-outline ki-notepad fs-2 text-dark"></i>{" "}
                                          {/* Changed to text-secondary */}
                                        </div>
                                      </Tooltip>

                                      {/* Room Icon */}
                                      <Tooltip title="Room">
                                        <div className="mt-2">
                                          {" "}
                                          {item?.is_single_room ? (
                                            <>
                                              <svg
                                                width="23"
                                                height="23"
                                                fill="#99a1b7"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path d="M20.25 10.814a3.724 3.724 0 0 0-1.5-.314H5.25a3.754 3.754 0 0 0-3.75 3.75v5.25a.75.75 0 1 0 1.5 0v-.375a.38.38 0 0 1 .375-.375h17.25a.38.38 0 0 1 .375.375v.375a.75.75 0 1 0 1.5 0v-5.25a3.754 3.754 0 0 0-2.25-3.436Z"></path>
                                                <path d="M17.625 3.75H6.375A2.625 2.625 0 0 0 3.75 6.375V9.75a.187.187 0 0 0 .24.18c.409-.12.833-.18 1.26-.18h.198a.188.188 0 0 0 .188-.166A1.5 1.5 0 0 1 7.125 8.25H9.75a1.5 1.5 0 0 1 1.49 1.334.188.188 0 0 0 .188.166h1.147a.187.187 0 0 0 .187-.166A1.5 1.5 0 0 1 14.25 8.25h2.625a1.5 1.5 0 0 1 1.49 1.334.188.188 0 0 0 .188.166h.197c.427 0 .851.06 1.26.18a.188.188 0 0 0 .24-.18V6.375a2.625 2.625 0 0 0-2.625-2.625Z"></path>
                                              </svg>{" "}
                                            </>
                                          ) : (
                                            <>
                                              <svg
                                                width="23"
                                                height="23"
                                                fill="none"
                                                stroke="#99a1b7"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="1.5"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path d="M18 11.25H4.5V6.375A1.88 1.88 0 0 1 6.375 4.5h11.25A1.88 1.88 0 0 1 19.5 6.375v4.875H18Z"></path>
                                                <path d="M2.25 19.5v-5.25a3.009 3.009 0 0 1 3-3h13.5a3.009 3.009 0 0 1 3 3v5.25"></path>
                                                <path d="M2.25 19.5v-.375A1.128 1.128 0 0 1 3.375 18h17.25a1.128 1.128 0 0 1 1.125 1.125v.375"></path>
                                                <path d="M5.25 11.25v-.75A1.504 1.504 0 0 1 6.75 9h3.75a1.504 1.504 0 0 1 1.5 1.5v.75"></path>
                                                <path d="M12 11.25v-.75A1.504 1.504 0 0 1 13.5 9h3.75a1.504 1.504 0 0 1 1.5 1.5v.75"></path>
                                              </svg>
                                            </>
                                          )}
                                        </div>

                                        {/* <a className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm  me-3">
                                          <i
                                            className={`${
                                              item?.is_single_room
                                                ? "ki-solid"
                                                : "ki-outline"
                                            } ki-delivery-door fs-2 text-dark`}
                                          ></i>
                                         
                                        </a> */}
                                      </Tooltip>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

                        {/*end::Table*/}
                      </div>

                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={setLimit}
                        limit={limit}
                        data={data}
                        activePage={activePage}
                        totalRecords={totalRecords}
                        onChange={(pageNumber) => {
                          setLoading(true);
                          setActivePage(pageNumber);
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </div>

      {openFilterDrawer && (
        <BookingsFilterDrawer
          show={openFilterDrawer}
          onHide={() => {
            // set_user_data({});
            setOpenFilterDrawer(false);
            // setLoading(true);
            // getData();
          }}
          // data={user_data}
          filterData={filterData}
          setFilterDataValue={setFilterDataValue}
          editionsList={editionsList}
          statusList={statusList}
          editionId={id == ":id" ? null : id}
          projectList={projectOptions}
          destinationList={destinationsOptions}
          setFilterData={setFilterData}
        />
      )}

      {flightModal && (
        <FlightModal
          show={flightModal}
          onHide={() => {
            setFlightModal(false);
            // setLoading(true);
            // getData();
          }}
          data={selectedModalData}
        />
      )}

      {passportInfoModal && (
        <PassportCheckModal
          show={passportInfoModal}
          onHide={() => {
            setPassportInfoModal(false);
            // setLoading(true);
            // getData();
          }}
          data={selectedModalData}
        />
      )}

      {showNotesDrawer && (
        <NotesDrawer
          show={showNotesDrawer}
          onHide={() => {
            setSelectedModalData({});
            setShowNotesDrawer(false);
            setOnboardingFlag(false);
            // setLoading(true);
            // getData();
          }}
          data={selectedModalData}
          notesOptions={notesOptions}
          onboardingFlag={onboardingFlag}
        />
      )}

      {paymentDrawer && (
        <PaymentDrawer
          show={paymentDrawer}
          onHide={() => {
            setPaymentDrawer(false);
            // setLoading(true);
            // getData();
          }}
          data={selectedModalData}
        />
      )}
    </div>
  );
}
