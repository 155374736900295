import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { DatePicker, Select } from "antd";
import { fetchData } from "../../../config/service";
import dayjs from "dayjs";
import { validateFields } from "../../../components/validation";
import { LIMITS } from "../../../config";
import { Loader, Pagination } from "../../../components";
import moment from "moment";
import Dropzone from "../../../components/Dropzone";

export default function TribeBlogImagesModal({ show, onHide }) {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [limit, setLimit] = useState(LIMITS[0]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [tribeModal, setTribeModal] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    fetchData(
      `api/tribe/tribe-blogs-details
      `,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res) {
        setPictures(res.images);
      } else {
        toast.error(res.message);
      }
    });
  };

  const onSubmitEvent = async () => {
    setSending(true);

    const isURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch {
        return false;
      }
    };

    const filteredPictures = pictures.filter((picture) => !picture.id);

    const body = {
      images: filteredPictures,
    };

    if (removedImages.length > 0) {
      body.delete_images = removedImages;
    }

    fetchData(`api/tribe/tribe-blogs-details/`, "post", body, "", true).then(
      (res) => {
        setSending(false);
        toast.remove();
        if (res.success) {
          toast.success("Success");
          onHide();
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Tribe Members <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div>
            <label htmlFor className=" fs-6 fw-bold mb-2">
              Images
            </label>
            <Dropzone
              pictures={pictures}
              setPictures={setPictures}
              removedImages={removedImages}
              setRemovedImages={setRemovedImages}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitEvent();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
