import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

export default function FeedbackModal({ show, onHide }) {
  const data = [
    { name: "Joginder bedi", phone: "+8719000056" },
    { name: "Mohit Jeswani", phone: "+919833489970" },
    { name: "Arti Gadodia", phone: "+919820666724" },
    // { name: "Ruchika Gupta", phone: "+919910317773" },
    // { name: "Rizwan Ali", phone: "+918553184590" },
    // { name: "Rajashekar Nallu", phone: "+918019155192" },
    // { name: "Anuj Rathi", phone: "+919945699780" },
    // { name: "Nikhil Sharma", phone: "+918360237382" },
    // { name: "Vijay jain", phone: "+919820539293" },
    // { name: "Pooja khemka", phone: "+917578008001" },
    // { name: "Mahak Mahak", phone: "+918017050365" },
  ];

  return (
    <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Feedback Pending List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive">
          <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
            {/*begin::Table head*/}
            <thead>
              <tr className="fw-bolder text-dark bg-light-primary">
                <th className="min-w-150px">Name</th>
                <th className="min-w-100px">Phone</th>
                <th className="min-w-150px">Action</th>
              </tr>
            </thead>
            {/*end::Table head*/}
            {/*begin::Table body*/}
            <tbody>
              {data.map((person, index) => (
                <tr key={index}>
                  <td>{person.name}</td>
                  <td>{person.phone}</td>
                  <td>
                    <button className="btn btn-success btn-sm me-4">
                      <i className="bi bi-whatsapp"></i>
                    </button>

                    <button className="btn btn-primary btn-sm">
                      <i className="bi bi-telephone"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        {/* <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitUser();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button> */}
      </Modal.Footer>
    </Modal>
  );
}
